import styled from "styled-components";
import { siteSettings } from "../../static";

const explains = [
  `An asset's price on ${siteSettings.siteName} is dependent on the combined liquidity of 12+ different providers`,
  `${siteSettings.siteName} does not offer illiquid assets with prices that are artificially inflated via false and misleading statements, which are made in order to sell these cheaply purchased assets at a higher price (know as Pump and Dump)`,
  `AI-based algorithmic technology by ${siteSettings.siteName} continuously monitors the consistency of received price feeds from each liquidity provider`,
  `In order to provide fair and equal trading opportunities for all traders, ${siteSettings.siteName} continuously monitors all suspicious trading activities.`,
];

const Shield = () => {
  return (
    <ShieldWrapper>
      <div className="container">
        <div className="left">
          <p className="left__header">Price Manipulation Protection</p>
          <span className="left__title">MP Shield™ System</span>
          <p className="left__text">
            The cryptocurrency market is volatile and still relatively new. The
            consequences of price manipulation have been encountered many times
            on the global market. To counter this, we have developed the MP
            Shield®, which protects our clients from the drawbacks of price
            manipulation that often triggers unfair liquidation on other
            platforms. MP Shield™ is a unique development available exclusively
            on {siteSettings.siteName}.
          </p>
        </div>

        <div className="right">
          <p className="right__title">How does MP Shield™ work:</p>

          <div className="explains">
            {explains.map((explain) => (
              <span>
                <div className="marker"></div>
                <p className="explain">{explain}</p>
              </span>
            ))}
          </div>
        </div>
      </div>
    </ShieldWrapper>
  );
};

const ShieldWrapper = styled.div`
  .left {
    max-width: 450px;
  }

  .left__header {
    font-size: 20px;
    line-height: 25px;
    font-family: Gilroy;
    font-weight: 800;
    font-size: 1.25rem;
    line-height: 1.5625rem;
    margin-bottom: 8px;
  }

  .left__title {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-family: Gilroy;
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 12px;
    font-size: 36px;
  }

  .left__text {
    font-size: 16px;
    line-height: 150%;
  }

  .container {
    max-width: 1110px;
    margin: 0 auto;
    background: url(https://margex.com/static/shield-bg.b0e5474f.svg) center
      bottom no-repeat;
    background-color: rgb(19, 22, 34);
    border-radius: 8px;
    color: rgb(255, 255, 255);
    display: grid;
    grid-template-columns: repeat(2, 450px);
    justify-content: space-between;
    /* flex-wrap: wrap; */
    /* min-height: 430px; */
    gap: 32px;
    padding: 60px;
  }

  .container {
    @media screen and (max-width: 868px) {
      grid-template-columns: auto;
      border-radius: 0px;
    }
  }

  .explains span {
    display: grid;
    grid-template-columns: 4px auto;
    gap: 16px;
    align-items: first baseline;
    width: 100%;
  }

  .explains .marker {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: rgb(48, 113, 238);
  }

  .explains .explain {
    /* margin-top: 16px; */
    font-size: 14px;
    line-height: 19px;
    font-weight: 500;
  }
`;

export default Shield;
