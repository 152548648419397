import { useEffect } from "react";
import "../styles/about/about.css";
import Footer from "../components/general/Footer";
import Header from "../components/general/Header";
import { siteSettings } from "../static";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="___gatsby">
      <div style={{ outline: "none" }} tabIndex={-1} id="gatsby-focus-wrapper">
        <div className="DefaultLayout__BackgroundContainer-sc-1h2qfsw-0 bzHVOS">
          {/* header */}
          <Header />

          {/* body */}
          <section className="SectionAboutStart__AboutStartSection-sc-16xlpks-0 ejAlQY">
            <div className="Container-sc-rx245t-0 dGblwP">
              <h1 className="SectionAboutStart__CompanyName-sc-16xlpks-1 lkCCQV">
                <div className="SectionAboutStart__Startlogo-sc-16xlpks-2 eIhEFp">
                  <img
                    src="./logo-full.svg"
                    alt=""
                    style={{ width: "200px" }}
                  />
                </div>
                {/* {siteSettings.siteName} */}
              </h1>
              <p className="SectionAboutStart__MainText-sc-16xlpks-3 jWHlat">
                is a digital asset trading and investment platform
              </p>
              <p className="SectionAboutStart__SubText-sc-16xlpks-4 kulHDh">
                that provides access to a cutting edge trading infrastructure
                worldwide
              </p>
            </div>
          </section>
          <section className="SectionOurBelief__OurBeliefSection-sc-11bv13q-0 jsyEzW">
            <h3 className="SectionOurBelief__OurBeliefTitle-sc-11bv13q-1 cNzdnc">
              Our Belief
            </h3>
            <div className="SectionOurBelief__OurBeliefGraph-sc-11bv13q-2 hORKaJ">
              <div className="Container-sc-rx245t-0 dGblwP">
                <div className="SectionOurBelief__OurBeliefContainer-sc-11bv13q-3 gPKCsn">
                  <div className="SectionOurBelief__TextBlock-sc-11bv13q-4 cZeMxT">
                    <p className="SectionOurBelief__Description-sc-11bv13q-5 iGoBhI">
                      <span className="SectionOurBelief__BlueText-sc-11bv13q-6 erBWOw">
                        {siteSettings.siteName}
                      </span>{" "}
                      {/* */}is set on a mission to provide a fair, secure, and
                      easy-to-use platform with the highest class of financial
                      technologies globally.
                    </p>
                    <p className="SectionOurBelief__SubText-sc-11bv13q-7 dLqhMo">
                      We strongly believe that everyone should be able to enjoy
                      equal and unrestricted wealth-building opportunities no
                      matter who you are and where you live. Our goal is to make
                      complex systems simple to understand and easy to use.
                    </p>
                  </div>
                  {/* <img
                    src="static/kolo-1-7a1a0c0def0fba3f165b0fb86b2fe564.png"
                    alt="interface"
                  /> */}
                </div>
              </div>
            </div>
          </section>
          <section className="SectionBenefits__BenefitsSection-sc-zl4pjd-0 BuGxZ">
            <div className="Container-sc-rx245t-0 dGblwP">
              <div className="SectionBenefits__BenefitsContainer-sc-zl4pjd-1 fuLzOx">
                <div className="SectionBenefits__BenefitItem-sc-zl4pjd-2 dCOPgg">
                  <p className="SectionBenefits__BenefitValue-sc-zl4pjd-3 jBfscV">
                    100,000
                  </p>
                  <p className="SectionBenefits__BenefitDescription-sc-zl4pjd-4 ggRUjW">
                    Trades per second capacity
                  </p>
                </div>
                <div className="SectionBenefits__BenefitItem-sc-zl4pjd-2 dCOPgg">
                  <p className="SectionBenefits__BenefitValue-sc-zl4pjd-3 jBfscV">
                    $40,000,000
                  </p>
                  <p className="SectionBenefits__BenefitDescription-sc-zl4pjd-4 ggRUjW">
                    Order book depth
                  </p>
                </div>
                <div className="SectionBenefits__BenefitItem-sc-zl4pjd-2 dCOPgg">
                  <p className="SectionBenefits__BenefitValue-sc-zl4pjd-3 jBfscV">
                    8 ms
                  </p>
                  <p className="SectionBenefits__BenefitDescription-sc-zl4pjd-4 ggRUjW">
                    Average order execution time
                  </p>
                </div>
                <div className="SectionBenefits__BenefitItem-sc-zl4pjd-2 dCOPgg">
                  <p className="SectionBenefits__BenefitValue-sc-zl4pjd-3 jBfscV">
                    99.99%
                  </p>
                  <p className="SectionBenefits__BenefitDescription-sc-zl4pjd-4 ggRUjW">
                    Service uptime
                  </p>
                </div>
                <div className="SectionBenefits__BenefitItem-sc-zl4pjd-2 dCOPgg">
                  <p className="SectionBenefits__BenefitValue-sc-zl4pjd-3 jBfscV">
                    24/7
                  </p>
                  <p className="SectionBenefits__BenefitDescription-sc-zl4pjd-4 ggRUjW">
                    Support
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="SectionCareers__CareersSection-sc-bt5ur2-0 kRkphz">
            <h3 className="SectionCareers__CareersTitle-sc-bt5ur2-1 fXYGiN">
              Career opportunities
            </h3>
            <div className="SectionCareers__CareersText-sc-bt5ur2-2 kCqX">
              <div className="SectionCareers__CareersTextContainer-sc-bt5ur2-3 hzCoVZ">
                <p className="SectionCareers__CareersTextFirst-sc-bt5ur2-4 iUNyii">
                  We are always looking for
                </p>
                <p className="SectionCareers__CareersTextSecond-sc-bt5ur2-5 iQKXLp">
                  talented individuals
                </p>
                <p className="SectionCareers__CareersTextThird-sc-bt5ur2-6 jXruyt">
                  {" "}
                  {/* */}to join our team
                </p>
              </div>
            </div>
            <div className="SectionCareers__LinkBlock-sc-bt5ur2-7 XFRXP">
              <a
                href="careers.html"
                className="SectionCareers__CareersLink-sc-bt5ur2-8 hKxIBy"
              >
                Explore careers{/* */}{" "}
                <svg className="SectionCareers__StyledSvgUseIcon-sc-bt5ur2-9 lnxOUN">
                  <use href="#arrow-right" />
                </svg>
              </a>
            </div>
          </section>
          <section className="SectionPartnership__PartnershipSection-sc-7p8xc2-0 fKbWoo">
            <div className="SectionPartnership__PartnershipTexts-sc-7p8xc2-1 jVpVxQ">
              <h3 className="SectionPartnership__PartnershipText-sc-7p8xc2-2 lbFvsb">
                Are you looking for partnership opportunities or want to provide
                us with valuable feedback?
              </h3>
              <a
                href={`mailto:${siteSettings.supportEmail}`}
                className="SectionPartnership__PartnershipLink-sc-7p8xc2-3 eIjRPa"
              >
                Contact us
              </a>
            </div>
          </section>

          {/* footer */}
          <Footer />
        </div>
      </div>
      <div
        id="gatsby-announcer"
        style={{
          position: "absolute",
          top: 0,
          width: 1,
          height: 1,
          padding: 0,
          overflow: "hidden",
          clip: "rect(0, 0, 0, 0)",
          whiteSpace: "nowrap",
          border: 0,
        }}
        aria-live="assertive"
        aria-atomic="true"
      />
      <div className="Toastify" />
      <div />
    </div>
  );
};

export default About;
