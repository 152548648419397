import styled from "styled-components";
import { siteSettings } from "../../static";

const ctaData = [
  {
    title: "Register",
    text: `Quick sign up.`,
    brk: `Create an account in 10 minutes`,
  },
  {
    title: "Deposit",
    text: `Deposit Bitcoin directly or buy Bitcoin with `,
    brk: `a bank card and send it to your Wallet`,
  },
  {
    title: "Enjoy Trading",
    text: `Open long or short positions with up`,
    brk: `to 100x leverage to maximize your profits`,
  },
];

const CTA = () => {
  return (
    // <section
    //     className="SectionTradingStart__TradingStartSection-sc-15f2f1s-0 ejpjkt"
    //     style={{
    //       boxSizing: "border-box",
    //       WebkitTapHighlightColor: "transparent",
    //       margin: "0px",
    //       padding: "0px",
    //       border: "0px",
    //       font: "inherit",
    //       verticalAlign: "baseline",
    //       display: "block",
    //       paddingInlineStart: "9px",
    //       paddingRight: "9px",
    //       paddingTop: "40px",
    //       paddingBottom: "68px",
    //     }}
    //   >
    //     <div
    //       className="Container-sc-rx245t-0 dGblwP"
    //       style={{
    //         boxSizing: "border-box",
    //         WebkitTapHighlightColor: "transparent",
    //         margin: "0px",
    //         padding: "0px",
    //         border: "0px",
    //         font: "inherit",
    //         verticalAlign: "baseline",
    //         marginInline: "auto",
    //         paddingInline: "10px",
    //         width: "100%",
    //         contain: "none",
    //         maxWidth: "1190px",
    //         paddingRight: "40px",
    //         paddingInlineStart: "40px",
    //       }}
    //     >
    //       <h4
    //         className="SectionTradingStart__TradingStartTitle-sc-15f2f1s-1 GkCCM"
    //         style={{
    //           boxSizing: "border-box",
    //           WebkitTapHighlightColor: "transparent",
    //           margin: "0px",
    //           padding: "0px",
    //           border: "0px",
    //           font: "inherit",
    //           verticalAlign: "baseline",
    //           fontFamily: "Gilroy",
    //           fontWeight: 800,
    //           fontSize: "36px",
    //           lineHeight: "44px",
    //           textAlign: "center",
    //           marginBottom: "46px",
    //         }}
    //       >
    //         Start trading in under a minute
    //       </h4>
    //       <div
    //         className="SectionTradingStart__TradingStartItems-sc-15f2f1s-2 kTOwig"
    //         style={{
    //           boxSizing: "border-box",
    //           WebkitTapHighlightColor: "transparent",
    //           margin: "0px",
    //           padding: "0px",
    //           border: "0px",
    //           font: "inherit",
    //           verticalAlign: "baseline",
    //           display: "flex",
    //           flexWrap: "wrap",
    //           marginBottom: "48px",
    //         }}
    //       >
    //         <div
    //           className="SectionTradingStart__TradingStartItem-sc-15f2f1s-3 fSlvMS"
    //           style={{
    //             boxSizing: "border-box",
    //             WebkitTapHighlightColor: "transparent",
    //             margin: "0px",
    //             padding: "0px",
    //             border: "0px",
    //             font: "inherit",
    //             verticalAlign: "baseline",
    //             display: "flex",
    //             position: "relative",
    //             flex: "0 0 33.3333%",
    //             maxWidth: "33.3333%",
    //             flexDirection: "column",
    //             paddingBottom: "0px",
    //           }}
    //         >
    //           <div
    //             className="SectionTradingStart__TradingStartItemBadge-sc-15f2f1s-4 lntiTs"
    //             style={{
    //               boxSizing: "border-box",
    //               WebkitTapHighlightColor: "transparent",
    //               margin: "0px",
    //               padding: "0px",
    //               font: "inherit",
    //               verticalAlign: "baseline",
    //               border: "2px solid rgb(229, 234, 249)",
    //               borderRadius: "50%",
    //               display: "flex",
    //               WebkitBoxAlign: "center",
    //               alignItems: "center",
    //               WebkitBoxPack: "center",
    //               justifyContent: "center",
    //               width: "34px",
    //               height: "34px",
    //               backgroundColor: "rgb(255, 255, 255)",
    //               marginBottom: "12px",
    //               marginInline: "auto",
    //               marginInlineEnd: "auto",
    //             }}
    //           />
    //           <div
    //             className="SectionTradingStart__TradingStartItemInfo-sc-15f2f1s-5 WYqaT"
    //             style={{
    //               boxSizing: "border-box",
    //               WebkitTapHighlightColor: "transparent",
    //               margin: "0px",
    //               padding: "0px",
    //               border: "0px",
    //               font: "inherit",
    //               verticalAlign: "baseline",
    //             }}
    //           >
    //             <div
    //               className="SectionTradingStart__TradingStartItemTitle-sc-15f2f1s-6 kdSbyH"
    //               style={{
    //                 boxSizing: "border-box",
    //                 WebkitTapHighlightColor: "transparent",
    //                 margin: "0px",
    //                 padding: "0px",
    //                 border: "0px",
    //                 font: "inherit",
    //                 verticalAlign: "baseline",
    //                 fontWeight: 600,
    //                 marginBottom: "8px",
    //                 fontSize: "22px",
    //                 lineHeight: "30px",
    //                 textAlign: "center",
    //               }}
    //             >
    //               Register
    //             </div>
    //             <div
    //               className="SectionTradingStart__TradingStartItemDesc-sc-15f2f1s-7 irDGIz"
    //               style={{
    //                 boxSizing: "border-box",
    //                 WebkitTapHighlightColor: "transparent",
    //                 margin: "0px",
    //                 padding: "0px",
    //                 border: "0px",
    //                 font: "inherit",
    //                 verticalAlign: "baseline",
    //                 color: "rgb(122, 132, 157)",
    //                 fontSize: "16px",
    //                 lineHeight: "22px",
    //                 textAlign: "center",
    //               }}
    //             >
    //               Trade anonymously.{" "}
    //               <br
    //                 style={{
    //                   boxSizing: "border-box",
    //                   WebkitTapHighlightColor: "transparent",
    //                 }}
    //               />{" "}
    //               No personal data is required
    //             </div>
    //           </div>
    //         </div>
    //         <div
    //           className="SectionTradingStart__TradingStartItem-sc-15f2f1s-3 fSlvMS"
    //           style={{
    //             boxSizing: "border-box",
    //             WebkitTapHighlightColor: "transparent",
    //             margin: "0px",
    //             padding: "0px",
    //             border: "0px",
    //             font: "inherit",
    //             verticalAlign: "baseline",
    //             display: "flex",
    //             position: "relative",
    //             flex: "0 0 33.3333%",
    //             maxWidth: "33.3333%",
    //             flexDirection: "column",
    //             paddingBottom: "0px",
    //           }}
    //         >
    //           <div
    //             className="SectionTradingStart__TradingStartItemBadge-sc-15f2f1s-4 lntiTs"
    //             style={{
    //               boxSizing: "border-box",
    //               WebkitTapHighlightColor: "transparent",
    //               margin: "0px",
    //               padding: "0px",
    //               font: "inherit",
    //               verticalAlign: "baseline",
    //               border: "2px solid rgb(229, 234, 249)",
    //               borderRadius: "50%",
    //               display: "flex",
    //               WebkitBoxAlign: "center",
    //               alignItems: "center",
    //               WebkitBoxPack: "center",
    //               justifyContent: "center",
    //               width: "34px",
    //               height: "34px",
    //               backgroundColor: "rgb(255, 255, 255)",
    //               marginBottom: "12px",
    //               marginInline: "auto",
    //               marginInlineEnd: "auto",
    //             }}
    //           />
    //           <div
    //             className="SectionTradingStart__TradingStartItemInfo-sc-15f2f1s-5 WYqaT"
    //             style={{
    //               boxSizing: "border-box",
    //               WebkitTapHighlightColor: "transparent",
    //               margin: "0px",
    //               padding: "0px",
    //               border: "0px",
    //               font: "inherit",
    //               verticalAlign: "baseline",
    //             }}
    //           >
    //             <div
    //               className="SectionTradingStart__TradingStartItemTitle-sc-15f2f1s-6 kdSbyH"
    //               style={{
    //                 boxSizing: "border-box",
    //                 WebkitTapHighlightColor: "transparent",
    //                 margin: "0px",
    //                 padding: "0px",
    //                 border: "0px",
    //                 font: "inherit",
    //                 verticalAlign: "baseline",
    //                 fontWeight: 600,
    //                 marginBottom: "8px",
    //                 fontSize: "22px",
    //                 lineHeight: "30px",
    //                 textAlign: "center",
    //               }}
    //             >
    //               Deposit
    //             </div>
    //             <div
    //               className="SectionTradingStart__TradingStartItemDesc-sc-15f2f1s-7 irDGIz"
    //               style={{
    //                 boxSizing: "border-box",
    //                 WebkitTapHighlightColor: "transparent",
    //                 margin: "0px",
    //                 padding: "0px",
    //                 border: "0px",
    //                 font: "inherit",
    //                 verticalAlign: "baseline",
    //                 color: "rgb(122, 132, 157)",
    //                 fontSize: "16px",
    //                 lineHeight: "22px",
    //                 textAlign: "center",
    //               }}
    //             >
    //               Deposit Bitcoin directly or buy Bitcoin with
    //               <br
    //                 style={{
    //                   boxSizing: "border-box",
    //                   WebkitTapHighlightColor: "transparent",
    //                 }}
    //               />{" "}
    //               a bank card and send it to your Wallet
    //             </div>
    //           </div>
    //         </div>
    //         <div
    //           className="SectionTradingStart__TradingStartItem-sc-15f2f1s-3 fSlvMS"
    //           style={{
    //             boxSizing: "border-box",
    //             WebkitTapHighlightColor: "transparent",
    //             margin: "0px",
    //             padding: "0px",
    //             border: "0px",
    //             font: "inherit",
    //             verticalAlign: "baseline",
    //             display: "flex",
    //             position: "relative",
    //             flex: "0 0 33.3333%",
    //             maxWidth: "33.3333%",
    //             flexDirection: "column",
    //             paddingBottom: "0px",
    //           }}
    //         >
    //           <div
    //             className="SectionTradingStart__TradingStartItemBadge-sc-15f2f1s-4 lntiTs"
    //             style={{
    //               boxSizing: "border-box",
    //               WebkitTapHighlightColor: "transparent",
    //               margin: "0px",
    //               padding: "0px",
    //               font: "inherit",
    //               verticalAlign: "baseline",
    //               border: "2px solid rgb(229, 234, 249)",
    //               borderRadius: "50%",
    //               display: "flex",
    //               WebkitBoxAlign: "center",
    //               alignItems: "center",
    //               WebkitBoxPack: "center",
    //               justifyContent: "center",
    //               width: "34px",
    //               height: "34px",
    //               backgroundColor: "rgb(255, 255, 255)",
    //               marginBottom: "12px",
    //               marginInline: "auto",
    //               marginInlineEnd: "auto",
    //             }}
    //           />
    //           <div
    //             className="SectionTradingStart__TradingStartItemInfo-sc-15f2f1s-5 WYqaT"
    //             style={{
    //               boxSizing: "border-box",
    //               WebkitTapHighlightColor: "transparent",
    //               margin: "0px",
    //               padding: "0px",
    //               border: "0px",
    //               font: "inherit",
    //               verticalAlign: "baseline",
    //             }}
    //           >
    //             <div
    //               className="SectionTradingStart__TradingStartItemTitle-sc-15f2f1s-6 kdSbyH"
    //               style={{
    //                 boxSizing: "border-box",
    //                 WebkitTapHighlightColor: "transparent",
    //                 margin: "0px",
    //                 padding: "0px",
    //                 border: "0px",
    //                 font: "inherit",
    //                 verticalAlign: "baseline",
    //                 fontWeight: 600,
    //                 marginBottom: "8px",
    //                 fontSize: "22px",
    //                 lineHeight: "30px",
    //                 textAlign: "center",
    //               }}
    //             >
    //               Enjoy Trading
    //             </div>
    //             <div
    //               className="SectionTradingStart__TradingStartItemDesc-sc-15f2f1s-7 irDGIz"
    //               style={{
    //                 boxSizing: "border-box",
    //                 WebkitTapHighlightColor: "transparent",
    //                 margin: "0px",
    //                 padding: "0px",
    //                 border: "0px",
    //                 font: "inherit",
    //                 verticalAlign: "baseline",
    //                 color: "rgb(122, 132, 157)",
    //                 fontSize: "16px",
    //                 lineHeight: "22px",
    //                 textAlign: "center",
    //               }}
    //             >
    //               Open long or short positions with up{" "}
    //               <br
    //                 style={{
    //                   boxSizing: "border-box",
    //                   WebkitTapHighlightColor: "transparent",
    //                 }}
    //               />{" "}
    //               to 100x leverage to maximize your profits
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       <a
    //         className="SectionTradingStart__TradingStartBtn-sc-15f2f1s-8 fHkuRF"
    //         href="https://margex.com/app/signup"
    //         style={{
    //           boxSizing: "border-box",
    //           WebkitTapHighlightColor: "transparent",
    //           margin: "0px",
    //           font: "inherit",
    //           transition:
    //             "color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s",
    //           border: "1px solid rgb(48, 113, 238)",
    //           padding: "0.375rem 0.75rem",
    //           marginInline: "auto",
    //           borderRadius: "6px",
    //           textDecoration: "none",
    //           textTransform: "uppercase",
    //           lineHeight: 1.5,
    //           backgroundClip: "padding-box",
    //           textAlign: "center",
    //           verticalAlign: "middle",
    //           display: "flex",
    //           WebkitBoxAlign: "center",
    //           alignItems: "center",
    //           WebkitBoxPack: "center",
    //           justifyContent: "center",
    //           width: "263px",
    //           height: "52px",
    //           fontSize: "1rem",
    //           fontWeight: 600,
    //           color: "rgb(255, 255, 255)",
    //           backgroundColor: "rgb(48, 113, 238)",
    //           cursor: "pointer",
    //         }}
    //       >
    //         START TRADING
    //       </a>
    //     </div>
    //   </section>

    <CTAWrapper>
      <div className="container">
        <p className="section__title">Start trading in under a minute</p>

        <div className="wrapper">
          {ctaData.map((cta) => (
            <div className="cta__item">
              <div className="cursor"></div>
              <p className="title">{cta.title}</p>

              <p className="text">
                {cta.text} <br /> {cta.brk}{" "}
              </p>
            </div>
          ))}
        </div>

        <a href={siteSettings.appLink}>
          <button className="button">Start trading</button>
        </a>
      </div>
    </CTAWrapper>
  );
};

const CTAWrapper = styled.div`
  padding: 64px 0px;

  .container {
    max-width: 1110px;
    margin: 0 auto;
  }

  .wrapper {
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 24px;
    text-align: center;
  }

  .wrapper {
    @media screen and (max-width: 868px) {
      grid-template-columns: auto;
    }
  }

  .button {
    transition: color 0.15s ease-in-out 0s,
      background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s,
      box-shadow 0.15s ease-in-out 0s;
    text-transform: uppercase;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid rgb(48, 113, 238);
    padding: 0.375rem 0.75rem;
    text-align: center;
    vertical-align: middle;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 263px;
    height: 52px;
    margin-inline: auto;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 6px;
    text-decoration: none;
    color: rgb(255, 255, 255);
    background-color: rgb(48, 113, 238);
    cursor: pointer;
    margin-top: 32px;
  }

  .section__title {
    text-align: center;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    margin-bottom: 46px;
    font-family: Gilroy;
    font-weight: 800;
  }

  .wrapper {
  }

  .wrapper .cta__item {
    display: grid;
    justify-items: center;
  }

  .wrapper .cta__item .title {
    line-height: 30px;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 8px;
    font-family: "Open Sans";
  }

  .wrapper .cta__item .text {
    line-height: 19px;
    color: rgb(122, 132, 157);
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    font-weight: 600;
  }

  .cta__item .cursor {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    border: 2px solid rgb(229, 234, 249);
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    margin-bottom: 12px;
    margin-inline-end: 12px;
  }

  .cta__item .cursor::after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgb(48, 113, 238);
  }
`;

export default CTA;
