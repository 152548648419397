import "../styles/home/home.css";
import CTA from "../components/home/CTA";
import CloserCTA from "../components/home/CloserCTA";
import Features from "../components/home/Features";
import Shield from "../components/home/Shield";
import { Significant } from "../components/home/Significant";
import Testimonials from "../components/home/Testimonials";
import WhyUs from "../components/home/WhyUs";
import Banner from "../components/home/Banner";
import Header from "../components/general/Header";
import MainLockup from "../components/home/MainLockup";
import CopyExpertsPopup from "../components/home/CopyExpertsPopup";
import Footer from "../components/general/Footer";
import { HeaderActual } from "../components/general/HeaderActual";
import { FeaturesTwoWithImage } from "../components/features/FeaturesTwoWithImage";

const Home = () => {
  return (
    <div>
      <div style={{ outline: "none" }} tabIndex={-1} id="gatsby-focus-wrapper">
        {/* banner */}
        {/* <Banner /> */}

        {/* main */}
        <div className="DefaultLayout__BackgroundContainer-sc-1h2qfsw-0 bzHVOS">
          {/* section:header and nav */}
          {/* <div style={{ backgroundColor: "#0c0f19" }}> */}
          <Header />
          {/* </div> */}
          <section className="SectionStart__StartSection-sc-dij555-0 cDHcaM">
            {/* section:lockup */}
            <MainLockup />
          </section>

          {/* section:features */}
          <FeaturesTwoWithImage />

          {/* section:why */}
          <WhyUs />

          {/* section:shield */}
          <Shield />

          {/* section:significant */}
          {/* <Significant /> */}

          {/* section:CTA */}
          <CTA />

          {/* section:testimonials */}
          <Testimonials />

          {/* section:closerCTA */}
          <CloserCTA />

          {/* section:copytrading-popup */}

          {/* <CopyExpertsPopup /> */}
          {/* section:footer */}

          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Home;
