import { useEffect } from "react";
import "../styles/contact/contact.css";
import Header from "../components/general/Header";
import Footer from "../components/general/Footer";
import { siteSettings } from "../static";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="___gatsby">
      <div style={{ outline: "none" }} tabIndex={-1} id="gatsby-focus-wrapper">
        <div className="DefaultLayout__BackgroundContainer-sc-1h2qfsw-0 bzHVOS">
          {/* header */}
          <Header />

          {/* body */}
          <section className="SectionStart__StartSection-sc-1ymsayc-0 hrUNVD">
            <div className="Container-sc-rx245t-0 dGblwP">
              <h1 className="SectionStart__Title-sc-1ymsayc-1 jMkwrW">
                Contact us
              </h1>
              <div className="SectionStart__SubTitle-sc-1ymsayc-2 ipDJMU">
                Have any questions? We'd love to hear from you
              </div>
            </div>
          </section>
          <div className="Container-sc-rx245t-0 SectionCards__CardsSection-sc-irdd1p-0 dGblwP kgPZif">
            {/* <div className="SectionCards__CardItem-sc-irdd1p-1 dOhrLN">
              <div className="SectionCards__CardBody-sc-irdd1p-2 gssdpG">
                <h5 className="SectionCards__CardTitle-sc-irdd1p-3 cXhGEb">
                  Press &amp; Media
                </h5>
                <p className="SectionCards__CardDescription-sc-irdd1p-4 hkPhqV">
                  Working on a Margex story? Want to take a comment of the
                  Margex team? Need to get in touch? If you have questions,
                  we've got answers.
                </p>
                <div className="SectionCards__BtnBlock-sc-irdd1p-5 hkqZUM">
                  <a
                    href="cdn-cgi/l/email-protection.html#4924283b222c3d20272e0924283b2e2c31672a2624"
                    rel="noreferrer"
                    className="SectionCards__Btn-sc-irdd1p-6 goFjdA"
                  >
                    Contact Press team
                  </a>
                </div>
              </div>
            </div> */}
            <div className="SectionCards__CardItem-sc-irdd1p-1 dOhrLN">
              <div className="SectionCards__CardBody-sc-irdd1p-2 gssdpG">
                <h5 className="SectionCards__CardTitle-sc-irdd1p-3 cXhGEb">
                  Help &amp; Support
                </h5>
                <p className="SectionCards__CardDescription-sc-irdd1p-4 hkPhqV">
                  The {siteSettings.siteName} support team is spread across the
                  globe. We will answer all your questions with our multilingual
                  customer service team
                </p>
                <div className="SectionCards__BtnBlock-sc-irdd1p-5 hkqZUM">
                  <a
                    href={`mailto:${siteSettings.supportEmail}`}
                    rel="noreferrer"
                    className="SectionCards__Btn-sc-irdd1p-6 goFjdA"
                  >
                    Send us an email
                  </a>
                  {/* <p className="SectionCards__CardBottomBtn-sc-irdd1p-7 jxKuOJ">
                    <a
                      href="cdn-cgi/l/email-protection.html#60131510100f1214200d01120705184e030f0d"
                      className="SectionCards__Link-sc-irdd1p-8 icXLqY"
                    >
                      Submit a request by email
                    </a>
                  </p> */}
                </div>
              </div>
            </div>
            {/* <div className="SectionCards__CardItem-sc-irdd1p-1 dOhrLN">
              <div className="SectionCards__CardBody-sc-irdd1p-2 gssdpG">
                <h5 className="SectionCards__CardTitle-sc-irdd1p-3 cXhGEb">
                  Affiliate &amp; Partnership
                </h5>
                <p className="SectionCards__CardDescription-sc-irdd1p-4 hkPhqV">
                  Become Margex's business partner and start converting your
                  influence into profit. Have a source of traffic? Contact us to
                  discuss our partnership
                </p>
                <div className="SectionCards__BtnBlock-sc-irdd1p-5 hkqZUM">
                  <a
                    href="cdn-cgi/l/email-protection.html#7f121e0d141a0b1611183f121e0d181a07511c1012"
                    rel="noreferrer"
                    className="SectionCards__Btn-sc-irdd1p-6 goFjdA"
                  >
                    Submit a proposal
                  </a>
                </div>
              </div>
            </div> */}
            {/* <div className="SectionCards__CardItem-sc-irdd1p-1 dOhrLN">
              <div className="SectionCards__CardBody-sc-irdd1p-2 gssdpG">
                <h5 className="SectionCards__CardTitle-sc-irdd1p-3 cXhGEb">
                  Job &amp; Career
                </h5>
                <p className="SectionCards__CardDescription-sc-irdd1p-4 hkPhqV">
                  Are you an experienced professional? Do you want to start
                  working in a large international startup? Take a look at
                  current vacancies
                </p>
                <div className="SectionCards__BtnBlock-sc-irdd1p-5 hkqZUM">
                  <a
                    className="SectionCards__Btn-sc-irdd1p-6 goFjdA"
                    href="careers.html"
                  >
                    Visit Career page
                  </a>
                </div>
              </div>
            </div> */}
          </div>
          <section className="SectionOtherRequest__OtherRequestSection-sc-uo4kjh-0 gcCcSm">
            <p className="SectionOtherRequest__RequestText-sc-uo4kjh-1 foLbDS">
              For any other requests please contact us at:
            </p>
            <a
              href={`mailto:${siteSettings.supportEmail}`}
              className="SectionOtherRequest__Email-sc-uo4kjh-2 fwNudq"
            >
              <span
                className="__cf_email__"
                data-cfemail="f1989f979eb19c9083969489df929e9c"
              >
                {siteSettings.supportEmail}
              </span>
            </a>
          </section>

          {/* footer */}
          <Footer />
        </div>
      </div>
      <div
        id="gatsby-announcer"
        style={{
          position: "absolute",
          top: 0,
          width: 1,
          height: 1,
          padding: 0,
          overflow: "hidden",
          clip: "rect(0, 0, 0, 0)",
          whiteSpace: "nowrap",
          border: 0,
        }}
        aria-live="assertive"
        aria-atomic="true"
      />
      <div className="Toastify" />
      <div />
    </div>
  );
};

export default Contact;
