import { Link } from "react-router-dom";
import { siteSettings } from "../../static";

// const Footer = () => {
//   return (
//     <footer className="Footer__LayoutFooter-sc-s51cim-0 cTugls">
//       <div className="Footer__FooterContainer-sc-s51cim-1 dATFpT">
//         <div className="Container-sc-rx245t-0 dGblwP">
//           <div className="Footer__Row-sc-s51cim-2 kVSuGe">
//             <div className="Footer__LogoCol-sc-s51cim-7 jPIwTI">
//               <a
//                 aria-current="page"
//                 className="Footer__FooterLogoLink-sc-s51cim-3 hqJpvX"
//                 href="index.html"
//               >
//                 <img
//                   alt="logo"
//                   src="./logo-full.png"
//                   style={{ width: "160px" }}
//                   // width={137}
//                   // height={`auto`}
//                 />
//               </a>
//               <div className="Footer__LogoText-sc-s51cim-4 kQCuRa">
//                 Secure. Transparent. Lightning fast
//               </div>

//               {/* social_links */}
//               {/* <div className="Footer__SocialLinks-sc-s51cim-15 cPjuLt">
//                 <a
//                   href="https://twitter.com/{siteSettings.siteName}com"
//                   className="Footer__SocialLink-sc-s51cim-16 fOAHeG"
//                 >
//                   <div className="Footer__FooterIconContainer-sc-s51cim-17 cEtGEv">
//                     <svg
//                       width={24}
//                       height={24}
//                       viewBox="0 0 24 24"
//                       fill="none"
//                       xmlns="http://www.w3.org/2000/svg"
//                     >
//                       <path
//                         fillRule="evenodd"
//                         clipRule="evenodd"
//                         d="M9.7431 12.2661L3 3.6875H8.34101L12.5035 8.98963L16.9504 3.71137H19.892L13.9257 10.8013L21 19.8125H15.6749L11.1678 14.0786L6.35607 19.7966H3.39858L9.7431 12.2661ZM16.4511 18.2231L6.27493 5.27695H7.56424L17.7276 18.2231H16.4511Z"
//                         fill="#3071EE"
//                       />
//                     </svg>
//                   </div>
//                 </a>
//                 <a
//                   href="https://t.me/{siteSettings.siteName}_official"
//                   className="Footer__SocialLink-sc-s51cim-16 fOAHeG"
//                 >
//                   <div className="Footer__FooterIconContainer-sc-s51cim-17 cEtGEv">
//                     <svg
//                       viewBox="0 0 24 24"
//                       fill="none"
//                       xmlns="http://www.w3.org/2000/svg"
//                     >
//                       <path
//                         d="m9.778 18.646.282-4.225 7.685-6.934c.337-.3-.074-.446-.522-.174l-9.485 5.984-4.097-1.279c-.885-.27-.891-.879.199-1.316l15.966-6.158c.729-.33 1.433.175 1.154 1.291l-2.72 12.813c-.19.91-.74 1.128-1.501.708l-4.141-3.06-1.992 1.936c-.23.226-.414.414-.828.414z"
//                         fill="#3071EE"
//                       />
//                     </svg>
//                   </div>
//                 </a>
//                 <a
//                   href="https://www.facebook.com/{siteSettings.siteName}com"
//                   className="Footer__SocialLink-sc-s51cim-16 fOAHeG"
//                 >
//                   <div className="Footer__FooterIconContainer-sc-s51cim-17 cEtGEv">
//                     <svg
//                       viewBox="0 0 24 24"
//                       fill="none"
//                       xmlns="http://www.w3.org/2000/svg"
//                     >
//                       <path
//                         d="M17 2v4.002h-2c-.69 0-1 .809-1 1.5v2.5h3v4h-3v8L10 22v-7.998H7v-4h3v-4a4 4 0 0 1 4-4L17 2z"
//                         fill="#3071EE"
//                       />
//                     </svg>
//                   </div>
//                 </a>
//                 <a
//                   href="https://www.youtube.com/c/{siteSettings.siteName}"
//                   className="Footer__SocialLink-sc-s51cim-16 fOAHeG"
//                 >
//                   <div className="Footer__FooterIconContainer-sc-s51cim-17 cEtGEv">
//                     <svg
//                       viewBox="0 0 23 25"
//                       fill="none"
//                       xmlns="http://www.w3.org/2000/svg"
//                     >
//                       <g clipPath="url(#youtubeIcon_svg__a)">
//                         <path
//                           d="M21.415 6.155c-.24-1.108-.947-1.981-1.845-2.278-1.626-.538-8.15-.538-8.15-.538s-6.522 0-8.149.538c-.897.297-1.604 1.17-1.844 2.278-.436 2.01-.436 6.202-.436 6.202s0 4.192.436 6.202c.24 1.108.947 1.945 1.844 2.241 1.627.539 8.15.539 8.15.539s6.523 0 8.15-.538c.897-.297 1.604-1.134 1.844-2.242.436-2.01.436-6.202.436-6.202s0-4.192-.436-6.202zM9.288 16.163V8.551l5.451 3.806-5.452 3.806z"
//                           fill="#3071ee"
//                         />
//                       </g>
//                       <defs>
//                         <clipPath id="youtubeIcon_svg__a">
//                           <path
//                             fill="#fff"
//                             transform="translate(.42 .339)"
//                             d="M0 0h22v24H0z"
//                           />
//                         </clipPath>
//                       </defs>
//                     </svg>
//                   </div>
//                 </a>
//               </div> */}
//             </div>

//             {/* platform */}
//             <div className="Footer__Col-sc-s51cim-6 gJuKws">
//               <div
//                 className="Footer__ColTitle-sc-s51cim-5 hOSqSJ"
//                 style={{ textTransform: "uppercase" }}
//               >
//                 {siteSettings.siteName} PLATFORM
//               </div>
//               <ul className="NavUl-sc-hbrp4m-0 Footer__Ul-sc-s51cim-8 btfRVJ ipnkSs">
//                 <li className="Footer__Li-sc-s51cim-9 huVXwp">
//                   <Link
//                     className="Footer__StyledLink-sc-s51cim-10 kvOlTk"
//                     to={"/contact"}
//                   >
//                     Contact us
//                   </Link>
//                 </li>

//                 <li className="Footer__Li-sc-s51cim-9 huVXwp">
//                   {/* <a

//                     href="platforms-security.html"
//                   >
//                     Security
//                   </a> */}

//                   <Link
//                     className="Footer__StyledLink-sc-s51cim-10 kvOlTk"
//                     to={"/security"}
//                   >
//                     Security
//                   </Link>
//                 </li>

//                 <li className="Footer__Li-sc-s51cim-9 huVXwp">
//                   {/* <a
//                     className="Footer__StyledLink-sc-s51cim-10 kvOlTk"
//                     href="about-us.html"
//                   >
//                     About {siteSettings.siteName}
//                   </a> */}
//                   <Link
//                     className="Footer__StyledLink-sc-s51cim-10 kvOlTk"
//                     to={"/about"}
//                   >
//                     About {siteSettings.siteName}
//                   </Link>
//                 </li>
//               </ul>
//             </div>

//             <br />

//             {/* earn */}
//             <div className="Footer__Col-sc-s51cim-6 gJuKws">
//               <div className="Footer__ColTitle-sc-s51cim-5 hOSqSJ">
//                 EARN CRYPTO
//               </div>
//               <ul className="NavUl-sc-hbrp4m-0 Footer__Ul-sc-s51cim-8 btfRVJ ipnkSs">
//                 <li className="Footer__Li-sc-s51cim-9 huVXwp">
//                   {/* <a
//                     className="Footer__StyledLink-sc-s51cim-10 kvOlTk"
//                     href="copytrading.html"
//                   >
//                     Copy Trading
//                     <span className="Badge__BadgeWarning-sc-g5fonl-0 Footer__BadgeWarningWithMargin-sc-s51cim-11 fBHSsY iWOnlm">
//                       HOT
//                     </span>
//                   </a> */}

//                   <Link
//                     className="Footer__StyledLink-sc-s51cim-10 kvOlTk"
//                     to={"/copy"}
//                   >
//                     Copy Trading
//                   </Link>
//                 </li>
//                 <li className="Footer__Li-sc-s51cim-9 huVXwp">
//                   {/* <a

//                     href="staking.html"
//                   >

//                   </a> */}
//                   <Link
//                     className="Footer__StyledLink-sc-s51cim-10 kvOlTk"
//                     to={"/staking"}
//                   >
//                     Staking 💰
//                   </Link>
//                 </li>
//               </ul>
//             </div>

//             <br />

//             {/* live */}
//             <div className="Footer__Col-sc-s51cim-6 gJuKws">
//               <div className="Footer__ColTitle-sc-s51cim-5 hOSqSJ">LIVE</div>
//               <ul className="NavUl-sc-hbrp4m-0 Footer__Ul-sc-s51cim-8 btfRVJ ipnkSs">
//                 <li className="Footer__Li-sc-s51cim-9 huVXwp">
//                   {/* <a
//                     href="trade/btcusd.html"
//                     rel="noreferrer"
//                     className="Footer__StyledLink-sc-s51cim-10 kvOlTk"
//                   >

//                   </a> */}
//                   <Link
//                     className="Footer__StyledLink-sc-s51cim-10 kvOlTk"
//                     to={siteSettings.appLink}
//                   >
//                     Live trading
//                   </Link>
//                 </li>
//               </ul>
//             </div>

//             {/* break */}
//           </div>
//         </div>
//       </div>
//       <div className="Footer__FooterPrivacy-sc-s51cim-12 ekWFop">
//         <div className="Container-sc-rx245t-0 dGblwP">
//           <ul className="NavUl-sc-hbrp4m-0 Footer__FooterPrivacyUl-sc-s51cim-13 btfRVJ foNJWL">
//             <li className="Footer__Li-sc-s51cim-9 huVXwp">
//               {/* <a
//                 className="Footer__StyledLinkPrivacy-sc-s51cim-14 jLelOp"
//                 href="copy-trading-terms.html"
//               >
//                 Copy Trading terms
//               </a> */}

//               <Link
//                 className="Footer__StyledLinkPrivacy-sc-s51cim-14 jLelOp"
//                 to={"/copy-terms"}
//               >
//                 Copy Trading terms
//               </Link>
//             </li>

//             <li className="Footer__Li-sc-s51cim-9 huVXwp">
//               {/* <a
//                 className="Footer__StyledLinkPrivacy-sc-s51cim-14 jLelOp"
//                 href="cookie-policy.html"
//               >
//                 Cookie policy
//               </a> */}
//               <Link
//                 className="Footer__StyledLinkPrivacy-sc-s51cim-14 jLelOp"
//                 to={"/cookie"}
//               >
//                 Cookie policy
//               </Link>
//             </li>

//             <li className="Footer__Li-sc-s51cim-9 huVXwp">
//               {/* <a
//                 className="Footer__StyledLinkPrivacy-sc-s51cim-14 jLelOp"
//                 href="staking-terms.html"
//               >
//                 Staking terms
//               </a> */}
//               <Link
//                 className="Footer__StyledLinkPrivacy-sc-s51cim-14 jLelOp"
//                 to={"/staking-terms"}
//               >
//                 Staking terms
//               </Link>
//             </li>

//             <li className="Footer__Li-sc-s51cim-9 huVXwp">
//               {/* <a
//                 className="Footer__StyledLinkPrivacy-sc-s51cim-14 jLelOp"
//                 href="privacy-policy.html"
//               >
//                 Privacy policy
//               </a> */}

//               <Link
//                 className="Footer__StyledLinkPrivacy-sc-s51cim-14 jLelOp"
//                 to={"/privacy"}
//               >
//                 Privacy policy
//               </Link>
//             </li>

//             <li className="Footer__Li-sc-s51cim-9 huVXwp">
//               {/* <a
//                 className="Footer__StyledLinkPrivacy-sc-s51cim-14 jLelOp"
//                 href="terms-and-conditions.html"
//               >
//                 Terms of service
//               </a> */}

//               <Link
//                 className="Footer__StyledLinkPrivacy-sc-s51cim-14 jLelOp"
//                 to={"/terms"}
//               >
//                 Terms of service
//               </Link>
//             </li>
//           </ul>
//           <div className="Footer__WarningContainer-sc-s51cim-18 cHkgYn">
//             <p className="Footer__WarnigTitle-sc-s51cim-19 dndRNe">
//               Risk Warning:
//             </p>
//             These website products and services are provided by{" "}
//             {siteSettings.siteName} Trading Solutions Ltd.{" "}
//             {siteSettings.siteName} does not provide services to residents of
//             certain jurisdictions including the United States of America, the
//             Republic of Seychelles, Bermuda, Cuba, Crimea, Sevastopol, Iran,
//             Syria, North Korea, Sudan, and Afghanistan. Please note that
//             cryptocurrencies, cryptocurrency leveraged products, and other
//             products and services provided by {siteSettings.siteName} Trading
//             Services Ltd involve a significant risk of financial losses. It is
//             not suitable for all investors and you should make sure you
//             understand the risks involved, seeking independent advice if
//             necessary. You are solely responsible for complying with all
//             applicable laws related to Your trading activities including without
//             limitation any reporting obligations and payment of all applicable
//             taxes in a jurisdiction(s) in which You may be liable to pay tax.
//           </div>
//         </div>
//       </div>

//       {/* social */}
//       {/* <div className="/icons__DisplayNone-sc-g262n3-0 kYhTYJ">
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           xmlnsXlink="http://www.w3.org/1999/xlink"
//         >
//           <symbol
//             viewBox="0 0 18 12"
//             id="arrow-right"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M0 5.25v1.5h15.127L11 10.879l1.06 1.06L18 6 12.06.06 11 1.121l4.127 4.129H0z"
//               data-fill="true"
//             ></path>
//           </symbol>
//           <symbol
//             viewBox="0 0 28 28"
//             id="benefit-bank"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M7.583 11.667H5.25v8.166h2.333v-8.166zm7 0H12.25v8.166h2.333v-8.166zm9.917 10.5H2.333V24.5H24.5v-2.333zm-2.917-10.5H19.25v8.166h2.333v-8.166zm-8.167-7.864L19.495 7H7.338l6.078-3.197zm0-2.636L2.333 7v2.333H24.5V7L13.416 1.167z"
//               data-fill="true"
//             />
//           </symbol>
//           <symbol
//             viewBox="0 0 28 28"
//             id="benefit-rating"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M4.083 21.572l7-7.012 4.667 4.667 9.916-11.154-1.645-1.645-8.271 9.299-4.667-4.667-8.75 8.762 1.75 1.75z"
//               data-fill="true"
//             />
//           </symbol>
//           <symbol
//             viewBox="0 0 28 28"
//             id="benefit-shild"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M14 1.167L3.5 5.833v7c0 6.475 4.48 12.53 10.5 14 6.02-1.47 10.5-7.525 10.5-14v-7L14 1.167zm0 2.543l8.167 3.64v5.74c0 1.988-.597 3.996-1.58 5.777-1.927-1.435-5.122-1.95-6.587-1.95-1.466 0-4.66.516-6.587 1.953-.985-1.782-1.58-3.792-1.58-5.78V7.35L14 3.709v.001zM14 7a4.083 4.083 0 100 8.167A4.083 4.083 0 0014 7zm0 2.333a1.75 1.75 0 110 3.5 1.75 1.75 0 010-3.5zm0 9.917c1.828 0 4.245.713 5.288 1.559C17.845 22.606 15.985 23.97 14 24.5c-1.983-.529-3.842-1.892-5.286-3.689C9.76 19.965 12.17 19.25 14 19.25z"
//               data-fill="true"
//             />
//           </symbol>
//           <symbol
//             viewBox="0 0 32 32"
//             id="bitcoin"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M19.414 13.806c-.473 1.89-3.414.93-4.328.67l.838-3.322c.945.275 3.977.67 3.49 2.652zm-4.74 2.377l-.914 3.672c1.128.29 4.617 1.402 5.135-.67.55-2.164-3.093-2.728-4.22-3.002zm16.107 3.505c-2.042 8.167-10.3 13.135-18.468 11.093S-.818 20.481 1.219 12.312A15.232 15.232 0 0119.688 1.22c8.152 2.041 13.12 10.3 11.092 18.468zM19.368 9.98l.686-2.743-1.677-.38-.67 2.635c-.442-.106-.884-.213-1.341-.304l.67-2.698-1.66-.396-.686 2.728c-.366-.092-.732-.168-1.067-.26l-2.3-.578-.458 1.783s1.25.29 1.22.304c.685.168.807.595.776.976l-1.874 7.512c-.076.213-.32.488-.762.412.015.015-1.219-.305-1.219-.305l-.823 1.904 2.164.55c.411.106.808.212 1.204.304l-.701 2.773 1.676.427.686-2.758c.457.122.899.228 1.325.35l-.685 2.728 1.676.427.7-2.774c2.82.534 4.984.32 5.867-2.255.762-2.057 0-3.276-1.523-4.053 1.097-.29 1.92-.976 2.148-2.469.305-2.027-1.25-3.109-3.352-3.84z"
//               data-fill="true"
//             />
//           </symbol>
//           <symbol
//             viewBox="0 0 32 32"
//             id="chart-bell"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M5.333 25.333v1.334h24v2.666H2.667V2.667h2.666v20c4 0 8-2.667 10.8-7.467 4-6.667 8.4-9.867 13.2-9.867V8C25.6 8 22 10.8 18.533 16.667c-3.467 5.466-8.267 8.666-13.2 8.666z"
//               data-fill="true"
//             />
//           </symbol>
//           <symbol
//             viewBox="0 0 16 14"
//             id="coming-soon-like"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M15.334 6.665A1.333 1.333 0 0014 5.332H9.79l.636-3.046a.995.995 0 00-.272-.917L9.448.665 5.057 5.056a1.33 1.33 0 00-.39.943v6.666A1.333 1.333 0 006 14h6a1.332 1.332 0 001.228-.814l2.012-4.702A1.33 1.33 0 0015.334 8V6.665zM.667 14h2.667V6H.667v8z"
//               data-fill="true"
//             />
//           </symbol>
//           <symbol
//             viewBox="0 0 24 24"
//             id="facebook"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M17 2v4.002h-2c-.69 0-1 .809-1 1.5v2.5h3v4h-3v8L10 22v-7.998H7v-4h3v-4a4 4 0 014-4L17 2z"
//               data-fill="true"
//             />
//           </symbol>
//           <symbol
//             viewBox="0 0 24 24"
//             id="medium"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M4.372 7.3a.775.775 0 00-.252-.653l-1.868-2.25v-.336H8.05l4.482 9.83 3.94-9.83H22v.336l-1.597 1.53a.466.466 0 00-.177.449v11.248c-.029.17.04.343.177.448l1.56 1.53v.337h-7.844v-.336l1.616-1.568c.159-.159.159-.206.159-.448V8.495l-4.492 11.407h-.606L5.566 8.495v7.645c-.043.321.064.645.29.877l2.101 2.549v.336H2v-.336l2.1-2.549c.226-.233.326-.558.272-.877V7.3z"
//               data-fill="true"
//             />
//           </symbol>
//           <symbol
//             viewBox="0 0 16 16"
//             id="proposal-link"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M0 7v2h12.01l-5.505 5.505 1.414 1.414L15.84 8 7.92.08 6.506 1.495 12.01 7H0z"
//               data-fill="true"
//             ></path>
//           </symbol>
//           <symbol
//             viewBox="0 0 24 24"
//             id="reddit"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M22 12.143a2.183 2.183 0 00-2.186-2.186 2.2 2.2 0 00-1.524.61c-1.505-1.076-3.566-1.775-5.86-1.864l1.004-4.696 3.261.7a1.56 1.56 0 101.56-1.63 1.553 1.553 0 00-1.398.877l-3.638-.771a.382.382 0 00-.395.16.347.347 0 00-.053.144L11.66 8.72c-2.33.072-4.426.77-5.95 1.864a2.201 2.201 0 00-2.898-.126 2.183 2.183 0 00.48 3.693c-.037.215-.054.43-.054.663 0 3.369 3.924 6.11 8.763 6.11 4.84 0 8.764-2.723 8.764-6.11 0-.215-.018-.448-.054-.663A2.208 2.208 0 0022 12.143zm-15.018 1.56a1.56 1.56 0 013.118 0 1.56 1.56 0 01-1.559 1.559c-.86.018-1.559-.7-1.559-1.56zm8.728 4.14c-1.076 1.075-3.119 1.146-3.71 1.146-.61 0-2.652-.09-3.71-1.147a.4.4 0 010-.573.401.401 0 01.574 0c.68.68 2.115.914 3.136.914 1.022 0 2.473-.233 3.136-.914a.402.402 0 01.574 0 .436.436 0 010 .573zm-.287-2.563a1.56 1.56 0 010-3.118c.86 0 1.56.698 1.56 1.558 0 .843-.7 1.56-1.56 1.56z"
//               data-fill="true"
//             />
//           </symbol>
//           <symbol
//             viewBox="0 0 9 16"
//             id="right"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M8.707 8.707a1 1 0 000-1.414L2.343.929A1 1 0 00.93 2.343L6.586 8 .929 13.657a1 1 0 101.414 1.414l6.364-6.364zM7.5 9H8V7h-.5v2z"
//               data-fill="true"
//             />
//           </symbol>
//           <symbol
//             viewBox="0 0 32 32"
//             id="shield-account"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M16 1.333L4 6.667v8c0 7.4 5.12 14.32 12 16 6.88-1.68 12-8.6 12-16v-8L16 1.333zm0 2.907l9.333 4.16v6.56c0 2.272-.681 4.567-1.805 6.603-2.203-1.64-5.853-2.23-7.528-2.23-1.676 0-5.325.591-7.528 2.232-1.125-2.036-1.805-4.333-1.805-6.605V8.4L16 4.239v.001zM16 8a4.667 4.667 0 100 9.334A4.667 4.667 0 0016 8zm0 2.667a2 2 0 110 4 2 2 0 010-4zM16 22c2.09 0 4.852.815 6.044 1.781C20.394 25.836 18.268 27.395 16 28c-2.267-.604-4.39-2.163-6.041-4.216C11.155 22.817 13.909 22 16 22z"
//               data-fill="true"
//             />
//           </symbol>
//           <symbol
//             viewBox="0 0 24 32"
//             id="shield-outline"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M24 14.667c0 7.4-5.12 14.32-12 16-6.88-1.68-12-8.6-12-16v-8l12-5.334 12 5.334v8zM12 28c5-1.333 9.333-7.281 9.333-13.037V8.4L12 4.244 2.667 8.4v6.564C2.667 20.72 7 26.667 12 28z"
//               data-fill="true"
//             />
//           </symbol>
//           <symbol
//             viewBox="0 0 24 24"
//             id="telegram"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M9.778 18.646l.282-4.225 7.685-6.934c.337-.3-.074-.446-.522-.174l-9.485 5.984-4.097-1.279c-.885-.27-.891-.879.199-1.316l15.966-6.158c.729-.33 1.433.175 1.154 1.291l-2.72 12.813c-.19.91-.74 1.128-1.501.708l-4.141-3.06-1.992 1.936c-.23.226-.414.414-.828.414z"
//               data-fill="true"
//             />
//           </symbol>
//           <symbol
//             viewBox="0 0 24 24"
//             id="twitter"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M22.46 6.012a8.58 8.58 0 01-2.466.676 4.305 4.305 0 001.887-2.374 8.59 8.59 0 01-2.725 1.041 4.292 4.292 0 00-7.313 3.914 12.184 12.184 0 01-8.846-4.483 4.29 4.29 0 001.329 5.729 4.276 4.276 0 01-1.945-.537v.054c0 2.08 1.48 3.814 3.443 4.209a4.295 4.295 0 01-1.939.073 4.296 4.296 0 004.01 2.98 8.61 8.61 0 01-5.33 1.838c-.347 0-.688-.02-1.024-.06A12.147 12.147 0 008.12 21c7.893 0 12.21-6.54 12.21-12.21 0-.187-.004-.372-.012-.556a8.719 8.719 0 002.141-2.222h.001z"
//               data-fill="true"
//             />
//           </symbol>
//           <symbol
//             viewBox="0 0 24 24"
//             id="way-account"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M15 14c-2.7 0-8 1.3-8 4v2h16v-2c0-2.7-5.3-4-8-4zm0-2c2.2 0 4-1.8 4-4s-1.8-4-4-4-4 1.8-4 4 1.8 4 4 4zM5 15l-.6-.5C2.4 12.6 1 11.4 1 9.9c0-1.2 1-2.2 2.2-2.2.7 0 1.4.3 1.8.8.4-.5 1.1-.8 1.8-.8C8 7.7 9 8.6 9 9.9c0 1.5-1.4 2.7-3.4 4.6L5 15z"
//               data-fill="true"
//             />
//           </symbol>
//           <symbol
//             viewBox="0 0 24 24"
//             id="way-chart"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M14 6h8v16h-8V6zM2 4h20V2H2v2zm0 4h10V6H2v2zm7 14h3V10H9v12zm-7 0h5V10H2v12z"
//               data-fill="true"
//             ></path>
//           </symbol>
//           <symbol
//             viewBox="0 0 24 24"
//             id="way-shild"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M12 1L3 5v6c0 5.552 3.839 10.74 9 12 5.161-1.26 9-6.448 9-12V5l-9-4zm-1 6h2v6h-2V7zm0 8h2v2h-2"
//               data-fill="true"
//             />
//           </symbol>
//           <symbol
//             viewBox="0 0 24 24"
//             id="way-widgets"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M3 3h8v4.343l5.657-5.657 5.657 5.657L16.657 13H21v8h-8v-8h3.657L11 7.343V11H3V3zm0 10h8v8H3v-8z"
//               data-fill="true"
//             />
//           </symbol>
//           <symbol
//             viewBox="0 0 24 24"
//             id="multicollateral"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               xmlns="http://www.w3.org/2000/svg"
//               d="M21 5.69895H2.25V4.93641L18.75 3.59433V4.93641H21V2.64878C21 0.97118 19.6635 -0.206188 18.0315 0.0302009L2.97 2.21718C1.3365 2.45509 0 4.02135 0 5.69895V20.9498C0 21.7588 0.31607 22.5346 0.87868 23.1066C1.44129 23.6786 2.20435 24 3 24H21C21.7956 24 22.5587 23.6786 23.1213 23.1066C23.6839 22.5346 24 21.7588 24 20.9498V8.74913C24 7.94017 23.6839 7.16434 23.1213 6.59233C22.5587 6.02031 21.7956 5.69895 21 5.69895V5.69895ZM18.75 16.3837C18.4544 16.3836 18.1618 16.3243 17.8887 16.2092C17.6157 16.0941 17.3676 15.9255 17.1587 15.7129C16.9498 15.5004 16.7841 15.248 16.671 14.9703C16.558 14.6927 16.4999 14.3951 16.5 14.0946C16.5001 13.794 16.5584 13.4965 16.6716 13.2189C16.7848 12.9413 16.9507 12.6891 17.1598 12.4766C17.3688 12.2642 17.617 12.0957 17.8901 11.9808C18.1632 11.8659 18.4559 11.8068 18.7515 11.8069C19.3484 11.8071 19.9208 12.0484 20.3428 12.4777C20.7648 12.907 21.0017 13.4892 21.0015 14.0961C21.0013 14.703 20.764 15.285 20.3417 15.714C19.9195 16.143 19.3469 16.3839 18.75 16.3837Z"
//               data-fill="true"
//             />
//           </symbol>
//           <symbol
//             viewBox="0 0 23 25"
//             id="youtube"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <g clipPath="url(#ua)" fill="none">
//               <path
//                 d="M21.415 6.155c-.24-1.108-.947-1.981-1.845-2.278-1.626-.538-8.15-.538-8.15-.538s-6.522 0-8.149.538c-.897.297-1.604 1.17-1.844 2.278-.436 2.01-.436 6.202-.436 6.202s0 4.192.436 6.202c.24 1.108.947 1.945 1.844 2.241 1.627.539 8.15.539 8.15.539s6.523 0 8.15-.538c.897-.297 1.604-1.134 1.844-2.242.436-2.01.436-6.202.436-6.202s0-4.192-.436-6.202zM9.288 16.163V8.551l5.451 3.806-5.452 3.806z"
//                 data-fill="true"
//               />
//             </g>
//             <defs fill="none">
//               <clipPath id="ua" fill="none">
//                 <path
//                   transform="translate(.42 .339)"
//                   d="M0 0h22v24H0z"
//                   data-fill="true"
//                 />
//               </clipPath>
//             </defs>
//           </symbol>
//           <symbol id="plyr-play" viewBox="0 0 18 18">
//             <path d="M15.562 8.1L3.87.225c-.818-.562-1.87 0-1.87.9v15.75c0 .9 1.052 1.462 1.87.9L15.563 9.9c.584-.45.584-1.35 0-1.8z"></path>
//           </symbol>
//         </svg>
//       </div> */}
//     </footer>
//   );
// };

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

const navigation = {
  solutions: [
    { name: "About us", href: "/about" },
    { name: `Security`, href: "/security" },
    { name: "Copy Trading", href: "/copy" },
    { name: "Staking", href: "/staking" },
  ],
  support: [
    { name: "Contact us", href: "/contact" },
    { name: "Send us an email", href: `mailto:${siteSettings.supportEmail}` },
    // { name: "Help", href: "#" },
  ],
  company: [
    { name: "Crypto", href: `${siteSettings.appLink}` },
    { name: "Forex", href: `${siteSettings.appLink}` },
    { name: "Stocks", href: `${siteSettings.appLink}` },
    // { name: "Real Estate", href: "/realestate" },
  ],
  legal: [
    { name: "Copy Trading Terms", href: "/copy-terms" },
    { name: "Staking terms", href: "/staking-terms" },
    { name: "Terms of service", href: "/terms" },
    { name: "Cookie policy", href: "/cookie" },
    { name: "Privacy policy", href: "/privacy" },
  ],
  social: [
    {
      name: "Facebook",
      href: "#",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Instagram",
      href: "#",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Twitter",
      href: "#",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
    {
      name: "GitHub",
      href: "#",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "YouTube",
      href: "#",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ],
};

export default function Footer() {
  return (
    <footer className="bg-[#0c0f19] " aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-[1545px] px-6 lg:px-8 pt-[16px] pb-[120px]">
        {/* links */}
        <div>
          <div
            className=" w-[100%] justify-between mt-[72px] grid gap-[32px] md:flex"
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <div>
              <div>
                <h3 className="text-md font-medium leading-6 text-[#5D5F66]">
                  About
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.solutions.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-md leading-6 text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div>
              <div>
                <h3 className="text-md font-medium leading-6 text-[#5D5F66]">
                  Earn
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-md leading-6 text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div>
              <div>
                <h3 className="text-md font-medium leading-6 text-[#5D5F66]">
                  Legal
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-md leading-6 text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div>
              <div>
                <h3 className="text-md font-medium leading-6 text-[#5D5F66]">
                  Support
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {navigation.support.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-md leading-6 text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* logo */}

        <div className="mt-[120px]">
          <img className="h-10 " src="./logo-full.svg" alt="Company name" />
        </div>

        {/* copy text */}
        <div className="border-white/10 lg:flex lg:items-center lg:justify-between mt-[60px] ">
          <div>
            <p className="text-[15px] leading-6 text-[#AEAFB2]">
              The risk of loss in online trading of stocks, options, futures,
              currencies, foreign equities, and fixed Income can be substantial.
              <br />
              <br />
              Before trading, clients must read the relevant risk disclosure
              statements on our Warnings and Disclosures page. Trading on margin
              is only for experienced investors with high risk tolerance. You
              may lose more than your initial investment. For additional
              information about rates on margin loans, please see Margin Loan
              Rates. Security futures involve a high degree of risk and are not
              suitable for all investors. The amount you may lose may be greater
              than your initial investment.
              <br />
              <br />
              For trading security futures, read the Security Futures Risk
              Disclosure Statement. Structured products and fixed income
              products such as bonds are complex products that are more risky
              and are not suitable for all investors. Before trading, please
              read the Risk Warning and Disclosure Statement.
            </p>
          </div>
        </div>

        {/* copyright */}
        <div className="border-white/10 md:flex md:items-center md:justify-between mt-[64px]">
          {/* <div className="flex space-x-6 md:order-2">
            {navigation.social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-gray-500 hover:text-gray-400"
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div> */}
          <p className="text-md leading-5 text-[#AEAFB2] md:order-1 md:mt-0">
            &copy; 2016 - {new Date().getFullYear()} {siteSettings.siteName},
            Inc. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

// export default Footer;
