import styled from "styled-components";
import { siteSettings } from "../../static";

const mainTestimonialText = `${siteSettings.siteName} has created a quality platform for trading cryptocurrency derivatives. We were most impressed by the exchange’s design and overall user experience, which is truly first-class.`;

const first = {
  text: "Amazing experience. I have a very nice experience using this platform. Execution speed is ont of the best all over the market.",
  user: "Pro Kittisak",
  picture:
    "https://user-images.trustpilot.com/64c13a9a79f4110012303256/73x73.png",
  title: "User",
  rating: "9.5 / 10",
};

const testimonials = [
  {
    text: `${siteSettings.siteName} has everything you can ask for, I'm super happy it has been recommended to me as my first broker`,
    user: "Agata Vincent",
    picture:
      "https://user-images.trustpilot.com/64a712e728582c0011a9260e/73x73.png",
    title: "User",
    rating: "10 / 10",
  },
  {
    text: "legitimate! thank you for the opportunity, nothing out there can compare",
    user: "Dee",
    picture:
      "https://user-images.trustpilot.com/6321c934f2a8b90013faaf15/73x73.png",
    title: "Review Expert",
    rating: "10 / 10",
  },
  {
    text: "Great company, no issues with payouts, great customer support. Highly recommended for experienced traders.",
    user: "Jacob Szulc",
    picture:
      "https://user-images.trustpilot.com/5fd721e2c4a9b800196081bd/73x73.png",
    title: "Trader",
    rating: "9 / 10",
  },
];

const Testimonials = () => {
  return (
    //     <section
    //         className="SectionComment__CommentSection-sc-11k5g7g-0 jxmaTY"
    //         style={{
    //           boxSizing: "border-box",
    //           WebkitTapHighlightColor: "transparent",
    //           margin: "0px",
    //           border: "0px",
    //           font: "inherit",
    //           verticalAlign: "baseline",
    //           display: "block",
    //           borderTop: "1px solid rgb(229, 234, 249)",
    //           padding: "76px 9px 50px",
    //         }}
    //       >
    //         <div
    //           className="Container-sc-rx245t-0 dGblwP"
    //           style={{
    //             boxSizing: "border-box",
    //             WebkitTapHighlightColor: "transparent",
    //             margin: "0px",
    //             padding: "0px",
    //             border: "0px",
    //             font: "inherit",
    //             verticalAlign: "baseline",
    //             marginInline: "auto",
    //             paddingInline: "10px",
    //             width: "100%",
    //             contain: "none",
    //             maxWidth: "1190px",
    //             paddingRight: "40px",
    //             paddingInlineStart: "40px",
    //           }}
    //         >
    //           <div
    //             className="SectionComment__CommentHeader-sc-11k5g7g-1 zPEiH"
    //             style={{
    //               boxSizing: "border-box",
    //               WebkitTapHighlightColor: "transparent",
    //               margin: "0px",
    //               padding: "0px",
    //               border: "0px",
    //               font: "inherit",
    //               verticalAlign: "baseline",
    //               borderBottom: "1px solid rgb(229, 234, 249)",
    //               display: "flex",
    //               paddingBottom: "36px",
    //               marginBottom: "36px",
    //             }}
    //           >
    //             <div
    //               className="SectionComment__CommentHeaderText-sc-11k5g7g-2 dFxpml"
    //               style={{
    //                 boxSizing: "border-box",
    //                 WebkitTapHighlightColor: "transparent",
    //                 margin: "0px",
    //                 padding: "0px",
    //                 border: "0px",
    //                 font: "inherit",
    //                 verticalAlign: "baseline",
    //                 fontFamily: "Gilroy",
    //                 fontWeight: 800,
    //                 fontSize: "33px",
    //                 lineHeight: "40px",
    //                 flexBasis: "0px",
    //                 WebkitBoxFlex: "1",
    //                 flexGrow: 1,
    //                 maxWidth: "750px",
    //                 marginInlineEnd: "54px",
    //               }}
    //             >
    //               "Margex has created a quality platform for trading cryptocurrency
    //               derivatives. We were most impressed by the exchange’s design and
    //               overall user experience, which is truly first-class."
    //             </div>
    //             <div
    //               className="SectionComment__CommentHeaderAutor-sc-11k5g7g-3 spKYw"
    //               style={{
    //                 boxSizing: "border-box",
    //                 WebkitTapHighlightColor: "transparent",
    //                 margin: "0px",
    //                 padding: "0px",
    //                 border: "0px",
    //                 font: "inherit",
    //                 verticalAlign: "baseline",
    //                 display: "flex",
    //                 flex: "0 0 auto",
    //                 maxWidth: "260px",
    //                 marginTop: "3px",
    //               }}
    //             >
    //               <div
    //                 className="SectionComment__CommentHeaderPhoto-sc-11k5g7g-4 gCbZyA"
    //                 style={{
    //                   boxSizing: "border-box",
    //                   WebkitTapHighlightColor: "transparent",
    //                   margin: "0px",
    //                   padding: "0px",
    //                   border: "0px",
    //                   font: "inherit",
    //                   verticalAlign: "baseline",
    //                   flex: "0 0 auto",
    //                   width: "52px",
    //                   height: "52px",
    //                   marginInlineEnd: "20px",
    //                 }}
    //               >
    //                 <div
    //                   className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
    //                   style={{
    //                     boxSizing: "border-box",
    //                     WebkitTapHighlightColor: "transparent",
    //                     margin: "0px",
    //                     padding: "0px",
    //                     border: "0px",
    //                     font: "inherit",
    //                     overflow: "hidden",
    //                     position: "relative",
    //                     display: "inline-block",
    //                     verticalAlign: "top",
    //                   }}
    //                 >
    //                   <div
    //                     style={{
    //                       boxSizing: "border-box",
    //                       WebkitTapHighlightColor: "transparent",
    //                       margin: "0px",
    //                       padding: "0px",
    //                       border: "0px",
    //                       font: "inherit",
    //                       verticalAlign: "baseline",
    //                       maxWidth: "52px",
    //                       display: "block",
    //                     }}
    //                   >
    //                     <img
    //                       aria-hidden="true"
    //                       role="presentation"
    //                       src="data:image/svg+xml;charset=utf-8,<svg height='52' width='52' xmlns='http://www.w3.org/2000/svg' version='1.1'></svg>"
    //                       style={{
    //                         boxSizing: "border-box",
    //                         WebkitTapHighlightColor: "transparent",
    //                         border: "0px",
    //                         font: "inherit",
    //                         borderStyle: "none",
    //                         verticalAlign: "middle",
    //                         inset: "0px",
    //                         margin: "0px",
    //                         padding: "0px",
    //                         height: "100%",
    //                         width: "100%",
    //                         objectFit: "cover",
    //                         maxWidth: "100%",
    //                         display: "block",
    //                         position: "static",
    //                       }}
    //                     />
    //                   </div>
    //                   <div
    //                     aria-hidden="true"
    //                     style={{
    //                       boxSizing: "border-box",
    //                       WebkitTapHighlightColor: "transparent",
    //                       margin: "0px",
    //                       padding: "0px",
    //                       border: "0px",
    //                       font: "inherit",
    //                       verticalAlign: "baseline",
    //                       transition: "opacity 500ms linear 0s",
    //                       inset: "0px",
    //                       opacity: 0,
    //                       backgroundColor: "rgb(248, 248, 248)",
    //                       position: "absolute",
    //                       objectFit: "cover",
    //                     }}
    //                   />
    //                   <img
    //                     height={52}
    //                     width={52}
    //                     alt="CoinCodex"
    //                     sizes="(min-width: 52px) 52px, 100vw"
    //                     src="https://margex.com/static/db479117f45404fc2eb7fd844c95955a/69237/comment-photo-main.png"
    //                     srcSet={`/static/db479117f45404fc2eb7fd844c95955a/86ee2/comment-photo-main.png 13w,
    // /static/db479117f45404fc2eb7fd844c95955a/996d8/comment-photo-main.png 26w,
    // /static/db479117f45404fc2eb7fd844c95955a/69237/comment-photo-main.png 52w,
    // /static/db479117f45404fc2eb7fd844c95955a/658ae/comment-photo-main.png 104w`}
    //                     style={{
    //                       boxSizing: "border-box",
    //                       WebkitTapHighlightColor: "transparent",
    //                       border: "0px",
    //                       font: "inherit",
    //                       borderStyle: "none",
    //                       verticalAlign: "middle",
    //                       inset: "0px",
    //                       margin: "0px",
    //                       padding: "0px",
    //                       height: "100%",
    //                       maxWidth: "none",
    //                       position: "absolute",
    //                       width: "100%",
    //                       transition: "opacity 0.25s linear 0s",
    //                       transform: "translateZ(0px)",
    //                       willChange: "opacity",
    //                       objectFit: "cover",
    //                       opacity: 1,
    //                     }}
    //                   />
    //                   <noscript
    //                     style={{
    //                       boxSizing: "border-box",
    //                       WebkitTapHighlightColor: "transparent",
    //                     }}
    //                   >{`<img width="52" height="52" data-main-image="" style="object-fit:cover;opacity:0" sizes="(min-width: 52px) 52px, 100vw" decoding="async" loading="lazy" src="/static/db479117f45404fc2eb7fd844c95955a/69237/comment-photo-main.png" srcSet="/static/db479117f45404fc2eb7fd844c95955a/86ee2/comment-photo-main.png 13w,
    // /static/db479117f45404fc2eb7fd844c95955a/996d8/comment-photo-main.png 26w,
    // /static/db479117f45404fc2eb7fd844c95955a/69237/comment-photo-main.png 52w,
    // /static/db479117f45404fc2eb7fd844c95955a/658ae/comment-photo-main.png 104w" alt="CoinCodex"/>`}</noscript>
    //                 </div>
    //               </div>
    //             </div>
    //             <div
    //               className="SectionComment__CommentHeaderEvaluation-sc-11k5g7g-5 dEIZUJ"
    //               style={{
    //                 boxSizing: "border-box",
    //                 WebkitTapHighlightColor: "transparent",
    //                 margin: "0px",
    //                 padding: "0px",
    //                 border: "0px",
    //                 font: "inherit",
    //                 verticalAlign: "baseline",
    //                 fontWeight: 600,
    //                 fontSize: "16px",
    //                 lineHeight: "22px",
    //               }}
    //             >
    //               9.5/10
    //               <div
    //                 style={{
    //                   boxSizing: "border-box",
    //                   WebkitTapHighlightColor: "transparent",
    //                   margin: "0px",
    //                   padding: "0px",
    //                   border: "0px",
    //                   font: "inherit",
    //                   verticalAlign: "baseline",
    //                 }}
    //               >
    //                 <div
    //                   className="SectionComment__CommentHeaderName-sc-11k5g7g-6 bojcQz"
    //                   style={{
    //                     boxSizing: "border-box",
    //                     WebkitTapHighlightColor: "transparent",
    //                     margin: "0px",
    //                     padding: "0px",
    //                     border: "0px",
    //                     font: "inherit",
    //                     verticalAlign: "baseline",
    //                     fontSize: "18px",
    //                     lineHeight: "25px",
    //                     marginTop: "0px",
    //                     marginBottom: "0px",
    //                   }}
    //                 >
    //                   Emma Avon
    //                 </div>
    //                 <div
    //                   className="SectionComment__CommentHeaderSpeciality-sc-11k5g7g-7 bPmdKR"
    //                   style={{
    //                     boxSizing: "border-box",
    //                     WebkitTapHighlightColor: "transparent",
    //                     margin: "0px",
    //                     padding: "0px",
    //                     border: "0px",
    //                     font: "inherit",
    //                     verticalAlign: "baseline",
    //                     color: "rgb(122, 132, 157)",
    //                   }}
    //                 >
    //                   <a
    //                     href="https://coincodex.com/review/10510/margex-review-crypto-futures-exchange-with-up-to-100x-leverage/"
    //                     rel="noreferrer"
    //                     target="_blank"
    //                     style={{
    //                       boxSizing: "border-box",
    //                       WebkitTapHighlightColor: "transparent",
    //                       margin: "0px",
    //                       padding: "0px",
    //                       border: "0px",
    //                       font: "inherit",
    //                       verticalAlign: "baseline",
    //                       textDecoration: "none",
    //                       backgroundColor: "transparent",
    //                     }}
    //                   >
    //                     coincodex.com
    //                   </a>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //           <div
    //             className="SectionComment__CommentItems-sc-11k5g7g-8 cenjGZ"
    //             style={{
    //               boxSizing: "border-box",
    //               WebkitTapHighlightColor: "transparent",
    //               margin: "0px",
    //               padding: "0px",
    //               border: "0px",
    //               font: "inherit",
    //               verticalAlign: "baseline",
    //               display: "flex",
    //               flexWrap: "wrap",
    //             }}
    //           >
    //             <div
    //               className="SectionComment__CommentItem-sc-11k5g7g-9 jjkcro"
    //               style={{
    //                 boxSizing: "border-box",
    //                 WebkitTapHighlightColor: "transparent",
    //                 margin: "0px",
    //                 padding: "0px",
    //                 border: "0px",
    //                 font: "inherit",
    //                 verticalAlign: "baseline",
    //                 flex: "0 0 33.3333%",
    //                 maxWidth: "33.3333%",
    //                 display: "flex",
    //                 flexDirection: "column",
    //                 WebkitBoxPack: "justify",
    //                 justifyContent: "space-between",
    //                 paddingRight: "48px",
    //                 height: "171px",
    //               }}
    //             >
    //               <div
    //                 className="SectionComment__CommentItemText-sc-11k5g7g-10 jNhLaQ"
    //                 style={{
    //                   boxSizing: "border-box",
    //                   WebkitTapHighlightColor: "transparent",
    //                   margin: "0px",
    //                   padding: "0px",
    //                   border: "0px",
    //                   font: "inherit",
    //                   verticalAlign: "baseline",
    //                   fontSize: "16px",
    //                   lineHeight: "22px",
    //                   marginBottom: "8px",
    //                 }}
    //               >
    //                 Margex is gradually taking over the crypto derivatives market
    //                 through its unique and user-friendly platform.
    //               </div>
    //               <div
    //                 style={{
    //                   boxSizing: "border-box",
    //                   WebkitTapHighlightColor: "transparent",
    //                   margin: "0px",
    //                   padding: "0px",
    //                   border: "0px",
    //                   font: "inherit",
    //                   verticalAlign: "baseline",
    //                 }}
    //               >
    //                 <div
    //                   className="SectionComment__CommentAuthorEvaluation-sc-11k5g7g-11 bAeAee"
    //                   style={{
    //                     boxSizing: "border-box",
    //                     WebkitTapHighlightColor: "transparent",
    //                     margin: "0px",
    //                     padding: "0px",
    //                     border: "0px",
    //                     font: "inherit",
    //                     verticalAlign: "baseline",
    //                     fontWeight: 600,
    //                     fontSize: "16px",
    //                     lineHeight: "22px",
    //                   }}
    //                 />
    //                 <div
    //                   className="SectionComment__CommentAuthor-sc-11k5g7g-12 fKlcDU"
    //                   style={{
    //                     boxSizing: "border-box",
    //                     WebkitTapHighlightColor: "transparent",
    //                     margin: "0px",
    //                     padding: "0px",
    //                     border: "0px",
    //                     font: "inherit",
    //                     verticalAlign: "baseline",
    //                     display: "flex",
    //                     marginTop: "16px",
    //                   }}
    //                 >
    //                   <div
    //                     className="SectionComment__CommentPhoto-sc-11k5g7g-13 igwXYl"
    //                     style={{
    //                       boxSizing: "border-box",
    //                       WebkitTapHighlightColor: "transparent",
    //                       margin: "0px",
    //                       padding: "0px",
    //                       border: "0px",
    //                       font: "inherit",
    //                       verticalAlign: "baseline",
    //                       width: "36px",
    //                       height: "36px",
    //                       marginInlineEnd: "12px",
    //                     }}
    //                   >
    //                     <div
    //                       className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
    //                       style={{
    //                         boxSizing: "border-box",
    //                         WebkitTapHighlightColor: "transparent",
    //                         margin: "0px",
    //                         padding: "0px",
    //                         border: "0px",
    //                         font: "inherit",
    //                         overflow: "hidden",
    //                         position: "relative",
    //                         display: "inline-block",
    //                         verticalAlign: "top",
    //                       }}
    //                     >
    //                       <div
    //                         style={{
    //                           boxSizing: "border-box",
    //                           WebkitTapHighlightColor: "transparent",
    //                           margin: "0px",
    //                           padding: "0px",
    //                           border: "0px",
    //                           font: "inherit",
    //                           verticalAlign: "baseline",
    //                           maxWidth: "104px",
    //                           display: "block",
    //                         }}
    //                       >
    //                         <img
    //                           aria-hidden="true"
    //                           role="presentation"
    //                           src="data:image/svg+xml;charset=utf-8,<svg height='104' width='104' xmlns='http://www.w3.org/2000/svg' version='1.1'></svg>"
    //                           style={{
    //                             boxSizing: "border-box",
    //                             WebkitTapHighlightColor: "transparent",
    //                             border: "0px",
    //                             font: "inherit",
    //                             borderStyle: "none",
    //                             verticalAlign: "middle",
    //                             inset: "0px",
    //                             margin: "0px",
    //                             padding: "0px",
    //                             width: "100%",
    //                             objectFit: "cover",
    //                             height: "auto",
    //                             maxWidth: "100%",
    //                             display: "block",
    //                             position: "static",
    //                           }}
    //                         />
    //                       </div>
    //                       <div
    //                         aria-hidden="true"
    //                         style={{
    //                           boxSizing: "border-box",
    //                           WebkitTapHighlightColor: "transparent",
    //                           margin: "0px",
    //                           padding: "0px",
    //                           border: "0px",
    //                           font: "inherit",
    //                           verticalAlign: "baseline",
    //                           transition: "opacity 500ms linear 0s",
    //                           inset: "0px",
    //                           opacity: 0,
    //                           backgroundColor: "rgb(248, 248, 248)",
    //                           position: "absolute",
    //                           objectFit: "cover",
    //                         }}
    //                       />
    //                       <img
    //                         height={36}
    //                         width={36}
    //                         sizes="(min-width: 104px) 104px, 100vw"
    //                         src="https://margex.com/static/17a7a977a1e51cb1b419b64c7ded9291/658ae/comment-photo-1.png"
    //                         srcSet={`/static/17a7a977a1e51cb1b419b64c7ded9291/996d8/comment-photo-1.png 26w,
    // /static/17a7a977a1e51cb1b419b64c7ded9291/69237/comment-photo-1.png 52w,
    // /static/17a7a977a1e51cb1b419b64c7ded9291/658ae/comment-photo-1.png 104w`}
    //                         style={{
    //                           boxSizing: "border-box",
    //                           WebkitTapHighlightColor: "transparent",
    //                           border: "0px",
    //                           font: "inherit",
    //                           borderStyle: "none",
    //                           verticalAlign: "middle",
    //                           inset: "0px",
    //                           margin: "0px",
    //                           padding: "0px",
    //                           position: "absolute",
    //                           width: "100%",
    //                           maxWidth: "100%",
    //                           height: "auto",
    //                           transition: "opacity 0.25s linear 0s",
    //                           transform: "translateZ(0px)",
    //                           willChange: "opacity",
    //                           objectFit: "cover",
    //                           opacity: 1,
    //                         }}
    //                       />
    //                       <noscript
    //                         style={{
    //                           boxSizing: "border-box",
    //                           WebkitTapHighlightColor: "transparent",
    //                         }}
    //                       >{`<img width="104" height="104" data-main-image="" style="object-fit:cover;opacity:0" sizes="(min-width: 104px) 104px, 100vw" decoding="async" loading="lazy" src="/static/17a7a977a1e51cb1b419b64c7ded9291/658ae/comment-photo-1.png" srcSet="/static/17a7a977a1e51cb1b419b64c7ded9291/996d8/comment-photo-1.png 26w,
    // /static/17a7a977a1e51cb1b419b64c7ded9291/69237/comment-photo-1.png 52w,
    // /static/17a7a977a1e51cb1b419b64c7ded9291/658ae/comment-photo-1.png 104w" alt=""/>`}</noscript>
    //                     </div>
    //                   </div>
    //                   <div
    //                     style={{
    //                       boxSizing: "border-box",
    //                       WebkitTapHighlightColor: "transparent",
    //                       margin: "0px",
    //                       padding: "0px",
    //                       border: "0px",
    //                       font: "inherit",
    //                       verticalAlign: "baseline",
    //                     }}
    //                   >
    //                     <div
    //                       className="SectionComment__CommentName-sc-11k5g7g-14 hFhsUe"
    //                       style={{
    //                         boxSizing: "border-box",
    //                         WebkitTapHighlightColor: "transparent",
    //                         margin: "0px",
    //                         padding: "0px",
    //                         border: "0px",
    //                         font: "inherit",
    //                         verticalAlign: "baseline",
    //                       }}
    //                     >
    //                       Casper Brown
    //                     </div>
    //                     <div
    //                       className="SectionComment__CommentSpeciality-sc-11k5g7g-15 ksOrv"
    //                       style={{
    //                         boxSizing: "border-box",
    //                         WebkitTapHighlightColor: "transparent",
    //                         margin: "0px",
    //                         padding: "0px",
    //                         border: "0px",
    //                         font: "inherit",
    //                         verticalAlign: "baseline",
    //                         color: "rgb(122, 132, 157)",
    //                         fontSize: "12px",
    //                         lineHeight: "16px",
    //                       }}
    //                     >
    //                       <a
    //                         href="https://coingape.com/margex-the-future-of-cryptocurrency-derivatives-trading-with-up-to-100x-leverage/"
    //                         rel="noreferrer"
    //                         target="_blank"
    //                         style={{
    //                           boxSizing: "border-box",
    //                           WebkitTapHighlightColor: "transparent",
    //                           margin: "0px",
    //                           padding: "0px",
    //                           border: "0px",
    //                           font: "inherit",
    //                           verticalAlign: "baseline",
    //                           textDecoration: "none",
    //                           color: "rgb(48, 113, 238)",
    //                           backgroundColor: "transparent",
    //                         }}
    //                       >
    //                         coingape.com
    //                       </a>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //             <div
    //               className="SectionComment__CommentItem-sc-11k5g7g-9 jjkcro"
    //               style={{
    //                 boxSizing: "border-box",
    //                 WebkitTapHighlightColor: "transparent",
    //                 margin: "0px",
    //                 padding: "0px",
    //                 border: "0px",
    //                 font: "inherit",
    //                 verticalAlign: "baseline",
    //                 flex: "0 0 33.3333%",
    //                 maxWidth: "33.3333%",
    //                 display: "flex",
    //                 flexDirection: "column",
    //                 WebkitBoxPack: "justify",
    //                 justifyContent: "space-between",
    //                 paddingRight: "48px",
    //                 height: "171px",
    //               }}
    //             >
    //               <div
    //                 className="SectionComment__CommentItemText-sc-11k5g7g-10 jNhLaQ"
    //                 style={{
    //                   boxSizing: "border-box",
    //                   WebkitTapHighlightColor: "transparent",
    //                   margin: "0px",
    //                   padding: "0px",
    //                   border: "0px",
    //                   font: "inherit",
    //                   verticalAlign: "baseline",
    //                   fontSize: "16px",
    //                   lineHeight: "22px",
    //                   marginBottom: "8px",
    //                 }}
    //               >
    //                 Margex is a true refreshment in the crypto derivatives space.
    //                 The platform offers low fees, high leverage and security
    //                 standards.
    //               </div>
    //               <div
    //                 style={{
    //                   boxSizing: "border-box",
    //                   WebkitTapHighlightColor: "transparent",
    //                   margin: "0px",
    //                   padding: "0px",
    //                   border: "0px",
    //                   font: "inherit",
    //                   verticalAlign: "baseline",
    //                 }}
    //               >
    //                 <div
    //                   className="SectionComment__CommentAuthorEvaluation-sc-11k5g7g-11 bAeAee"
    //                   style={{
    //                     boxSizing: "border-box",
    //                     WebkitTapHighlightColor: "transparent",
    //                     margin: "0px",
    //                     padding: "0px",
    //                     border: "0px",
    //                     font: "inherit",
    //                     verticalAlign: "baseline",
    //                     fontWeight: 600,
    //                     fontSize: "16px",
    //                     lineHeight: "22px",
    //                   }}
    //                 >
    //                   8.5/10
    //                 </div>
    //                 <div
    //                   className="SectionComment__CommentAuthor-sc-11k5g7g-12 fKlcDU"
    //                   style={{
    //                     boxSizing: "border-box",
    //                     WebkitTapHighlightColor: "transparent",
    //                     margin: "0px",
    //                     padding: "0px",
    //                     border: "0px",
    //                     font: "inherit",
    //                     verticalAlign: "baseline",
    //                     display: "flex",
    //                     marginTop: "16px",
    //                   }}
    //                 >
    //                   <div
    //                     className="SectionComment__CommentPhoto-sc-11k5g7g-13 igwXYl"
    //                     style={{
    //                       boxSizing: "border-box",
    //                       WebkitTapHighlightColor: "transparent",
    //                       margin: "0px",
    //                       padding: "0px",
    //                       border: "0px",
    //                       font: "inherit",
    //                       verticalAlign: "baseline",
    //                       width: "36px",
    //                       height: "36px",
    //                       marginInlineEnd: "12px",
    //                     }}
    //                   >
    //                     <div
    //                       className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
    //                       style={{
    //                         boxSizing: "border-box",
    //                         WebkitTapHighlightColor: "transparent",
    //                         margin: "0px",
    //                         padding: "0px",
    //                         border: "0px",
    //                         font: "inherit",
    //                         overflow: "hidden",
    //                         position: "relative",
    //                         display: "inline-block",
    //                         verticalAlign: "top",
    //                       }}
    //                     >
    //                       <div
    //                         style={{
    //                           boxSizing: "border-box",
    //                           WebkitTapHighlightColor: "transparent",
    //                           margin: "0px",
    //                           padding: "0px",
    //                           border: "0px",
    //                           font: "inherit",
    //                           verticalAlign: "baseline",
    //                           maxWidth: "104px",
    //                           display: "block",
    //                         }}
    //                       >
    //                         <img
    //                           aria-hidden="true"
    //                           role="presentation"
    //                           src="data:image/svg+xml;charset=utf-8,<svg height='104' width='104' xmlns='http://www.w3.org/2000/svg' version='1.1'></svg>"
    //                           style={{
    //                             boxSizing: "border-box",
    //                             WebkitTapHighlightColor: "transparent",
    //                             border: "0px",
    //                             font: "inherit",
    //                             borderStyle: "none",
    //                             verticalAlign: "middle",
    //                             inset: "0px",
    //                             margin: "0px",
    //                             padding: "0px",
    //                             width: "100%",
    //                             objectFit: "cover",
    //                             height: "auto",
    //                             maxWidth: "100%",
    //                             display: "block",
    //                             position: "static",
    //                           }}
    //                         />
    //                       </div>
    //                       <div
    //                         aria-hidden="true"
    //                         style={{
    //                           boxSizing: "border-box",
    //                           WebkitTapHighlightColor: "transparent",
    //                           margin: "0px",
    //                           padding: "0px",
    //                           border: "0px",
    //                           font: "inherit",
    //                           verticalAlign: "baseline",
    //                           transition: "opacity 500ms linear 0s",
    //                           inset: "0px",
    //                           opacity: 0,
    //                           backgroundColor: "rgb(248, 248, 248)",
    //                           position: "absolute",
    //                           objectFit: "cover",
    //                         }}
    //                       />
    //                       <img
    //                         height={36}
    //                         width={36}
    //                         sizes="(min-width: 104px) 104px, 100vw"
    //                         src="https://margex.com/static/eff610b875058caa7c211b4dd0f90344/658ae/comment-photo-2.png"
    //                         srcSet={`/static/eff610b875058caa7c211b4dd0f90344/996d8/comment-photo-2.png 26w,
    // /static/eff610b875058caa7c211b4dd0f90344/69237/comment-photo-2.png 52w,
    // /static/eff610b875058caa7c211b4dd0f90344/658ae/comment-photo-2.png 104w`}
    //                         style={{
    //                           boxSizing: "border-box",
    //                           WebkitTapHighlightColor: "transparent",
    //                           border: "0px",
    //                           font: "inherit",
    //                           borderStyle: "none",
    //                           verticalAlign: "middle",
    //                           inset: "0px",
    //                           margin: "0px",
    //                           padding: "0px",
    //                           position: "absolute",
    //                           width: "100%",
    //                           maxWidth: "100%",
    //                           height: "auto",
    //                           transition: "opacity 0.25s linear 0s",
    //                           transform: "translateZ(0px)",
    //                           willChange: "opacity",
    //                           objectFit: "cover",
    //                           opacity: 1,
    //                         }}
    //                       />
    //                       <noscript
    //                         style={{
    //                           boxSizing: "border-box",
    //                           WebkitTapHighlightColor: "transparent",
    //                         }}
    //                       >{`<img width="104" height="104" data-main-image="" style="object-fit:cover;opacity:0" sizes="(min-width: 104px) 104px, 100vw" decoding="async" loading="lazy" src="/static/eff610b875058caa7c211b4dd0f90344/658ae/comment-photo-2.png" srcSet="/static/eff610b875058caa7c211b4dd0f90344/996d8/comment-photo-2.png 26w,
    // /static/eff610b875058caa7c211b4dd0f90344/69237/comment-photo-2.png 52w,
    // /static/eff610b875058caa7c211b4dd0f90344/658ae/comment-photo-2.png 104w" alt=""/>`}</noscript>
    //                     </div>
    //                   </div>
    //                   <div
    //                     style={{
    //                       boxSizing: "border-box",
    //                       WebkitTapHighlightColor: "transparent",
    //                       margin: "0px",
    //                       padding: "0px",
    //                       border: "0px",
    //                       font: "inherit",
    //                       verticalAlign: "baseline",
    //                     }}
    //                   >
    //                     <div
    //                       className="SectionComment__CommentName-sc-11k5g7g-14 hFhsUe"
    //                       style={{
    //                         boxSizing: "border-box",
    //                         WebkitTapHighlightColor: "transparent",
    //                         margin: "0px",
    //                         padding: "0px",
    //                         border: "0px",
    //                         font: "inherit",
    //                         verticalAlign: "baseline",
    //                       }}
    //                     >
    //                       Rene Peters
    //                     </div>
    //                     <div
    //                       className="SectionComment__CommentSpeciality-sc-11k5g7g-15 ksOrv"
    //                       style={{
    //                         boxSizing: "border-box",
    //                         WebkitTapHighlightColor: "transparent",
    //                         margin: "0px",
    //                         padding: "0px",
    //                         border: "0px",
    //                         font: "inherit",
    //                         verticalAlign: "baseline",
    //                         color: "rgb(122, 132, 157)",
    //                         fontSize: "12px",
    //                         lineHeight: "16px",
    //                       }}
    //                     >
    //                       <a
    //                         href="https://www.hedgewithcrypto.com/margex-review/"
    //                         rel="noreferrer"
    //                         target="_blank"
    //                         style={{
    //                           boxSizing: "border-box",
    //                           WebkitTapHighlightColor: "transparent",
    //                           margin: "0px",
    //                           padding: "0px",
    //                           border: "0px",
    //                           font: "inherit",
    //                           verticalAlign: "baseline",
    //                           textDecoration: "none",
    //                           color: "rgb(48, 113, 238)",
    //                           backgroundColor: "transparent",
    //                         }}
    //                       >
    //                         captainaltcoin.com
    //                       </a>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //             <div
    //               className="SectionComment__CommentItem-sc-11k5g7g-9 jjkcro"
    //               style={{
    //                 boxSizing: "border-box",
    //                 WebkitTapHighlightColor: "transparent",
    //                 margin: "0px",
    //                 padding: "0px",
    //                 border: "0px",
    //                 font: "inherit",
    //                 verticalAlign: "baseline",
    //                 flex: "0 0 33.3333%",
    //                 maxWidth: "33.3333%",
    //                 display: "flex",
    //                 flexDirection: "column",
    //                 WebkitBoxPack: "justify",
    //                 justifyContent: "space-between",
    //                 paddingRight: "48px",
    //                 height: "171px",
    //               }}
    //             >
    //               <div
    //                 className="SectionComment__CommentItemText-sc-11k5g7g-10 jNhLaQ"
    //                 style={{
    //                   boxSizing: "border-box",
    //                   WebkitTapHighlightColor: "transparent",
    //                   margin: "0px",
    //                   padding: "0px",
    //                   border: "0px",
    //                   font: "inherit",
    //                   verticalAlign: "baseline",
    //                   fontSize: "16px",
    //                   lineHeight: "22px",
    //                   marginBottom: "8px",
    //                 }}
    //               >
    //                 Margex is a solid exchange for beginners that want to learn how
    //                 to trade with leverage. There are no complicated features or
    //                 distractions.
    //               </div>
    //               <div
    //                 style={{
    //                   boxSizing: "border-box",
    //                   WebkitTapHighlightColor: "transparent",
    //                   margin: "0px",
    //                   padding: "0px",
    //                   border: "0px",
    //                   font: "inherit",
    //                   verticalAlign: "baseline",
    //                 }}
    //               >
    //                 <div
    //                   className="SectionComment__CommentAuthorEvaluation-sc-11k5g7g-11 bAeAee"
    //                   style={{
    //                     boxSizing: "border-box",
    //                     WebkitTapHighlightColor: "transparent",
    //                     margin: "0px",
    //                     padding: "0px",
    //                     border: "0px",
    //                     font: "inherit",
    //                     verticalAlign: "baseline",
    //                     fontWeight: 600,
    //                     fontSize: "16px",
    //                     lineHeight: "22px",
    //                   }}
    //                 >
    //                   4/5
    //                 </div>
    //                 <div
    //                   className="SectionComment__CommentAuthor-sc-11k5g7g-12 fKlcDU"
    //                   style={{
    //                     boxSizing: "border-box",
    //                     WebkitTapHighlightColor: "transparent",
    //                     margin: "0px",
    //                     padding: "0px",
    //                     border: "0px",
    //                     font: "inherit",
    //                     verticalAlign: "baseline",
    //                     display: "flex",
    //                     marginTop: "16px",
    //                   }}
    //                 >
    //                   <div
    //                     className="SectionComment__CommentPhoto-sc-11k5g7g-13 igwXYl"
    //                     style={{
    //                       boxSizing: "border-box",
    //                       WebkitTapHighlightColor: "transparent",
    //                       margin: "0px",
    //                       padding: "0px",
    //                       border: "0px",
    //                       font: "inherit",
    //                       verticalAlign: "baseline",
    //                       width: "36px",
    //                       height: "36px",
    //                       marginInlineEnd: "12px",
    //                     }}
    //                   >
    //                     <div
    //                       className="gatsby-image-wrapper gatsby-image-wrapper-constrained"
    //                       style={{
    //                         boxSizing: "border-box",
    //                         WebkitTapHighlightColor: "transparent",
    //                         margin: "0px",
    //                         padding: "0px",
    //                         border: "0px",
    //                         font: "inherit",
    //                         overflow: "hidden",
    //                         position: "relative",
    //                         display: "inline-block",
    //                         verticalAlign: "top",
    //                       }}
    //                     >
    //                       <div
    //                         style={{
    //                           boxSizing: "border-box",
    //                           WebkitTapHighlightColor: "transparent",
    //                           margin: "0px",
    //                           padding: "0px",
    //                           border: "0px",
    //                           font: "inherit",
    //                           verticalAlign: "baseline",
    //                           maxWidth: "104px",
    //                           display: "block",
    //                         }}
    //                       >
    //                         <img
    //                           aria-hidden="true"
    //                           role="presentation"
    //                           src="data:image/svg+xml;charset=utf-8,<svg height='104' width='104' xmlns='http://www.w3.org/2000/svg' version='1.1'></svg>"
    //                           style={{
    //                             boxSizing: "border-box",
    //                             WebkitTapHighlightColor: "transparent",
    //                             border: "0px",
    //                             font: "inherit",
    //                             borderStyle: "none",
    //                             verticalAlign: "middle",
    //                             inset: "0px",
    //                             margin: "0px",
    //                             padding: "0px",
    //                             width: "100%",
    //                             objectFit: "cover",
    //                             height: "auto",
    //                             maxWidth: "100%",
    //                             display: "block",
    //                             position: "static",
    //                           }}
    //                         />
    //                       </div>
    //                       <div
    //                         aria-hidden="true"
    //                         style={{
    //                           boxSizing: "border-box",
    //                           WebkitTapHighlightColor: "transparent",
    //                           margin: "0px",
    //                           padding: "0px",
    //                           border: "0px",
    //                           font: "inherit",
    //                           verticalAlign: "baseline",
    //                           transition: "opacity 500ms linear 0s",
    //                           inset: "0px",
    //                           opacity: 0,
    //                           backgroundColor: "rgb(248, 248, 248)",
    //                           position: "absolute",
    //                           objectFit: "cover",
    //                         }}
    //                       />
    //                       <img
    //                         height={36}
    //                         width={36}
    //                         sizes="(min-width: 104px) 104px, 100vw"
    //                         src="https://margex.com/static/c94c573b58e46cec207b8a4c09b9adaa/658ae/comment-photo-3.png"
    //                         srcSet={`/static/c94c573b58e46cec207b8a4c09b9adaa/996d8/comment-photo-3.png 26w,
    // /static/c94c573b58e46cec207b8a4c09b9adaa/69237/comment-photo-3.png 52w,
    // /static/c94c573b58e46cec207b8a4c09b9adaa/658ae/comment-photo-3.png 104w`}
    //                         style={{
    //                           boxSizing: "border-box",
    //                           WebkitTapHighlightColor: "transparent",
    //                           border: "0px",
    //                           font: "inherit",
    //                           borderStyle: "none",
    //                           verticalAlign: "middle",
    //                           inset: "0px",
    //                           margin: "0px",
    //                           padding: "0px",
    //                           position: "absolute",
    //                           width: "100%",
    //                           maxWidth: "100%",
    //                           height: "auto",
    //                           transition: "opacity 0.25s linear 0s",
    //                           transform: "translateZ(0px)",
    //                           willChange: "opacity",
    //                           objectFit: "cover",
    //                           opacity: 1,
    //                         }}
    //                       />
    //                       <noscript
    //                         style={{
    //                           boxSizing: "border-box",
    //                           WebkitTapHighlightColor: "transparent",
    //                         }}
    //                       >{`<img width="104" height="104" data-main-image="" style="object-fit:cover;opacity:0" sizes="(min-width: 104px) 104px, 100vw" decoding="async" loading="lazy" src="/static/c94c573b58e46cec207b8a4c09b9adaa/658ae/comment-photo-3.png" srcSet="/static/c94c573b58e46cec207b8a4c09b9adaa/996d8/comment-photo-3.png 26w,
    // /static/c94c573b58e46cec207b8a4c09b9adaa/69237/comment-photo-3.png 52w,
    // /static/c94c573b58e46cec207b8a4c09b9adaa/658ae/comment-photo-3.png 104w" alt=""/>`}</noscript>
    //                     </div>
    //                   </div>
    //                   <div
    //                     style={{
    //                       boxSizing: "border-box",
    //                       WebkitTapHighlightColor: "transparent",
    //                       margin: "0px",
    //                       padding: "0px",
    //                       border: "0px",
    //                       font: "inherit",
    //                       verticalAlign: "baseline",
    //                     }}
    //                   >
    //                     <div
    //                       className="SectionComment__CommentName-sc-11k5g7g-14 hFhsUe"
    //                       style={{
    //                         boxSizing: "border-box",
    //                         WebkitTapHighlightColor: "transparent",
    //                         margin: "0px",
    //                         padding: "0px",
    //                         border: "0px",
    //                         font: "inherit",
    //                         verticalAlign: "baseline",
    //                       }}
    //                     >
    //                       Kevin
    //                     </div>
    //                     <div
    //                       className="SectionComment__CommentSpeciality-sc-11k5g7g-15 ksOrv"
    //                       style={{
    //                         boxSizing: "border-box",
    //                         WebkitTapHighlightColor: "transparent",
    //                         margin: "0px",
    //                         padding: "0px",
    //                         border: "0px",
    //                         font: "inherit",
    //                         verticalAlign: "baseline",
    //                         color: "rgb(122, 132, 157)",
    //                         fontSize: "12px",
    //                         lineHeight: "16px",
    //                       }}
    //                     >
    //                       <a
    //                         href="https://captainaltcoin.com/margex-review/"
    //                         rel="noreferrer"
    //                         target="_blank"
    //                         style={{
    //                           boxSizing: "border-box",
    //                           WebkitTapHighlightColor: "transparent",
    //                           margin: "0px",
    //                           padding: "0px",
    //                           border: "0px",
    //                           font: "inherit",
    //                           verticalAlign: "baseline",
    //                           textDecoration: "none",
    //                           color: "rgb(48, 113, 238)",
    //                           backgroundColor: "transparent",
    //                         }}
    //                       >
    //                         hedgewithcrypto.com
    //                       </a>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </section>

    <TestimonialsWrap>
      <div className="container">
        <div className="testimonials__top">
          <p className="main_text">{mainTestimonialText}</p>
          <div className="main_testimonial_user">
            <div className="img__wrap">
              <img
                src={first.picture}
                style={{ borderRadius: "100%" }}
                alt=""
              />
            </div>
            <span className="desc__first">
              <p>{first.user}</p>
              <p>{first.rating}</p>
              <p>{first.title}</p>
            </span>
          </div>
        </div>
        <div className="testimonials__bottom">
          {testimonials.map((testimonial) => (
            <div className="testimonial">
              <span className="testimonial__span">
                <p>{testimonial.text}</p>
                <p>{testimonial.rating}</p>
              </span>
              <div className="testimonial__user">
                <div className="img__wrap">
                  <img
                    src={testimonial.picture}
                    alt=""
                    style={{ borderRadius: "100%" }}
                  />
                </div>
                <div className="desc">
                  <p>{testimonial.user}</p>
                  <p>{testimonial.title}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </TestimonialsWrap>
  );
};

const TestimonialsWrap = styled.div`
  .container {
    max-width: 1110px;
    margin: 0 auto;
    /* padding: 76px 9px 50px; */
  }

  .desc {
  }

  .testimonial .testimonial__span p:nth-child(1) {
    font-family: 600;
  }

  .testimonial .testimonial__span p:nth-child(2) {
    font-family: 800;
  }

  .desc p:nth-child(1) {
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 500;
  }

  .desc p:nth-child(2) {
    color: #3071ee;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
  }

  .testimonials__top {
    display: flex;
    justify-content: space-between;
    align-items: first baseline;
    border-top: 1px solid rgb(229, 234, 249);
    padding: 76px 9px 50px;
  }

  .testimonials__top {
    @media screen and (max-width: 868px) {
      display: grid;
    }
  }

  .testimonials__top .main_text {
    font-size: 33px;
    line-height: 40px;
    flex-basis: 0px;
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-width: 750px;
    margin-inline-end: 54px;
    font-weight: 800;
  }

  .main_testimonial_user {
    display: flex;
    gap: 12px;
  }

  .desc__first p {
    margin: 0;
    padding: 0;
    gap: 0;
  }

  .desc p {
    margin: 0;
    padding: 0;
    gap: 0;
  }

  .main_testimonial_user .desc {
  }

  .testimonials__bottom {
    border-top: 1px solid rgb(229, 234, 249);
    padding: 76px 9px 50px;
    display: flex;
    justify-content: space-between;
    gap: 32px;
  }

  .testimonials__bottom {
    @media screen and (max-width: 868px) {
      display: grid;
    }
  }

  .testimonial {
  }

  .testimonial .testimonial__user {
    display: flex;
    align-items: center;
    gap: 6px;
    margin: 0;
    padding: 0;
  }

  .testimonial .testimonial__user .img__wrap {
    width: 36px;
    height: 36px;
  }

  .testimonial .testimonial__user .img__wrap img {
    width: 36px;
    height: 36px;
  }
`;

export default Testimonials;
