import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import Copy from "./pages/Copy";
import Staking from "./pages/Staking";
import Contact from "./pages/Contact";
import Security from "./pages/Security";
import About from "./pages/About";
import Lost from "./pages/Lost";
import CopyTerms from "./pages/bottom/CopyTerms";
import Cookie from "./pages/bottom/Cookie";
import Privacy from "./pages/bottom/Privacy";
import StakingTerms from "./pages/bottom/StakingTerms";
import Terms from "./pages/bottom/Terms";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/copy",
      element: <Copy />,
    },
    {
      path: "/staking",
      element: <Staking />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
    {
      path: "/security",
      element: <Security />,
    },
    {
      path: "/about",
      element: <About />,
    },
    {
      path: "/copy-terms",
      element: <CopyTerms />,
    },
    {
      path: "/cookie",
      element: <Cookie />,
    },
    {
      path: "/privacy",
      element: <Privacy />,
    },
    {
      path: "/staking-terms",
      element: <StakingTerms />,
    },
    {
      path: "/terms",
      element: <Terms />,
    },
    {
      path: "*",
      element: <Lost />,
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}
export default App;
