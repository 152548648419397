import { useEffect } from "react";
import "../../styles/bottom/terms.css";
import Header from "../../components/general/Header";
import Footer from "../../components/general/Footer";
import { siteSettings } from "../../static";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="___gatsby">
      <div style={{ outline: "none" }} tabIndex={-1} id="gatsby-focus-wrapper">
        <div className="DefaultLayout__BackgroundContainer-sc-1h2qfsw-0 bzHVOS">
          {/* header */}
          <Header />

          {/* body */}

          <div className="Container-sc-rx245t-0 TermsAndConditionsTemplate__StyledContainer-sc-r7oimv-0 dGblwP jqMLBy">
            <h1 className="TermsAndConditionsTemplate__Title-sc-r7oimv-1 bychxl">
              Terms of service
            </h1>
            <p className="TermsAndConditionsTemplate__Paragraph-sc-r7oimv-2 eOHHud">
              These Terms of Service and any terms expressly incorporated herein
              ("Terms") apply to any person (natural person or otherwise)
              ("User" or "You") accessing or using any Services made available
              by {siteSettings.siteName} Trading Solutions Ltd (and
              collectively, "Us", "Our", "We"), a company incorporated under the
              International Business Companies Act of 1994 of the Republic of
              Seychelles, with a company number of 225155, on this website
              ("Site") or on Our mobile application, and to any other related
              services provided by Us (collectively, the "Services"). By
              accessing or using any Services in any manner whatsoever, You
              agree to be bound by these Terms.
            </p>
            <p className="TermsAndConditionsTemplate__Paragraph-sc-r7oimv-2 eOHHud">
              The Privacy Policy and any other policies communicated by Us shall
              be applicable to Your use of the Services and shall be deemed
              incorporated herein by reference.
            </p>
            <p className="TermsAndConditionsTemplate__Paragraph-sc-r7oimv-2 eOHHud">
              The Services we offer include a peer-to-peer trading platform that
              offers trading of cryptographic tokens and currencies
              ("Cryptocurrency" or "Cryptocurrencies") and leveraged products
              that have Cryptocurrencies as the underlying basis for the
              transaction ("Leveraged Product" or "Leveraged Products" and
              "Cryptocurrency Leveraged Product" or "Cryptocurrency Leveraged
              Products").
            </p>
            <div className="TermsAndConditionsTemplate__List-sc-r7oimv-3 dNmWuD">
              <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                <strong>MODIFICATION OF TERMS</strong>
                <div className="TermsAndConditionsTemplate__List-sc-r7oimv-3 dNmWuD">
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    We may, at Our sole discretion, change, modify, add to, or
                    remove portions of these Terms and related Services from
                    time to time without any prior written notice to You. We may
                    do this for a variety of reasons including to reflect
                    changes and requirements under the law, new features, or
                    changes in business practices. It is Your responsibility to
                    review these Terms periodically for updates or changes. Your
                    continued use of the Services following the posting of
                    changes will mean that You accept and agree to the
                    revisions.
                  </div>
                </div>
              </div>
              <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                <b>ELIGIBILITY</b>
                <div className="TermsAndConditionsTemplate__List-sc-r7oimv-3 dNmWuD">
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>General Requirements: </b>The Services are intended
                    solely for Users who are 18 (eighteen) years and above and
                    who satisfy the criteria described in these Terms. You
                    represent and warrant that You: (a) are of legal age to form
                    a binding contract (at least 18 (eighteen) years old); (b)
                    have not previously been suspended or removed from using our
                    Services; (c) have full power and authority to agree to
                    these Terms; and (d) are not prohibited in the jurisdiction
                    applicable to You from undertaking activities in respect to
                    the Services.
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Restricted Locations: </b>You shall not use the Services
                    if You are located, incorporated or otherwise established
                    in, or a citizen or resident of the United States of America
                    (including, Puerto Rico and the U.S. Virgin Islands),
                    Canada, the Hong Kong Special Administrative Region of the
                    People’s Republic of China, the Republic of Seychelles,
                    Bermuda, Cuba, Crimea and Sevastopol, {/* */}Iran,{/* */}{" "}
                    Syria, North Korea or Sudan, Afghanistan, any state, country
                    or other jurisdiction that is embargoed by the United States
                    of America and any other state, country, territory or other
                    jurisdiction as decided by Us or where Your use of the
                    Services would be illegal or otherwise violate any
                    applicable law by reason of your nationality, domicile,
                    citizenship, residence or otherwise. You represent and
                    warrant that You are not a citizen or resident of any such
                    jurisdiction and that You will not use any Services while
                    located in any such jurisdiction. You also may not use the
                    Services if You are located in, or a citizen or resident of,
                    any other jurisdiction wherein the laws of Your country
                    prohibit You from trading Cryptocurrencies and leveraged
                    trading on exchanges which are outside of Your Country or
                    where We have determined, at Our discretion, to prohibit use
                    of the Services. We may implement controls to restrict
                    access to the Services from any jurisdiction prohibited
                    pursuant to this Section 2.2. You will comply with this
                    Section 2.2, even if Our methods to prevent use of the
                    Services are not effective or can be bypassed. You
                    understand and acknowledge that if it is determined that you
                    have given false representations as to your location or
                    place of residence, We reserve the right to close your
                    account immediately and to liquidate any open positions.
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Use of Account: </b>You represent and warrant that You
                    will be the sole authorized user of the Services and its
                    corresponding User Account. You are granted an exclusive and
                    non-assignable right to the use of and the access to the
                    User Account, and you acknowledge and agree that You are
                    entirely responsible for ensuring that the User Account is
                    accessed only by You. You will use the Services and Your
                    User Account (as defined below) only for Yourself and not on
                    behalf of, or for the account of, any third party. You may
                    not, directly or indirectly: (i) share Your User Account
                    log-in details; or (ii) allow a third party to access Your
                    User Account, including without limitation, a next of kin,
                    member of Your immediate family, a friend, or any other
                    third party ("Unauthorized Use"). You acknowledge and agree
                    that You shall be liable for all Orders received by Us on
                    Your behalf including, without limitation, to: (i) Orders
                    submitted through Your Trading Platform; or (ii) Orders made
                    using Your User Account. You acknowledge and agree that We
                    are under no obligation to investigate such Unauthorized
                    Use. You represent and warrant that you will not be acting
                    on behalf of a third party, or under the name of a third
                    party, while using Our services. You agree and understand
                    that any such Unauthorized Use by You will not subject Us to
                    any relationship or form a legal basis to any relationship
                    created by You. You agree to indemnify us and hold us
                    harmless from any loss or liability resulting from any such
                    Unauthorized Use.
                  </div>
                </div>
              </div>
              <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                <b>ACCOUNT</b>
                <div className="TermsAndConditionsTemplate__List-sc-r7oimv-3 dNmWuD">
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>User Account: </b>In order to use any of the Services,
                    You must create and maintain an account through the Services
                    ("User Account"). To create or maintain Your User Account,
                    or enable functions on Your User Account, You will be
                    required to provide Us with certain information and
                    documentation, including, as applicable, the information and
                    documentation associated with identity verification and
                    other screening procedures described in Section 3.3, below.
                    You will: (a) create a unique password; (b) provide complete
                    and accurate information; (c) promptly update any
                    information You have provided so that the information is
                    complete and accurate at all times; (d) maintain the
                    security of Your User Account by protecting Your password
                    from unauthorized access or use; (e) promptly notify Us if
                    You discover or suspect any unauthorized access or use of
                    Your User Account or any security breaches related to Your
                    User Account; (f) be responsible for all activities that
                    occur under Your User Account, and accept all risks of any
                    authorized or unauthorized access to Your User Account.
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Enhanced Security: </b>We may offer optional enhanced
                    security features for Your User Account (including, for
                    example, two-factor authentication). We encourage, but may
                    not require, You to use any such enhanced security features.
                    Whether You enable the enhanced security features or not, it
                    is Your responsibility to ensure the security of, and Your
                    continuous control over, any device or account that may be
                    associated with the enhanced security features.
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Identity Verification: </b>We may, at Our discretion,
                    require identity verification and other screening procedures
                    with respect to You or the transactions associated with Your
                    User Account at any time ("Customer Due Diligence"). These
                    verification and screening procedures may include, without
                    limitation, checking the information You provide to any
                    governmental authority. You may be required to provide Us
                    with certain personal information, including, but not
                    limited to, Your name, address, telephone number, email
                    address, date of birth, passport number, photograph of Your
                    government-issued ID and any other information as may be
                    required. You hereby authorize Us, directly or through a
                    third party, to make any inquiries We consider necessary to
                    verify Your identity and/or protect against fraud, including
                    but not limited to: (a) query identity information contained
                    in public reports (e.g., Your name, address, past addresses,
                    or date of birth); (b) query account information associated
                    with Your linked bank account (e.g., name or account
                    balance); and (c) take action We reasonably deem necessary
                    based on the results of such inquiries and reports. You
                    agree to fully cooperate with Us throughout the identity
                    verification process and to provide Us with further
                    information or documentation as We may require from You to,
                    without limitation, verify Your identity and assess the
                    purpose of the business relationship. You acknowledge and
                    agree that We reserve the right to impose, without
                    limitation, trading limits and withdrawal limits until You
                    complete to the Customer Due Diligence to Our satisfaction.
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Responsibility for Account Activities: </b>You will be
                    bound by, and hereby authorize Us to accept and rely on any
                    agreements, instructions, orders, authorizations and any
                    other actions made, provided or taken by anyone who has
                    accessed or used Your User Account regardless of whether the
                    access is authorized or unauthorized. Upon receipt of
                    written notice from You that the security of Your User
                    Account has been compromised, We will take reasonable steps
                    to protect Your User Account, including, for example, to
                    cease to allow actions initiated using any compromised
                    account passwords, in the event such actions are not already
                    completed. We shall, under no circumstance, be liable for
                    any loss incurred by You by any unauthorised use of Your
                    User Account.
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Account inactivity: </b>We reserve the right to charge
                    maintenance fees for storing assets in accounts that had no
                    trading activity for 1 year or more. The size and frequency
                    of the fee is determined individually for each account.
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Multiple accounts: </b>The creation of multiple accounts
                    by a single user is prohibited. If we detect the existence
                    of multiple accounts held by a single user, we reserve the
                    right to request verification in accordance with Section 3.3
                    of these terms of service. Additionally, we retain the right
                    to close any duplicate or unauthorized accounts found in
                    violation of this policy and/or impose a fee equal to 20% of
                    the accounts balances, or as otherwise determined by us, at
                    our sole discretion.
                  </div>
                </div>
              </div>
              <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                <b>RISK DISCLOSURES; ASSUMPTION OF RISKS</b>
                <div className="TermsAndConditionsTemplate__List-sc-r7oimv-3 dNmWuD">
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    The trading of Cryptocurrencies and Cryptocurrency Leveraged
                    Products, and the use of other Services provided by Us,
                    involves significant risks and potential for financial
                    losses, including without limitation the following:
                    <div className="TermsAndConditionsTemplate__List-sc-r7oimv-3 dNmWuD">
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          The features, functions, characteristics, operation,
                          use and other properties of Cryptocurrencies
                          ("Currency Properties") and the software, networks,
                          protocols, systems, and other technology (including,
                          if applicable, any blockchain) ("Underlying
                          Technology") used to administer, create, issue,
                          transfer, cancel, use or transact using the
                          Cryptocurrencies may be complex, technical or
                          difficult to understand or evaluate.
                        </span>
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          Cryptocurrencies, their Underlying Technology, the
                          Site and other Services may be vulnerable to attacks
                          on the security, integrity or operation of the
                          Cryptocurrencies or their Underlying Technology
                          ("Threats"), including Threats using computing power
                          sufficient to overwhelm the normal operation of a
                          blockchain or other Underlying Technology.
                        </span>
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          Cryptocurrencies, their Properties or Underlying
                          Technology may change or otherwise cease to operate as
                          expected due to changes made to the Underlying
                          Technology, changes made using features or functions
                          built into the Underlying Technology or change
                          resulting from a Threat.
                        </span>
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          Any Cryptocurrency may be cancelled, lost, double
                          spent, or otherwise lose all or most of their value,
                          due to various Threats, changes to the Currency
                          Properties or failure of the Cryptocurrency to operate
                          as intended.
                        </span>
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          We may suspend or cease to support the transfer,
                          storage or trading of any Cryptocurrency or
                          Cryptocurrency Leveraged Products at any time at Our
                          discretion.
                        </span>
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          We may suspend or reject Your transaction requests,
                          suspend or cease support for certain Cryptocurrencies,
                          or suspend or terminate Your access to the Services to
                          comply with applicable laws or regulations or an order
                          from law enforcement or other governmental authority,
                          for other reasons as specified in these Terms or
                          otherwise at Our discretion.
                        </span>
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          You may be unable to withdraw Cryptocurrencies prior
                          to {siteSettings.siteName} Trading Solutions Ltd
                          ceasing to support transfer of any such
                          Cryptocurrencies, resulting in the loss of any such
                          Cryptocurrencies remaining in Your User Account.
                        </span>
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          Any Cryptocurrency may decrease in value or lose all
                          of its value due to various factors including
                          discovery of wrongful conduct, market manipulation,
                          changes to Currency Properties or perceived value of
                          Currency Properties, Threats, suspension or cessation
                          of support for a Cryptocurrency by Us or other
                          exchanges or service providers, and other factors
                          outside Our control.
                        </span>
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          You may be prevented from sending a transaction
                          request, or Your transaction request or email may not
                          be received by Us or the Services, due to hardware,
                          software or services issues (including, without
                          limitation, Internet and other network connectivity
                          issues).
                        </span>
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          Your transaction request or email to Us or the
                          Services may be lost, intercepted or altered during
                          transmission.
                        </span>
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          Unauthorized third parties may access or use Your User
                          Account and effect transactions without Your knowledge
                          or authorization, whether by obtaining the password to
                          Your User Account, obtaining control over another
                          device or account used by You in connection with any
                          enhanced security measures enabled for Your account,
                          or by other methods.
                        </span>
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          The risks described in Article 4 of these Terms may
                          result in loss of Cryptocurrency, decrease in or loss
                          of all value of the Cryptocurrencies, inability to
                          access or transfer Cryptocurrencies, inability to
                          trade Cryptocurrencies, inability to receive financial
                          benefits which are available to other Cryptocurrency
                          holders, and other financial losses to You. You hereby
                          assume and agree that We will have no responsibility
                          or liability for such risks. You hereby irrevocably
                          waive, release and discharge any and all claims,
                          whether known or unknown to You, against Us, Our
                          Affiliates and respective shareholders, members,
                          directors, officers, employees, agents and
                          representatives related to any of the risks set forth
                          herein.
                        </span>
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          You represent and warrant that You have: (a) the
                          necessary technical expertise and ability to review
                          and evaluate the security, integrity and operation of
                          any Cryptocurrency or Cryptocurrency Leveraged Product
                          that You decide to acquire or trade; and (b) the
                          knowledge, experience, understanding, professional
                          advice and information to make Your own evaluation of
                          the merits and risks of any Cryptocurrency or trade as
                          supported by the Services. You accept the risk of
                          trading Cryptocurrencies or Cryptocurrency Leveraged
                          Products by using the Services and are responsible for
                          conducting Your own independent analysis of the risks
                          specific to the Cryptocurrencies, Cryptocurrency
                          Leveraged Products and the Services. You should not
                          place any "Orders" to acquire or trade any
                          Cryptocurrency Leveraged Products unless You have
                          deposited sufficient Accepted Currency and can afford
                          to lose all value of the Accepted Currency. In other
                          words, You must ensure that You have sufficient
                          balance available in Your Hosted Wallet (as defined
                          below) to reserve a Cryptocurrency Leveraged Product
                          margin ("Margin").
                        </span>
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          You represent and warrant that You have the technical
                          ability and expertise to understand and evaluate the
                          consequence or effects of any trading contracts
                          (including Cryptocurrency Leveraged Products) You
                          make, including such of these contracts or trades as
                          may remain outstanding from time to time, after
                          squaring-up or netting-off any of such trades, to the
                          extent feasible ("Position"). Our decision to support
                          transfer, storage or trade any particular
                          Cryptocurrency or Cryptocurrency Leveraged Product
                          through the Services does not indicate Our approval or
                          disapproval of the Cryptocurrency or the integrity,
                          security or operation of the Cryptocurrency or its
                          Underlying Technology. The risks associated with
                          Cryptocurrencies or Cryptocurrency Leveraged Products
                          and trading them applies notwithstanding Our decision
                          to support a particular Cryptocurrency or any
                          Cryptocurrency Leveraged Product as supported by Our
                          Services. We (a) do not provide trading advice, (b) do
                          not have any fiduciary duty to You or any other user,
                          and (c) do not make any warranty about the suitability
                          of Cryptocurrency for trading or ownership by You.
                        </span>
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          There may be additional risks that we have not
                          foreseen or identified in our terms of service.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                <b>PRIVACY POLICY </b>Please refer to Our privacy policy for
                information about how We collect, use, and share Your
                information. Our Privacy Policy is available
                <a
                  href="privacy-policy.html"
                  className="TermsAndConditionsTemplate__Link-sc-r7oimv-5 dPMDBn"
                >
                  here
                </a>
                .
              </div>
              <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                <b>WARRANTIES AND REPRESENTATIONS</b>
                <div className="TermsAndConditionsTemplate__List-sc-r7oimv-3 dNmWuD">
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <span>You represent, warrant and acknowledge that:</span>
                    <div className="TermsAndConditionsTemplate__List-sc-r7oimv-3 dNmWuD">
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        You are authorised to access and use the Site; in
                        particular, the jurisdiction where You reside, hold
                        citizenship, or conduct business in such jurisdictions
                        as mentioned under these Terms that allows You to
                        utilize the Services;
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        If You are using the Site on behalf of or for the
                        benefit of any organisation then it is assumed that You
                        have the right to do so. The organisation and You will
                        be jointly and severally liable for Your actions
                        including any breach of these Terms;
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        Your use of the Services is at Your own risk. You agree
                        that We shall not be liable for any damage or harm
                        arising out of Your use of the Services;
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        The information provided on the Site is for general
                        information purposes only and is given in good faith.
                        However, the information is selective, and We may not
                        verify all information, which may not be complete or
                        accurate for Your purposes and should not be relied upon
                        without further enquiry. The information should not be
                        construed as a recommendation to trade or engage the
                        Services provided by Us in a particular manner;
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        We do not warrant that the use of the Services will be
                        uninterrupted or error free. Among other things, the
                        operation and availability of the systems used for
                        accessing the Services, including public computer
                        networks and the internet, can be unpredictable and may
                        from time to time interfere with or prevent access to
                        the Services. We are not in any way responsible or
                        liable for any such interference that prevents Your
                        access or use of the Service
                      </div>
                    </div>
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    We provide no warranty about the Services. Without limiting
                    the foregoing, We do not warrant that the Services will meet
                    Your requirements or that it will be suitable for Your
                    purposes. To avoid any doubt, all implied conditions or
                    warranties are excluded insofar as is permitted by law
                    including, without limitation, warranties of
                    merchantability, fitness for purpose, title and
                    non-infringement.
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    You warrant and represent that You are acquiring the right
                    to access and use the Site and agreeing to these Terms for
                    the purposes of a trade and that, to the maximum extent
                    permitted by law, any statutory consumer guarantees or
                    legislation intended to protect non-business consumers in
                    any jurisdiction does not apply to the supply and use of the
                    Site or these Terms.
                  </div>
                </div>
              </div>
              <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                <b>SERVICE PERFORMANCE</b>
                <div className="TermsAndConditionsTemplate__List-sc-r7oimv-3 dNmWuD">
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <span>
                      We deny all liability for the timely operation of the
                      Services, where You or a third party is providing the
                      computer equipment upon which the Service is dependent
                      upon for any part of its functionality.
                    </span>
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <span>
                      By using the Service, You confirm Your understanding that
                      the timely operation of the internet and the World Wide
                      Web is governed by constraints beyond Our control. You
                      accept that We are not liable for any perceived slow
                      operation of the Services.
                    </span>
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <span>
                      By using the Service, You accept that all trade executions
                      pursuant to Cryptocurrencies or Cryptocurrency Leveraged
                      Products which You have executed are final and
                      irreversible.
                    </span>
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <span>
                      By using the Service, You accept that We reserve the right
                      to liquidate or close any trades and/or withdraw funds
                      without notice <strong>from your User Account</strong> at
                      any time, regardless of the profit or loss that You may
                      have incurred while trading on any Position to: ensure
                      that actual Margin equals or exceeds required Margin to
                      maintain open positions; and to satisfy any payment
                      obligation to {siteSettings.siteName} Trading Solutions
                      Ltd, including commissions, margin financing and other
                      costs in respect to User Accounts.
                    </span>
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <span>
                      We do not warrant that the Service will meet Your
                      requirements; that the Service will be uninterrupted,
                      timely, secure, or error-free; that the information
                      provided through the Service is accurate, reliable or
                      correct; that any defects or errors will be corrected, or
                      that the Service will be available at any particular time
                      or location. You assume full responsibility and risk of
                      loss resulting from Your use of the Service.
                    </span>
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <span>
                      We shall make reasonable efforts to ensure that the
                      Services are available to you. However, access to the
                      Services may be disrupted from time to time due to
                      necessary maintenance, technical issues, network and
                      system overloads or events outside of our control. We will
                      use commercially reasonable efforts to avoid downtime of
                      the Services during anticipated peak hours, but assumes no
                      liability (whether for trading-related losses or
                      otherwise) if the Services or any part thereof are
                      unavailable at any time or for any period.
                    </span>
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <span>
                      We may, at any time, in our sole discretion and without
                      notice, refuse to process a transaction or impose
                      restrictions on trade amount limits. Specifically, we
                      reserve the right to reverse or cancel, and the right to
                      refuse to process, any transactions, as well as to revoke
                      access to a User’s deposit address on the Platform, if we
                      suspect that the transaction is related to money
                      laundering, terrorist financing, fraud or any other type
                      of crime, or if we suspect that the transaction is related
                      to a prohibited use as stated in the Terms.
                    </span>
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <span>
                      {siteSettings.siteName} Trading Solutions Ltd may correct,
                      cancel or reverse any trade impacted by an error in
                      processing a User’s transaction or otherwise at our own
                      discretion. The User’s remedy in the event of an error
                      will be limited to seeking to cancel an Order or obtaining
                      a refund of any amounts charged to the User.{" "}
                      {siteSettings.siteName} Trading Solutions Ltd cannot
                      guarantee such cancellations or refunds will always be
                      possible.
                    </span>
                  </div>
                </div>
              </div>
              <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                <b>ACCOUNT FUNDING; TRANSFERS</b>
                <div className="TermsAndConditionsTemplate__List-sc-r7oimv-3 dNmWuD">
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Initial Account Funding:</b>
                    <span>
                      Upon creation of an User Account on Our Site, We will
                      provide You with a specific address where You can deposit
                      Your Cryptocurrency. The Cryptocurrency You deposit will
                      be reflected in Your wallet balance ("Hosted Wallet") and
                      will be available for You to trade on the Site. You agree
                      and acknowledge that once You have deposited the
                      Cryptocurrency in accordance with Our instructions, the
                      Cryptocurrency will be under Our control.
                    </span>
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Deposits: </b>
                    <span>
                      You may periodically, at Your discretion, deposit
                      Cryptocurrency to Your Hosted Wallet through the specific
                      address for the Cryptocurrency provided by Us, any
                      Cryptocurrency that is supported for transfer and storage
                      using the Services. If You transfer to Your Hosted Wallet
                      any Cryptocurrencies that are not supported by the
                      Services, such Cryptocurrencies may be permanently lost.
                    </span>
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Withdrawals: </b>
                    <span>
                      Any withdrawal account number or cryptocurrency address
                      you provide is your own and that you are in full control
                      over this account or address. When You realise a profit or
                      loss pursuant to a trade in respect to a Cryptocurrency or
                      a Cryptocurrency Leveraged Product, the amounts of
                      Cryptocurrencies in Your Hosted Wallet will change
                      accordingly. You are required to retain, in Your Hosted
                      Wallet, a sufficient quantity of Cryptocurrency balance
                      necessary to satisfy any Margin requirement for Your
                      Cryptocurrency Leveraged Product trades (including
                      applicable convenience fees). Only Cryptocurrencies that
                      have not been blocked in an existing Order or Position are
                      available for withdrawal ("Available Balance"). In
                      addition, there may be limits on the amounts that You are
                      able to withdraw on a daily or other periodic basis.
                      Otherwise, You may periodically at Your discretion
                      withdraw the Available Balance of Cryptocurrencies in Your
                      Hosted Wallet by placing a withdrawal request with Us
                      through Your Account which We will process once in 24
                      (twenty four) hours (at such time as determined by Us at
                      Our sole discretion), on a best efforts basis. The
                      transfer of such Cryptocurrencies pursuant to the
                      withdrawal request as stated above, will be made to an
                      address not controlled by Us ("External Address"). We may
                      require You to verify the External Address to which You
                      seek to withdraw Your Cryptocurrency to. You hereby
                      authorize Us to use the Available Balance in Your Hosted
                      Wallet to send such a number of Cryptocurrency specified
                      by You to any External Address specified by You, using the
                      Services. We will not be able to reverse any transfers and
                      will not have any responsibility or liability if You have
                      instructed Us to send Cryptocurrency to an address that is
                      incorrect, improperly formatted, erroneous or intended for
                      a different type of currency, or does not support the
                      Cryptocurrency.
                    </span>
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Other Applicable Terms:</b>
                    <span>
                      You will be responsible for: (a) paying all fees charged
                      by any third party service provider associated with any
                      External Address along with the payment of any fees
                      charged by Us for any Cryptocurrency or Cryptocurrency
                      Leveraged Product trade execution; (b) ensuring that any
                      deposit and/ or withdrawal are in compliance with Our
                      requirements; (c) ensuring that the address to which Your
                      Cryptocurrencies are to be deposited or withdrawn is
                      properly formatted and suitable for the type of trade as
                      per the Cryptocurrency Leveraged Product; and (d) ensuring
                      that there are no errors in any of the transfer
                      instructions You provide using the Services. In the event
                      You fail to comply with any requirements of this Section
                      8.4, the transferred Cryptocurrencies may be permanently
                      lost. The timing for completing any transfer will depend
                      on third party actions that are outside of Our control and
                      {siteSettings.siteName} Trading Solutions Ltd makes no
                      guarantee regarding the amount of time it may take to
                      complete any transfer. We may impose limits on the amount
                      of any inbound or outbound transfers, or suspend or
                      terminate the ability to transfer Cryptocurrencies into or
                      out of Your Hosted Wallet in order to comply with
                      applicable laws or regulations, an order from law
                      enforcement or other governmental authority, or otherwise
                      at Our discretion.
                    </span>
                  </div>
                </div>
              </div>
              <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                <b>EXCHANGE ORDERS AND TRADES</b>
                <div className="TermsAndConditionsTemplate__List-sc-r7oimv-3 dNmWuD">
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Orders; Fees: </b>
                    <span> A “trade” is:&nbsp;</span>
                    <div className="TermsAndConditionsTemplate__List-sc-r7oimv-3 dNmWuD">
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          an exchange of Cryptocurrencies between You and
                          another user of the Services; or
                        </span>
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          an agreement to enter into a Cryptocurrency Leveraged
                          Products with another user of the Services as
                          supported through the Services ("Order"). An Order is
                          created when You enter instructions to effect a trade
                          using the Services. When You create an Order to trade
                          Cryptocurrencies, You authorize Us to execute a trade
                          for all or a portion of the number of Cryptocurrencies
                          specified in Your Order in accordance with such Order.
                          When you create an Order to trade through
                          Cryptocurrency Leveraged Product, You authorize Us to
                          block a margin for the required amount of
                          Cryptocurrency to support the Order as specified in
                          the Cryptocurrency Leveraged Product, and execute a
                          trade for all or a portion of the number of contracts
                          specified in Your Order in accordance with such Order.
                          You agree to pay Us the specific fees ("Commission
                          Fees") for each trade made by You and authorize Us to
                          deduct any such fees from Your Hosted Wallet.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Fees and Costs:</b>
                    <div className="TermsAndConditionsTemplate__List-sc-r7oimv-3 dNmWuD">
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          The Services provided by {siteSettings.siteName}{" "}
                          Trading Solutions Ltd, including without limitation to
                          performing of both trading and non-trading operations
                          under this Agreement, are subject to payment of trade
                          commission, margin financing, deposits or withdrawal
                          related charges, and other ("Fees") as disclosed in
                          the fee schedule on the {siteSettings.siteName}{" "}
                          Trading Solutions Ltd website. You acknowledge and
                          agree that We reserve the right to change Our Fees at
                          our discretion without prior notice to You.
                        </span>
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          In carrying out its operations and paying in or
                          withdrawing funds, {siteSettings.siteName} Trading
                          Solutions Ltd charges commission based on, without
                          limitation, the type of Transaction, the Transaction
                          currency, and the system of payment.
                        </span>
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          When You place a trade in Leveraged Products, You
                          understand and agree that the Fees associated with
                          executing the Transaction or maintaining an open
                          position, and other related Fees, appear as a
                          percentage of the value of the Order. You acknowledge
                          that You bear the sole responsibility for
                          understanding how Fees are calculated.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>No Broker or Fiduciary Relationship: </b>
                    <span>
                      {siteSettings.siteName} Trading Solutions Ltd, is not Your
                      broker, intermediary, agent, or advisor and has no
                      fiduciary relationship or obligation to You in connection
                      with any Trades or other decisions or activities affected
                      by You using the Services. No communication or information
                      provided to You by Us is intended as, or shall be
                      considered or construed as, advice.
                    </span>
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Order Matching and Trade Execution: </b>
                    <span>
                      Upon submission of an Order by You, Your Order will be
                      included in Our order book for matching with Orders from
                      other users. If all or a portion of Your Order is matched
                      with another user, the Services will execute a trade. Upon
                      execution of a trade, Your User Account will be updated to
                      reflect that the Order has either been closed due to
                      having been fully executed, or updated to reflect any
                      partial fulfilment of the Order. Orders will remain open
                      until fully executed or cancelled in accordance with
                      Section 13.5 below.
                    </span>
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Cancellations: </b>
                    <span>
                      You may only cancel an Order initiated via the Services if
                      such cancellation occurs before Your Order has been
                      matched with an Order from another user. Once Your Order
                      has been matched with an Order from another user, You may
                      not change, withdraw, or cancel Your Order.
                    </span>
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Insufficient Currencies:</b>
                    <span>
                      In the event You have an insufficient amount of available
                      balance/ Currencies in Your Hosted Wallet to fulfill an
                      Order on the date of the trade, We may cancel the entire
                      Order or may fulfill a partial Order that can be covered
                      by the Currencies based on the available balance in Your
                      Hosted Wallet (after deducting any fees payable to Us in
                      connection with the trade).
                    </span>
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Unacceptable Use or Conduct:</b>
                    <span>You will not</span>
                    <div className="TermsAndConditionsTemplate__List-sc-r7oimv-3 dNmWuD">
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          violate any law, regulation, contract, intellectual
                          property or other third-party right, or commit a tort
                          while using the Services;
                        </span>
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          use the Services in any manner that could interfere
                          with, disrupt, negatively affect, or inhibit other
                          users from fully enjoying the Services, or that could
                          damage, disable, overburden, or impair the functioning
                          of the Services in any manner;
                        </span>
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          use the Services to pay for, support, or otherwise
                          engage in any illegal gambling activities, fraud,
                          money-laundering, or terrorist activities, or other
                          illegal activities;
                        </span>
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          use any robot, spider, crawler, scraper, or other
                          automated means or interface not provided by Us to
                          access the Services or to extract data;
                        </span>
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          use or attempt to use another user’s account without
                          authorization;
                        </span>
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          attempt to circumvent any content filtering techniques
                          We employ, or attempt to access any Service or area of
                          the Services that You are not authorized to access;
                        </span>
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          introduce to the Services any malware, virus, trojan
                          worms, logic bombs, or other harmful material;
                        </span>
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          develop any third-party applications that interact
                          with our Services without our prior written consent,
                          or unless otherwise agreed;
                        </span>
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          provide false, inaccurate, or misleading information;
                        </span>
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          post content or communications that are, in our sole
                          discretion, libellous, defamatory, profane, obscene,
                          pornographic, sexually explicit, indecent, lewd,
                          vulgar, suggestive, harassing, hateful, threatening,
                          offensive, discriminatory, bigoted, abusive,
                          inflammatory, fraudulent, deceptive or otherwise
                          objectionable;
                        </span>
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          post content containing unsolicited promotions,
                          political campaigning, or commercial messages (SPAM)
                          or any chain messages or user content designed to
                          deceive or trick the user of the Service;
                        </span>
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          post content containing private information of any
                          third-party including, but not limited to, addresses,
                          phone numbers, email addresses, Social Security
                          numbers and credit card numbers; or
                        </span>
                      </div>
                      <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                        <span>
                          encourage or induce any third party to engage in any
                          of the activities prohibited under this Section.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                <b>LIQUIDATION</b>
                <div className="TermsAndConditionsTemplate__List-sc-r7oimv-3 dNmWuD">
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <span>
                      Our Cryptocurrency Leveraged Products are leveraged and to
                      keep leveraged positions open, You are required to hold a
                      minimum percentage of the value of the Position
                      ("Maintenance Margin"). The price at which the Maintenance
                      Margin requirement is breached is referred to as the
                      "Liquidation Price". When the Liquidation Price of a
                      Position is achieved, the process of liquidation of that
                      particular Position is initiated.
                    </span>
                  </div>
                </div>
              </div>
              <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                <b>&nbsp;COMPLIANCE WITH LAW</b>
                <div className="TermsAndConditionsTemplate__List-sc-r7oimv-3 dNmWuD">
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <span>
                      {siteSettings.siteName} Trading Solutions Ltd is not a tax
                      agent and thus shall not provide clients' financial
                      information to any third parties. You are responsible for
                      complying with all applicable laws related to Your trading
                      activities and other use of the Services, including
                      without limitation any reporting obligations and payment
                      of all applicable taxes. You will determine what, if any,
                      taxes apply to the Trades and any other transactions You
                      complete via the Services, and it is Your responsibility
                      to report and remit the correct tax to the appropriate tax
                      authority. We will not be responsible for determining
                      whether taxes apply to Your trades or for collecting,
                      reporting, withholding, or remitting any taxes arising
                      from any trades.
                    </span>
                  </div>
                </div>
              </div>
              <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                <b>ANTI MONEY LAUNDERING</b>
                <div className="TermsAndConditionsTemplate__List-sc-r7oimv-3 dNmWuD">
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <span>
                      You acknowledge that due to anti-money laundering
                      regulations within Your jurisdiction, including not
                      limited to the with the Seychelles’ Anti-Money Laundering
                      Act 2006 or any other Applicable Law, but We may require
                      further documentation for verifying Your identity and the
                      source of funds used to trade using the Services prior to
                      any confirmation of an Order. You further agree to provide
                      Us, at any time, with such information as We determine to
                      be necessary and appropriate to verify compliance with the
                      anti-money laundering regulations of any applicable
                      jurisdiction or to respond to requests for information
                      concerning Your identity from any governmental authority,
                      self-regulatory organization or financial institution in
                      connection with its anti-money laundering compliance
                      procedures, and to update such information as necessary.
                    </span>
                  </div>
                </div>
              </div>
              <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                <b>CHANGES; SUSPENSION; TERMINATION</b>
                <div className="TermsAndConditionsTemplate__List-sc-r7oimv-3 dNmWuD">
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Suspension or Termination of Services and Account: </b>We
                    may, at Our discretion and without liability to You, with or
                    without prior notice and at any time, temporarily suspend or
                    permanently terminate Your access to all or a portion of any
                    Services.
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Termination by User: </b>You may also at Your sole
                    discretion terminate Your Account by sending us a written
                    request stating Your intentions to do so. Upon the receipt
                    of Your request, We will provide You with a written notice
                    of 5 (five) days ("Notice Period") to permanently terminate
                    Your Account. You shall be required to withdraw all the
                    Cryptocurrencies available in Your Hosted Account within the
                    Notice Period. Failing to do so will result in the permanent
                    loss of the Cryptocurrencies balance in Your Hosted Wallet
                    and {siteSettings.siteName} Trading Solutions Ltd, shall not
                    be in any way obligated or liable to recover Your lost
                    Cryptocurrencies in any manner whatsoever.
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>No Liability: </b>We will not be liable for any losses
                    suffered by You resulting from any modification of any
                    Services or from any suspension or termination of Your
                    access to all or a portion of any Services. If and when
                    Services resume, You acknowledge that Cryptocurrency and
                    Cryptocurrency Leveraged Product valuations and exchange
                    rates may differ significantly from the valuations and rates
                    prior to such events.
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Effect of Termination: </b>In the event of
                    discontinuation of all Services or other termination of Your
                    right to access all Services: (a) all amounts payable by You
                    to Us or against any matched Order will immediately become
                    due; (b) We may delete or deactivate Your User Account and
                    all related information and files in such account without
                    liability to You; and (c) We may cancel any existing
                    positions and open orders at the time of discontinuation or
                    termination. In the event of discontinuation or termination
                    of all Services or discontinuation or termination of
                    transfer or storage Services for all or some
                    Cryptocurrencies, We will use commercially reasonable
                    efforts, unless prohibited in order to comply with
                    applicable laws or regulations or by order of law
                    enforcement or other governmental authority, to provide You
                    with a period of 30 (Thirty) days to remove the affected
                    Cryptocurrencies from Your Hosted Wallet.
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Survival: </b>The terms of Sections 2.3, 3.3, 4, 5 and
                    8.3, 8.4, 9.2. 9.8, 13 through 20 will survive any
                    termination of Your access to the Services.
                  </div>
                </div>
              </div>
              <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                <b>&nbsp;PROPRIETARY RIGHTS</b>
                <div className="TermsAndConditionsTemplate__List-sc-r7oimv-3 dNmWuD">
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Ownership of Services: </b>The Services and all
                    technology, content and other materials used, displayed or
                    provided in connection with the Services including the Site
                    ("{siteSettings.siteName} Trading Solutions Ltd Materials")
                    together with all intellectual property rights in any of the
                    foregoing are, as between You and Us, owned by Us or Our
                    licensors.
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Limitations: </b>You may use the {siteSettings.siteName}{" "}
                    Trading Solutions Ltd Materials solely as authorized by Us
                    in connection with Your use of the Services for as long We
                    permit You to continue to access the Services. Without
                    limiting the foregoing: You will not (a) resell, lease,
                    lend, share, distribute or otherwise permit any third party
                    to use the Site, Services or Our materials or use the Site,
                    Services or
                    {siteSettings.siteName} Trading Solutions Ltd Materials in
                    any service bureau environment; (b) modify or create
                    derivative works of the Site, Services or Our materials, or
                    any portion thereof; (c) frame, display or incorporate the
                    Site, Services or {siteSettings.siteName}
                    Trading Solutions Ltd Materials in any website or any other
                    work of authorship; (d) decompile, disassemble, reverse
                    engineer or attempt to discover the source code of the Site,
                    Services or {siteSettings.siteName} Trading Solutions Ltd
                    Materials; (e) use the Site, Services or{" "}
                    {siteSettings.siteName} Trading Solutions Ltd Materials to
                    design, develop or create any competing product or service;
                    or (f) otherwise use the Site, Services or{" "}
                    {siteSettings.siteName}
                    Trading Solutions Ltd Materials for any commercial or
                    non-commercial purpose other than their intended purposes
                    determined at Our discretion. "{siteSettings.siteName}{" "}
                    Trading Solutions Ltd", any product or service names, logos,
                    and other marks used on the Site or {siteSettings.siteName}{" "}
                    Trading Solutions Ltd Materials, or otherwise in connection
                    with the Services, are trademarks owned by Us or its
                    licensors. You may not copy, imitate or use them without{" "}
                    {siteSettings.siteName} Trading Solutions Ltd’s prior
                    written consent.
                  </div>
                </div>
              </div>
              <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                <b>THIRD-PARTY CONTENT</b>
                <div className="TermsAndConditionsTemplate__List-sc-r7oimv-3 dNmWuD">
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <span>
                      In using the Services, You may view content provided by
                      third parties ("Third-Party Content"). We do not control,
                      endorse, or adopt any Third-Party Content and shall have
                      no responsibility for Third‑Party Content, including
                      without limitation material that may be misleading,
                      incomplete, erroneous, offensive, indecent, or otherwise
                      objectionable. In addition, Your business dealings or
                      correspondence with such third parties are solely between
                      You and the third parties. We are not responsible or
                      liable for any loss or damage of any sort incurred as the
                      result of any such dealings, and You understand that Your
                      use of Third-Party Content, and Your interactions with
                      third parties, is at Your own risk.
                    </span>
                  </div>
                </div>
              </div>
              <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                <b>DISCLAIMER OF WARRANTIES</b>
                <div className="TermsAndConditionsTemplate__List-sc-r7oimv-3 dNmWuD">
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <span>
                      To the maximum extent permitted under applicable law, the
                      Site, the Services, the materials and any Service provided
                      by or on behalf of Us are provided on an "as is" and "as
                      available" basis and We expressly disclaim, and You waive,
                      any and all other warranties of any kind, whether
                      expressed or implied, including, without limitation,
                      implied warranties of merchantability, fitness for a
                      particular purpose, title or non-infringement or
                      warranties arising from course of performance, course of
                      dealing or usage in trade. Without limiting the foregoing,
                      {siteSettings.siteName} Trading Solutions Ltd does not
                      represent or warrant that the Site, the Services or Our
                      materials are accurate, complete, reliable, current,
                      error-free, or free of viruses or other harmful
                      components.
                    </span>
                  </div>
                </div>
              </div>
              <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                <b>INDEMNIFICATION</b>
                <div className="TermsAndConditionsTemplate__List-sc-r7oimv-3 dNmWuD">
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <span>
                      You will defend, indemnify, and hold harmless{" "}
                      {siteSettings.siteName}
                      Trading Solutions Ltd, its affiliates, and their
                      respective shareholders, members, directors, officers,
                      employees, attorneys, agents, representatives, suppliers
                      and contractors (collectively, "Indemnified Parties") from
                      any claim, demand, lawsuit, action, proceeding,
                      investigation, liability, damage, loss, cost or expense,
                      including without limitation reasonable attorneys’ fees,
                      arising out or relating to (a) Your use of, or conduct in
                      connection with, the Services; (b) Your violation of these
                      Terms; or (c) Your violation of any applicable law or the
                      rights of any other person or entity. If You are obligated
                      to indemnify any Indemnified Party, We, at Our discretion,
                      will have the right to control any action or proceeding
                      and to determine whether We wish to settle, and if so, on
                      what terms.
                    </span>
                  </div>
                </div>
              </div>
              <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                <b>&nbsp;LIMITATION OF LIABILITY</b>
                <div className="TermsAndConditionsTemplate__List-sc-r7oimv-3 dNmWuD">
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <span>
                      In no event will the liability of {siteSettings.siteName}{" "}
                      Trading Solutions Ltd, its affiliates and their respective
                      shareholders, members, directors, officers, employees,
                      attorneys, agents, representatives, suppliers or
                      contractors arising out of or in connection with Site, the
                      Services, the
                      {siteSettings.siteName} Trading Solutions Ltd Materials,
                      any performance or non-performance of the services, or any
                      other product, service or other item provided by or on
                      behalf of Us, whether under contract, statute, strict
                      liability or other theory (including, for avoidance of
                      doubt, any negligence by Us) exceed the amount of the fees
                      paid by You to Us under this agreement in the twelve-month
                      period immediately preceding the event giving rise to the
                      claim for liability.
                    </span>
                  </div>
                </div>
              </div>
              <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                <b>DISPUTE RESOLUTION</b>
                <div className="TermsAndConditionsTemplate__List-sc-r7oimv-3 dNmWuD">
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <span>
                      With the exception only of disputes related to the
                      enforcement or validity of Our intellectual property
                      rights, all disputes, controversies or claims arising out
                      of or relating to these Terms or the Services, will be
                      resolved through confidential binding arbitration held in
                      the Republic of Seychelles, in accordance with the
                      applicable laws.
                    </span>
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <span>
                      You agree that any dispute arising out of or related to
                      these Terms or the Services is personal to You and Us and
                      that any dispute will be resolved solely through
                      individual arbitration. The arbitrator will have exclusive
                      authority to make all procedural and substantive decisions
                      regarding any dispute and to grant any remedy that would
                      otherwise be available in court. The arbitrator may only
                      conduct an individual arbitration and may not consolidate
                      more than one individual’s claims, preside over any type
                      of class or representative proceeding or preside over any
                      proceeding involving more than one individual.
                    </span>
                  </div>
                </div>
              </div>
              <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                <b>&nbsp;GOVERNING LAW</b>
                <div className="TermsAndConditionsTemplate__List-sc-r7oimv-3 dNmWuD">
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <span>
                      The interpretation and enforcement of these Terms, and any
                      dispute related to these Terms or the Services, will be
                      governed by and construed and enforced in accordance with
                      the laws of the Republic of Seychelles, without regard to
                      conflict of law rules or principles that would cause the
                      application of the laws of any other jurisdiction. You
                      agree that We may initiate a proceeding related to the
                      enforcement or validity of Our intellectual property
                      rights in any court having jurisdiction.
                    </span>
                  </div>
                </div>
              </div>
              <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                <b>&nbsp;OTHER TERMS</b>
                <div className="TermsAndConditionsTemplate__List-sc-r7oimv-3 dNmWuD">
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Duration: </b>This Agreement shall commence for a term
                    beginning on the day the User accepts the Terms of Service
                    set in this Agreement during the opening of the User Account
                    or User registration on the Website or through the mobile
                    application, with no foreseeable end date ("Term"), and can
                    be terminated as provided for in Section 13.
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Conflicts: </b>To the extent of any conflicts between the
                    English language Terms of Service, or any other
                    documentation or information published on the website, and
                    any translated version, the English language Terms of
                    Service or English documentation or information, shall
                    prevail.
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Remedies: </b>If You violate any of these Terms, We may,
                    as We determine reasonably necessary to remedy or mitigate
                    Your violation, delete all or part of such information
                    transmitted by You, suspend or cancel Your User Account, or
                    confiscate Cryptocurrencies owned by You without any prior
                    notice to You. We shall in no event be responsible or liable
                    for any damage incurred by the user as a result of an action
                    taken by Us pursuant to this paragraph. Any right or remedy
                    of {siteSettings.siteName} Trading Solutions Ltd set forth
                    in these Terms is in addition to, and not in lieu of, any
                    other right or remedy whether described in these Terms,
                    under statute, at law or in equity.
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Affiliates and Contractors:</b>An "Affiliate" is, with
                    respect to a legal entity, another legal entity that
                    controls, is under common control with, or is controlled by
                    the first legal entity. The Site and any Services may be
                    operated or provided by Us, Our Affiliates or their
                    respective contractors. To the extent that Our Affiliate, or
                    contractor is operating or providing any Services, the
                    Affiliate or contractor’s provision of such Services will be
                    under terms identical to these Terms substituting the
                    Affiliate or contractor’s name wherever{" "}
                    {siteSettings.siteName} Trading Solutions Ltd’s name occurs
                    in these Terms.
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Non-waiver: </b>Our failure or delay in exercising any
                    right, power, or privilege under these Terms shall not
                    operate as a waiver thereof.
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Severability: </b>The invalidity or unenforceability of
                    any of these Terms shall not affect the validity or
                    enforceability of any other of these Terms, all of which
                    shall remain in full force and effect.
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Force Majeure: </b>We will have no responsibility or
                    liability for any failure or delay in performance of any
                    Services, or any loss or damage that You may incur, due to
                    any circumstance or event beyond Our control, including
                    without limitation any flood, extraordinary weather
                    conditions, earthquake, or other act of God, fire, war,
                    insurrection, riot, labour dispute, accident, action of
                    government, communications, power failure, or equipment or
                    software malfunction.
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Assignment: </b>You may not assign or transfer any right
                    to use the Services or any of Your rights or obligations
                    under these Terms without prior written consent from Us,
                    including by operation of law or in connection with any
                    change of control. We may assign or transfer any or all of
                    its rights or obligations under these Terms, in whole or in
                    part, without notice or obtaining Your consent or approval.
                  </div>
                  <div className="TermsAndConditionsTemplate__ListItem-sc-r7oimv-4 htVuAl">
                    <b>Entire Agreement; Order of Precedence:</b>These Terms
                    contain the entire agreement and supersede all prior and
                    contemporaneous understandings between the parties regarding
                    the Services. In the event of any conflict between these
                    Terms and any other agreement You may have with Us, these
                    Terms will retain in control unless the other agreement
                    specifically identifies these Terms and declares that the
                    other agreement supersedes these Terms.
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* footer */}
          <Footer />
        </div>
      </div>
      <div
        id="gatsby-announcer"
        style={{
          position: "absolute",
          top: 0,
          width: 1,
          height: 1,
          padding: 0,
          overflow: "hidden",
          clip: "rect(0, 0, 0, 0)",
          whiteSpace: "nowrap",
          border: 0,
        }}
        aria-live="assertive"
        aria-atomic="true"
      />
      <div className="Toastify" />
      <div />
    </div>
  );
};

export default Terms;
