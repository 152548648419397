import { useEffect } from "react";
import "../styles/copy-trading/copy.css";
import Header from "../components/general/Header";
import Footer from "../components/general/Footer";
import { siteSettings } from "../static";

const Copy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="___gatsby">
      <div style={{ outline: "none" }} tabIndex={-1} id="gatsby-focus-wrapper">
        <div className="DefaultLayout__BackgroundContainer-sc-1h2qfsw-0 cxRxcP">
          {/* header */}
          <Header />

          {/* body */}
          <div className="CopytradingTemplate__Div-sc-9wp908-0 iFZJoe">
            <section className="Wrapper__SectionWrapper-sc-1wxi09o-0 HeadingWithContent__WrapperWrapper-sc-1bzmkwk-0 hXWeeJ chBcXg CopytradingTemplate__HeadingWithContentWrapper-sc-9wp908-1 xrteZ">
              <h2 className="Heading-sc-vfylp7-0 HeadingWithContent__HeadingWrapper-sc-1bzmkwk-5 HeadingWithContent__MobileHeading-sc-1bzmkwk-6 jJrhee kZvQRO dydNaG mobileHeading">
                Follow, copy, profit. Maximize gains, minimize effort.
              </h2>
              <div className="Container-sc-rx245t-0 HeadingWithContent__ContainerWrapper-sc-1bzmkwk-2 dGblwP BuOvK container">
                <div className="HeadingWithContent__HeadingContainer-sc-1bzmkwk-3 jQXiNv headingContainer">
                  <div className="HeadingWithContent__HeadingWrapperContainer-sc-1bzmkwk-1 jPTUrP headingWrapper">
                    <h1 className="Heading-sc-vfylp7-0 HeadingWithContent__HeadingWrapper-sc-1bzmkwk-5 jJrhee kZvQRO heading">
                      Follow, copy, profit. Maximize gains, minimize effort.
                    </h1>
                    <p className="Subheading-sc-t8ooc1-0 HeadingWithContent__SubheadingWrapper-sc-1bzmkwk-7 ihSfAf doVxii subheading">
                      Copy proven traders on autopilot in one-click
                    </p>
                    <div className="CopytradingTemplate__Buttons-sc-9wp908-12 ilvYBN">
                      <a
                        href={siteSettings.appLink}
                        className="ButtonLink-sc-125kju0-0 CopytradingTemplate__ButtonWrapper1-sc-9wp908-13 jppsNF iHOgEy"
                      >
                        Get Started
                      </a>
                      {/* <a
                        href={siteSettings.appLink}
                        className="ButtonLink-sc-125kju0-0 CopytradingTemplate__ButtonWrapper1-sc-9wp908-13 jppsNF iHOgEy"
                      >
                        <button
                          className="Button-sc-1ptre0p-0 Button2__ButtonWrapper-sc-ni84na-0 dbsBku fdlUSp Button3-sc-1a29epc-0 egWAop"
                          type="button"
                        >
                          Learn more
                        </button>
                      </a> */}
                    </div>
                  </div>
                </div>
                <div className="HeadingWithContent__ContentContainer-sc-1bzmkwk-4 hObqHr contentContainer">
                  <div className="SliderItems__SliderItemsContainer-sc-2rk6i6-0 eSDUCb CopytradingTemplate__SliderItemsWrapper-sc-9wp908-4 cfiCHL">
                    <div className="SliderItems__Slider-sc-2rk6i6-1 ddNoNP">
                      <h2 className="Heading-sc-vfylp7-0 SliderItems__HeadingWrapper-sc-2rk6i6-2 jJrhee bDlaNC">
                        Top 10 traders by ROE{/* */}{" "}
                      </h2>
                      <div className="SliderItems__ItemsWrapper-sc-2rk6i6-3 bVJRlO">
                        <button className="SliderItems__Button-sc-2rk6i6-9 SliderItems__ButtonLeft-sc-2rk6i6-10 bPTXZT cdPTth" />
                        <div className="SliderItems__Items-sc-2rk6i6-4 dVyqJo">
                          <div className="SliderItems__ItemWrapper-sc-2rk6i6-7 jHPllK">
                            <div
                              alt="skeleton"
                              className="CopytradingTemplate__InfoCardSkeleton-sc-9wp908-15 chPgJs"
                            />
                          </div>
                          <div className="SliderItems__ItemWrapper-sc-2rk6i6-7 jHPllK">
                            <div
                              alt="skeleton"
                              className="CopytradingTemplate__InfoCardSkeleton-sc-9wp908-15 chPgJs"
                            />
                          </div>
                          <div className="SliderItems__ItemWrapper-sc-2rk6i6-7 jHPllK">
                            <div
                              alt="skeleton"
                              className="CopytradingTemplate__InfoCardSkeleton-sc-9wp908-15 chPgJs"
                            />
                          </div>
                          <div className="SliderItems__ItemWrapper-sc-2rk6i6-7 jHPllK">
                            <div
                              alt="skeleton"
                              className="CopytradingTemplate__InfoCardSkeleton-sc-9wp908-15 chPgJs"
                            />
                          </div>
                          <div className="SliderItems__ItemWrapper-sc-2rk6i6-7 jHPllK">
                            <div
                              alt="skeleton"
                              className="CopytradingTemplate__InfoCardSkeleton-sc-9wp908-15 chPgJs"
                            />
                          </div>
                          <div className="SliderItems__ItemWrapper-sc-2rk6i6-7 jHPllK">
                            <div
                              alt="skeleton"
                              className="CopytradingTemplate__InfoCardSkeleton-sc-9wp908-15 chPgJs"
                            />
                          </div>
                          <div className="SliderItems__ItemWrapper-sc-2rk6i6-7 jHPllK">
                            <div
                              alt="skeleton"
                              className="CopytradingTemplate__InfoCardSkeleton-sc-9wp908-15 chPgJs"
                            />
                          </div>
                          <div className="SliderItems__ItemWrapper-sc-2rk6i6-7 jHPllK">
                            <div
                              alt="skeleton"
                              className="CopytradingTemplate__InfoCardSkeleton-sc-9wp908-15 chPgJs"
                            />
                          </div>
                          <div className="SliderItems__ItemWrapper-sc-2rk6i6-7 jHPllK">
                            <div
                              alt="skeleton"
                              className="CopytradingTemplate__InfoCardSkeleton-sc-9wp908-15 chPgJs"
                            />
                          </div>
                          <div className="SliderItems__ItemWrapper-sc-2rk6i6-7 jHPllK">
                            <div
                              alt="skeleton"
                              className="CopytradingTemplate__InfoCardSkeleton-sc-9wp908-15 chPgJs"
                            />
                          </div>
                        </div>
                        <div className="SliderItems__BoxShadowWrapper-sc-2rk6i6-5 gwzExk">
                          <div className="SliderItems__BoxShadow-sc-2rk6i6-6 gRQllu boxShadow" />
                        </div>
                        {/* <button className="SliderItems__Button-sc-2rk6i6-9 SliderItems__ButtonRight-sc-2rk6i6-11 dNwski" /> */}
                      </div>
                    </div>
                    <div className="Pagination__PaginationContainer-sc-1udlh6-0 eEXqUn" />
                  </div>
                </div>
              </div>
            </section>
            <div className="CopytradingTemplate__DiscoverTopTradersContainer-sc-9wp908-2 gQpDlb">
              <section className="Wrapper__SectionWrapper-sc-1wxi09o-0 Table__WrapperStyled-sc-14taqk8-0 hXWeeJ hHeroF">
                <div className="Container-sc-rx245t-0 dGblwP">
                  <h2 className="Heading-sc-vfylp7-0 Table__HeadingWrapper-sc-14taqk8-6 jJrhee kIzPtZ">
                    Discover Top Traders
                  </h2>
                  <div className="Table__SkeletonContainer-sc-14taqk8-1 isVkOV">
                    <img
                      src="static/tableSkeletonDesktop.c87e6034.svg"
                      className="CopytradingTemplate__TableSkeletonDesktop-sc-9wp908-16 kSEPmv"
                    />
                  </div>
                </div>
              </section>
            </div>
            <div className="Container-sc-rx245t-0 dGblwP">
              <div className="HowToCopyStepsBlock__HowToCopyStepsBlockWrapper-sc-119tcma-0 bCvLvM">
                <section className="HowToCopyStepsBlock__HowToCopyStepsBlockSection-sc-119tcma-1 ehlpAP">
                  <h2 className="SectionTitle__Title-sc-13r7aem-0 kaCYFQ">
                    How to Copy Trade on {siteSettings.siteName}
                  </h2>
                  <ol className="HowToCopyStepsBlock__List-sc-119tcma-2 eHUiVD">
                    <li className="HowToCopyStepsBlock__Step-sc-119tcma-4 eatDAG">
                      <div className="HowToCopyStepsBlock__Heading-sc-119tcma-6 gpxAit">
                        <h5 className="HowToCopyStepsBlock__HeadingTitle-sc-119tcma-5 bPuraP">
                          Deposit into your account
                        </h5>
                      </div>
                      <div
                        height={0}
                        className="HowToCopyStepsBlock__DescriptionContainer-sc-119tcma-7 hvXvul"
                      >
                        <div className="HowToCopyStepsBlock__Description-sc-119tcma-8 OsdmA">
                          Followers have to decide how much capital to allocate
                          to follow a trader. Carefully select the amount of
                          funds you want to follow the trader with, and enter it
                          into the copy trading platform
                        </div>
                      </div>
                    </li>
                    <div className="HowToCopyStepsBlock__StepWrapper-sc-119tcma-3 fMntbO">
                      <li className="HowToCopyStepsBlock__Step-sc-119tcma-4 eatDAG">
                        <div className="HowToCopyStepsBlock__Heading-sc-119tcma-6 cOVSCQ">
                          <h5 className="HowToCopyStepsBlock__HeadingTitle-sc-119tcma-5 bPuraP">
                            Choose a Trader
                          </h5>
                        </div>
                        <div
                          height={0}
                          className="HowToCopyStepsBlock__DescriptionContainer-sc-119tcma-7 hvXvul"
                        >
                          <div className="HowToCopyStepsBlock__Description-sc-119tcma-8 OsdmA">
                            Choosing the right trader for you involves a
                            thorough examination of each trader's track record
                            and all the data available on the{" "}
                            {siteSettings.siteName} copy trading platform. Be
                            sure to consider traders that meet your risk level
                            and profit goals. A good trader worth copying should
                            also demonstrate consistent results and a track
                            record of safe trading practices
                          </div>
                        </div>
                      </li>

                      <li className="HowToCopyStepsBlock__Step-sc-119tcma-4 eatDAG">
                        <div className="HowToCopyStepsBlock__Heading-sc-119tcma-6 gpxAit">
                          <h5 className="HowToCopyStepsBlock__HeadingTitle-sc-119tcma-5 bPuraP">
                            Click Follow
                          </h5>
                        </div>
                        <div
                          height={0}
                          className="HowToCopyStepsBlock__DescriptionContainer-sc-119tcma-7 hvXvul"
                        >
                          <div className="HowToCopyStepsBlock__Description-sc-119tcma-8 OsdmA">
                            This is the easy part. Simply click "Follow" and all
                            trades made by the selected trader will be
                            automatically copied using {siteSettings.siteName}'s
                            innovative copy trading software. Users can also
                            follow multiple traders at once for a diversified
                            copy trading portfolio that covers all bases.
                          </div>
                        </div>
                      </li>

                      <li className="HowToCopyStepsBlock__Step-sc-119tcma-4 eatDAG">
                        <div className="HowToCopyStepsBlock__Heading-sc-119tcma-6 gpxAit">
                          <h5 className="HowToCopyStepsBlock__HeadingTitle-sc-119tcma-5 bPuraP">
                            Confirm and Start Copying
                          </h5>
                        </div>
                        <div
                          height={0}
                          className="HowToCopyStepsBlock__DescriptionContainer-sc-119tcma-7 hvXvul"
                        >
                          <div className="HowToCopyStepsBlock__Description-sc-119tcma-8 OsdmA">
                            After reviewing all the information, click "Follow"
                            to complete the process and start reaping the
                            benefits of more experienced traders.
                          </div>
                        </div>
                      </li>
                      <a
                        href={siteSettings.appLink}
                        className="ButtonLink-sc-125kju0-0 CopytradingTemplate__ButtonWrapper1-sc-9wp908-13 CopytradingTemplate__ButtonWrapper3-sc-9wp908-14 jppsNF iHOgEy efFEvn"
                      >
                        Get Started
                      </a>
                    </div>
                    <div className="HowToCopyStepsBlock__ImageWrapper-sc-119tcma-9 hXcHic">
                      <img
                        src="static/howToCopySteps__trader-742c5ee5853a810b4ffd6779c3e6ec89.png"
                        alt="Choose a Trader"
                        className="HowToCopyStepsBlock__Image-sc-119tcma-10 iiTkwr"
                      />
                      <img
                        src="static/howToCopySteps__follow-d1bec743a37f29ffec1ca44f6e9a051b.png"
                        alt="Click Follow"
                        className="HowToCopyStepsBlock__Image-sc-119tcma-10 kubmsg"
                      />
                      <img
                        src="static/howToCopySteps__amount-82ccd6bb5a60b05bc4cbf0f9f67a4718.png"
                        alt="Choose an Amount"
                        className="HowToCopyStepsBlock__Image-sc-119tcma-10 kubmsg"
                      />
                      <img
                        src="static/howToCopySteps__enjoy-rewards-1c91485373d870b3a905cba78a69e4e2.png"
                        alt="Confirm and Start Copying"
                        className="HowToCopyStepsBlock__Image-sc-119tcma-10 kubmsg"
                      />
                    </div>
                  </ol>
                  <div className="HowToCopyStepsBlock__HowToCopyStepsBlockProgress-sc-119tcma-11 ecbJnS">
                    <div
                      style={{ width: "0%" }}
                      className="HowToCopyStepsBlock__HowToCopyStepsBlockProgressBar-sc-119tcma-12 eWDIWc"
                    />
                  </div>
                </section>
              </div>
            </div>
            <div className="Container-sc-rx245t-0 dGblwP">
              <h2 className="SectionTitle__Title-sc-13r7aem-0 kaCYFQ">
                Benefits of Copy Trading Crypto with {siteSettings.siteName}
              </h2>
              <div className="BenefitsBlock__BenefitsBlockWrapper-sc-d5v3p6-0 jbfwGk">
                <article className="BenefitsBlock__GridWrapper-sc-d5v3p6-1 kgcZKE">
                  <div className="BenefitsBlock__HeadingWrapper-sc-d5v3p6-2 cSCdje">
                    <h3 className="BenefitsBlock__Heading-sc-d5v3p6-3 caSGxZ">
                      Great for Beginners
                    </h3>
                    <p className="BenefitsBlock__Text-sc-d5v3p6-4 eeGfux">
                      If you don't want to get into technical analysis and chart
                      patterns, just let the more experienced and consistently
                      profitable traders do all the trading for you. At the same
                      time, experienced traders can analyze other top performing
                      traders and apply some of their techniques to their own
                      strategies.
                    </p>
                  </div>
                  <div className="BenefitsBlock__ImageBlock-sc-d5v3p6-5 cXZCcl">
                    <img
                      src="static/benefits__beginers-ea605e8a285346fd3e7afe293ef1bac4.png"
                      alt="Great for Beginners"
                      className="BenefitsBlock__Image-sc-d5v3p6-6 bddnvD"
                    />
                    <div className="Badge__BadgeContainer-sc-pa4zh9-0 kuFump BenefitsBlock__BadgeWrapper-sc-d5v3p6-7 kBnVIT">
                      <span className="Badge__Content-sc-pa4zh9-1 dotZyM content">
                        {siteSettings.siteName} Copy Trading
                      </span>
                    </div>
                  </div>
                </article>
                <article className="BenefitsBlock__GridWrapper-sc-d5v3p6-1 kgcZKE">
                  <div className="BenefitsBlock__HeadingWrapper-sc-d5v3p6-2 cSCdje">
                    <h3 className="BenefitsBlock__Heading-sc-d5v3p6-3 caSGxZ">
                      Benefit from the experience of others
                    </h3>
                    <p className="BenefitsBlock__Text-sc-d5v3p6-4 eeGfux">
                      Active trading requires a lot of time, nerves of steel,
                      discipline, patience and a great deal of experience in
                      losing capital to become profitable. When you use{" "}
                      {siteSettings.siteName}'s Copy Trading, you are using the
                      years experience of other traders and letting them do all
                      the hard work for you while you can concentrate on other
                      things or just watch their actions and learn something for
                      yourself.
                    </p>
                  </div>
                  <div className="BenefitsBlock__ImageBlock-sc-d5v3p6-5 cXZCcl">
                    <img
                      src="static/benefits__passively-952e155709be2ce0ad93af31aa314e26.png"
                      alt="Benefit from the experience of others"
                      className="BenefitsBlock__Image-sc-d5v3p6-6 bddnvD"
                    />
                    <div className="Badge__BadgeContainer-sc-pa4zh9-0 kuFump BenefitsBlock__BadgeWrapper-sc-d5v3p6-7 kBnVIT">
                      <span className="Badge__Content-sc-pa4zh9-1 dotZyM content">
                        Save Your Time
                      </span>
                    </div>
                  </div>
                </article>
                <article className="BenefitsBlock__GridWrapper-sc-d5v3p6-1 kgcZKE">
                  <div className="BenefitsBlock__HeadingWrapper-sc-d5v3p6-2 cSCdje">
                    <h3 className="BenefitsBlock__Heading-sc-d5v3p6-3 caSGxZ">
                      Remain In Control
                    </h3>
                    <p className="BenefitsBlock__Text-sc-d5v3p6-4 eeGfux">
                      While experienced managers do the hard work for you, you
                      still remain in full control. You decide which strategies
                      to follow, how much capital is at stake and what risk
                      management tools to apply.
                    </p>
                  </div>
                  <div className="BenefitsBlock__ImageBlock-sc-d5v3p6-5 cXZCcl">
                    <img
                      src="static/benefits__control-839dad1166ca5c8818787836085facc8.png"
                      alt="Remain In Control"
                      className="BenefitsBlock__Image-sc-d5v3p6-6 bddnvD"
                    />
                    <div className="Badge__BadgeContainer-sc-pa4zh9-0 kuFump BenefitsBlock__BadgeWrapper-sc-d5v3p6-7 kBnVIT">
                      <span className="Badge__Content-sc-pa4zh9-1 dotZyM content">
                        {siteSettings.siteName} Copy Trading
                      </span>
                    </div>
                  </div>
                </article>
                <article className="BenefitsBlock__GridWrapper-sc-d5v3p6-1 kgcZKE">
                  <div className="BenefitsBlock__HeadingWrapper-sc-d5v3p6-2 cSCdje">
                    <h3 className="BenefitsBlock__Heading-sc-d5v3p6-3 caSGxZ">
                      Perpetual markets with unlimited opportunities
                    </h3>
                    <p className="BenefitsBlock__Text-sc-d5v3p6-4 eeGfux">
                      Time in the market beats timing the market.
                      Cryptocurrencies are complex and potentially risky,
                      especially during periods of high volatility. While
                      everyone loses capital from market fluctuations, you can
                      gain by using the expertise of someone who knows how to
                      tame candlestick charts under any conditions.
                    </p>
                  </div>
                  <div className="BenefitsBlock__ImageBlock-sc-d5v3p6-5 cXZCcl">
                    <img
                      src="static/benefits__markets-7d99f48487ae5f6f78b26f0f45d4d753.png"
                      alt="Perpetual markets with unlimited opportunities"
                      className="BenefitsBlock__Image-sc-d5v3p6-6 bddnvD"
                    />
                    <div className="Badge__BadgeContainer-sc-pa4zh9-0 kuFump BenefitsBlock__BadgeWrapper-sc-d5v3p6-7 kBnVIT">
                      <span className="Badge__Content-sc-pa4zh9-1 dotZyM content">
                        Right place to go
                      </span>
                    </div>
                  </div>
                </article>
              </div>
            </div>
            <section className="Wrapper__SectionWrapper-sc-1wxi09o-0 hXWeeJ">
              <div className="Container-sc-rx245t-0 AdvantageBlock__ContainerWrapper-sc-pwvjs7-0 dGblwP daZCvI">
                <h2 className="SectionTitle__Title-sc-13r7aem-0 kaCYFQ">
                  Double Protection Technology
                </h2>
                <div className="AdvantageBlock__AdvantageBlockWrapper-sc-pwvjs7-1 bzZgNA">
                  <article className="AdvantageBlock__AdvantageWrapper-sc-pwvjs7-2 bqKbym">
                    <div className="AdvantageBlock__TextWrapper-sc-pwvjs7-3 iwYvJp">
                      <h3 className="AdvantageBlock__AdvantageTitle-sc-pwvjs7-4 daeRGI">
                        MP Shield System
                      </h3>
                      <div className="AdvantageBlock__AdvantageDescriptionContainer-sc-pwvjs7-5 dAMcfI">
                        <p className="AdvantageBlock__AdvantageDescription-sc-pwvjs7-6 bNcxeg">
                          The cryptocurrency market is volatile and still
                          relatively new. The consequences of price manipulation
                          have been seen repeatedly.
                        </p>
                        <p className="AdvantageBlock__AdvantageDescription-sc-pwvjs7-6 bNcxeg">
                          To counter this, we have developed MP Shield, which
                          protects our clients from the downsides of price
                          manipulation, which often leads to unfair liquidation
                          of user funds on other platforms.
                        </p>
                      </div>
                    </div>
                    <img
                      src="static/advantage__shield-f75adfb9771ef4f94e7f7b78f60b8e46.png"
                      className="AdvantageBlock__AdvantageImage-sc-pwvjs7-7 dKyKYu"
                    />
                  </article>
                  <article className="AdvantageBlock__AdvantageWrapper-sc-pwvjs7-2 eawnBh">
                    <div className="AdvantageBlock__TextWrapper-sc-pwvjs7-3 iwYvJp">
                      <h3 className="AdvantageBlock__AdvantageTitle-sc-pwvjs7-4 daeRGI">
                        Smart follow
                      </h3>
                      <div className="AdvantageBlock__AdvantageDescriptionContainer-sc-pwvjs7-5 dAMcfI">
                        <p className="AdvantageBlock__AdvantageDescription-sc-pwvjs7-6 bNcxeg">
                          Does 0.1 second matter? In cryptocurrency trading,
                          yes. That's why we developed another unique technology
                          - Smart follow, which protects followers and provides
                          them with the same trading conditions as the trader.
                        </p>
                        <p className="AdvantageBlock__AdvantageDescription-sc-pwvjs7-6 bNcxeg">
                          Positions for all followers of a strategy are managed
                          at the same moment and when a position is profitable,
                          it is profitable for all followers. Thanks to Smart
                          follow technology.
                        </p>
                      </div>
                    </div>
                    <img
                      src="static/advantage__diamond-00d895df2cf7bf6fce93d1e6be93ec81.png"
                      className="AdvantageBlock__AdvantageImage-sc-pwvjs7-7 dKyKYu"
                    />
                  </article>
                </div>
              </div>
            </section>
            {/* <div className="Container-sc-rx245t-0 dGblwP">
              <div className="CopytradingTemplate__Wrapper-sc-9wp908-3 bRpHQQ">
                <h2 className="SectionTitle__Title-sc-13r7aem-0 eQNVre">
                  Frequently Asked Questions
                </h2>
                <ul className="Ul-sc-1f2qrkb-0 FaqBlockFaqList__FAQList-sc-1nm1j8i-0 dmohgA ffgZkX">
                  <li className="FaqBlockFaqList__FAQItem-sc-1nm1j8i-1 dDEKGW">
                    <div className="FaqBlockFaqList__TextContainer-sc-1nm1j8i-2 kwUqw">
                      <div className="FaqBlockFaqList__TitleContainer-sc-1nm1j8i-3 hLeixX">
                        <div className="FaqBlockFaqList__Title-sc-1nm1j8i-4 kETCqR">
                          What is crypto copy trading?
                        </div>
                        <div className="FaqBlockFaqList__IconContainer-sc-1nm1j8i-5 hhRnlw">
                          <svg
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g filter="url(#filter0_b_821_8970)">
                              <circle
                                r={24}
                                transform="matrix(1 0 0 -1 24 24)"
                                fill="#3071EE"
                                fillOpacity="0.1"
                              ></circle>
                              <circle
                                r={23}
                                transform="matrix(1 0 0 -1 24 24)"
                                stroke="#3071EE"
                                strokeWidth={2}
                              ></circle>
                            </g>
                            <path
                              d="M25.5 34C25.5 34.8284 24.8284 35.5 24 35.5C23.1716 35.5 22.5 34.8284 22.5 34H25.5ZM22.9393 12.9393C23.5251 12.3536 24.4749 12.3536 25.0607 12.9393L34.6066 22.4853C35.1924 23.0711 35.1924 24.0208 34.6066 24.6066C34.0208 25.1924 33.0711 25.1924 32.4853 24.6066L24 16.1213L15.5147 24.6066C14.9289 25.1924 13.9792 25.1924 13.3934 24.6066C12.8076 24.0208 12.8076 23.0711 13.3934 22.4853L22.9393 12.9393ZM22.5 34V14H25.5V34H22.5Z"
                              fill="#3071EE"
                            />
                            <defs>
                              <filter
                                id="filter0_b_821_8970"
                                x={-40}
                                y={-40}
                                width={128}
                                height={128}
                                filterUnits="userSpaceOnUse"
                                colorInterpolationFilters="sRGB"
                              >
                                <feFlood
                                  floodOpacity={0}
                                  result="BackgroundImageFix"
                                />
                                <feGaussianBlur
                                  in="BackgroundImage"
                                  stdDeviation={20}
                                />
                                <feComposite
                                  in2="SourceAlpha"
                                  operator="in"
                                  result="effect1_backgroundBlur_821_8970"
                                ></feComposite>
                                <feBlend
                                  mode="normal"
                                  in="SourceGraphic"
                                  in2="effect1_backgroundBlur_821_8970"
                                  result="shape"
                                />
                              </filter>
                            </defs>
                          </svg>
                        </div>
                      </div>
                      <div
                        height={0}
                        className="FaqBlockFaqList__DescriptionContainer-sc-1nm1j8i-6 tJjUZ"
                      >
                        <div className="FaqBlockFaqList__Description-sc-1nm1j8i-7 bIPIpE">
                          Crypto copy trading is a revolutionary new trading
                          feature that connects users of all levels with
                          experienced traders and allows them to copy the trades
                          of those successful traders. Although the feature is
                          relatively new, copy trading is becoming increasingly
                          popular on cryptocurrency trading platforms.
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="FaqBlockFaqList__FAQItem-sc-1nm1j8i-1 dDEKGW">
                    <div className="FaqBlockFaqList__TextContainer-sc-1nm1j8i-2 kwUqw">
                      <div className="FaqBlockFaqList__TitleContainer-sc-1nm1j8i-3 hLeixX">
                        <div className="FaqBlockFaqList__Title-sc-1nm1j8i-4 kETCqR">
                          Is copy trading profitable?
                        </div>
                        <div className="FaqBlockFaqList__IconContainer-sc-1nm1j8i-5 hhRnlw">
                          <svg
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g filter="url(#filter0_b_821_8970)">
                              <circle
                                r={24}
                                transform="matrix(1 0 0 -1 24 24)"
                                fill="#3071EE"
                                fillOpacity="0.1"
                              ></circle>
                              <circle
                                r={23}
                                transform="matrix(1 0 0 -1 24 24)"
                                stroke="#3071EE"
                                strokeWidth={2}
                              ></circle>
                            </g>
                            <path
                              d="M25.5 34C25.5 34.8284 24.8284 35.5 24 35.5C23.1716 35.5 22.5 34.8284 22.5 34H25.5ZM22.9393 12.9393C23.5251 12.3536 24.4749 12.3536 25.0607 12.9393L34.6066 22.4853C35.1924 23.0711 35.1924 24.0208 34.6066 24.6066C34.0208 25.1924 33.0711 25.1924 32.4853 24.6066L24 16.1213L15.5147 24.6066C14.9289 25.1924 13.9792 25.1924 13.3934 24.6066C12.8076 24.0208 12.8076 23.0711 13.3934 22.4853L22.9393 12.9393ZM22.5 34V14H25.5V34H22.5Z"
                              fill="#3071EE"
                            />
                            <defs>
                              <filter
                                id="filter0_b_821_8970"
                                x={-40}
                                y={-40}
                                width={128}
                                height={128}
                                filterUnits="userSpaceOnUse"
                                colorInterpolationFilters="sRGB"
                              >
                                <feFlood
                                  floodOpacity={0}
                                  result="BackgroundImageFix"
                                />
                                <feGaussianBlur
                                  in="BackgroundImage"
                                  stdDeviation={20}
                                />
                                <feComposite
                                  in2="SourceAlpha"
                                  operator="in"
                                  result="effect1_backgroundBlur_821_8970"
                                ></feComposite>
                                <feBlend
                                  mode="normal"
                                  in="SourceGraphic"
                                  in2="effect1_backgroundBlur_821_8970"
                                  result="shape"
                                />
                              </filter>
                            </defs>
                          </svg>
                        </div>
                      </div>
                      <div
                        height={0}
                        className="FaqBlockFaqList__DescriptionContainer-sc-1nm1j8i-6 tJjUZ"
                      >
                        <div className="FaqBlockFaqList__Description-sc-1nm1j8i-7 bIPIpE">
                          Cryptocurrency copy trading is only as profitable as
                          the trader who is chosen to be followed. If that
                          trader is profitable, then copying his trades will
                          provide a return to anyone who joins his fund. Copy
                          traders on {siteSettings.siteName} regularly show higher than average
                          performance.
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="FaqBlockFaqList__FAQItem-sc-1nm1j8i-1 dDEKGW">
                    <div className="FaqBlockFaqList__TextContainer-sc-1nm1j8i-2 kwUqw">
                      <div className="FaqBlockFaqList__TitleContainer-sc-1nm1j8i-3 hLeixX">
                        <div className="FaqBlockFaqList__Title-sc-1nm1j8i-4 kETCqR">
                          Is copy trading in crypto safe?{" "}
                        </div>
                        <div className="FaqBlockFaqList__IconContainer-sc-1nm1j8i-5 hhRnlw">
                          <svg
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g filter="url(#filter0_b_821_8970)">
                              <circle
                                r={24}
                                transform="matrix(1 0 0 -1 24 24)"
                                fill="#3071EE"
                                fillOpacity="0.1"
                              ></circle>
                              <circle
                                r={23}
                                transform="matrix(1 0 0 -1 24 24)"
                                stroke="#3071EE"
                                strokeWidth={2}
                              ></circle>
                            </g>
                            <path
                              d="M25.5 34C25.5 34.8284 24.8284 35.5 24 35.5C23.1716 35.5 22.5 34.8284 22.5 34H25.5ZM22.9393 12.9393C23.5251 12.3536 24.4749 12.3536 25.0607 12.9393L34.6066 22.4853C35.1924 23.0711 35.1924 24.0208 34.6066 24.6066C34.0208 25.1924 33.0711 25.1924 32.4853 24.6066L24 16.1213L15.5147 24.6066C14.9289 25.1924 13.9792 25.1924 13.3934 24.6066C12.8076 24.0208 12.8076 23.0711 13.3934 22.4853L22.9393 12.9393ZM22.5 34V14H25.5V34H22.5Z"
                              fill="#3071EE"
                            />
                            <defs>
                              <filter
                                id="filter0_b_821_8970"
                                x={-40}
                                y={-40}
                                width={128}
                                height={128}
                                filterUnits="userSpaceOnUse"
                                colorInterpolationFilters="sRGB"
                              >
                                <feFlood
                                  floodOpacity={0}
                                  result="BackgroundImageFix"
                                />
                                <feGaussianBlur
                                  in="BackgroundImage"
                                  stdDeviation={20}
                                />
                                <feComposite
                                  in2="SourceAlpha"
                                  operator="in"
                                  result="effect1_backgroundBlur_821_8970"
                                ></feComposite>
                                <feBlend
                                  mode="normal"
                                  in="SourceGraphic"
                                  in2="effect1_backgroundBlur_821_8970"
                                  result="shape"
                                />
                              </filter>
                            </defs>
                          </svg>
                        </div>
                      </div>
                      <div
                        height={0}
                        className="FaqBlockFaqList__DescriptionContainer-sc-1nm1j8i-6 tJjUZ"
                      >
                        <div className="FaqBlockFaqList__Description-sc-1nm1j8i-7 bIPIpE">
                          Copy trading is not risk-free, as cryptocurrencies are
                          volatile assets. Fund managers are given access to
                          risk management tools to prevent maximum drawdowns and
                          significant losses.
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="FaqBlockFaqList__FAQItem-sc-1nm1j8i-1 dDEKGW">
                    <div className="FaqBlockFaqList__TextContainer-sc-1nm1j8i-2 kwUqw">
                      <div className="FaqBlockFaqList__TitleContainer-sc-1nm1j8i-3 hLeixX">
                        <div className="FaqBlockFaqList__Title-sc-1nm1j8i-4 kETCqR">
                          Is copy trading a good choice for beginners?
                        </div>
                        <div className="FaqBlockFaqList__IconContainer-sc-1nm1j8i-5 hhRnlw">
                          <svg
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g filter="url(#filter0_b_821_8970)">
                              <circle
                                r={24}
                                transform="matrix(1 0 0 -1 24 24)"
                                fill="#3071EE"
                                fillOpacity="0.1"
                              ></circle>
                              <circle
                                r={23}
                                transform="matrix(1 0 0 -1 24 24)"
                                stroke="#3071EE"
                                strokeWidth={2}
                              ></circle>
                            </g>
                            <path
                              d="M25.5 34C25.5 34.8284 24.8284 35.5 24 35.5C23.1716 35.5 22.5 34.8284 22.5 34H25.5ZM22.9393 12.9393C23.5251 12.3536 24.4749 12.3536 25.0607 12.9393L34.6066 22.4853C35.1924 23.0711 35.1924 24.0208 34.6066 24.6066C34.0208 25.1924 33.0711 25.1924 32.4853 24.6066L24 16.1213L15.5147 24.6066C14.9289 25.1924 13.9792 25.1924 13.3934 24.6066C12.8076 24.0208 12.8076 23.0711 13.3934 22.4853L22.9393 12.9393ZM22.5 34V14H25.5V34H22.5Z"
                              fill="#3071EE"
                            />
                            <defs>
                              <filter
                                id="filter0_b_821_8970"
                                x={-40}
                                y={-40}
                                width={128}
                                height={128}
                                filterUnits="userSpaceOnUse"
                                colorInterpolationFilters="sRGB"
                              >
                                <feFlood
                                  floodOpacity={0}
                                  result="BackgroundImageFix"
                                />
                                <feGaussianBlur
                                  in="BackgroundImage"
                                  stdDeviation={20}
                                />
                                <feComposite
                                  in2="SourceAlpha"
                                  operator="in"
                                  result="effect1_backgroundBlur_821_8970"
                                ></feComposite>
                                <feBlend
                                  mode="normal"
                                  in="SourceGraphic"
                                  in2="effect1_backgroundBlur_821_8970"
                                  result="shape"
                                />
                              </filter>
                            </defs>
                          </svg>
                        </div>
                      </div>
                      <div
                        height={0}
                        className="FaqBlockFaqList__DescriptionContainer-sc-1nm1j8i-6 tJjUZ"
                      >
                        <div className="FaqBlockFaqList__Description-sc-1nm1j8i-7 bIPIpE">
                          Copy trading is a great way for newbies to gain access
                          to industry leaders while learning from the traders
                          they copy. Copy trading can also lead to steady
                          capital growth, which can give beginners an early
                          start to success.
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="FaqBlockFaqList__FAQItem-sc-1nm1j8i-1 dDEKGW">
                    <div className="FaqBlockFaqList__TextContainer-sc-1nm1j8i-2 kwUqw">
                      <div className="FaqBlockFaqList__TitleContainer-sc-1nm1j8i-3 hLeixX">
                        <div className="FaqBlockFaqList__Title-sc-1nm1j8i-4 kETCqR">
                          Is copy trading free?
                        </div>
                        <div className="FaqBlockFaqList__IconContainer-sc-1nm1j8i-5 hhRnlw">
                          <svg
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g filter="url(#filter0_b_821_8970)">
                              <circle
                                r={24}
                                transform="matrix(1 0 0 -1 24 24)"
                                fill="#3071EE"
                                fillOpacity="0.1"
                              ></circle>
                              <circle
                                r={23}
                                transform="matrix(1 0 0 -1 24 24)"
                                stroke="#3071EE"
                                strokeWidth={2}
                              ></circle>
                            </g>
                            <path
                              d="M25.5 34C25.5 34.8284 24.8284 35.5 24 35.5C23.1716 35.5 22.5 34.8284 22.5 34H25.5ZM22.9393 12.9393C23.5251 12.3536 24.4749 12.3536 25.0607 12.9393L34.6066 22.4853C35.1924 23.0711 35.1924 24.0208 34.6066 24.6066C34.0208 25.1924 33.0711 25.1924 32.4853 24.6066L24 16.1213L15.5147 24.6066C14.9289 25.1924 13.9792 25.1924 13.3934 24.6066C12.8076 24.0208 12.8076 23.0711 13.3934 22.4853L22.9393 12.9393ZM22.5 34V14H25.5V34H22.5Z"
                              fill="#3071EE"
                            />
                            <defs>
                              <filter
                                id="filter0_b_821_8970"
                                x={-40}
                                y={-40}
                                width={128}
                                height={128}
                                filterUnits="userSpaceOnUse"
                                colorInterpolationFilters="sRGB"
                              >
                                <feFlood
                                  floodOpacity={0}
                                  result="BackgroundImageFix"
                                />
                                <feGaussianBlur
                                  in="BackgroundImage"
                                  stdDeviation={20}
                                />
                                <feComposite
                                  in2="SourceAlpha"
                                  operator="in"
                                  result="effect1_backgroundBlur_821_8970"
                                ></feComposite>
                                <feBlend
                                  mode="normal"
                                  in="SourceGraphic"
                                  in2="effect1_backgroundBlur_821_8970"
                                  result="shape"
                                />
                              </filter>
                            </defs>
                          </svg>
                        </div>
                      </div>
                      <div
                        height={0}
                        className="FaqBlockFaqList__DescriptionContainer-sc-1nm1j8i-6 tJjUZ"
                      >
                        <div className="FaqBlockFaqList__Description-sc-1nm1j8i-7 bIPIpE">
                          Trade copy services are free. However, the
                          profit-sharing model splits the revenue from the
                          copied trades between the manager, the follower and
                          the crypto copy trading platform in the form of a
                          commission.
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="FaqBlockFaqList__FAQItem-sc-1nm1j8i-1 dDEKGW">
                    <div className="FaqBlockFaqList__TextContainer-sc-1nm1j8i-2 kwUqw">
                      <div className="FaqBlockFaqList__TitleContainer-sc-1nm1j8i-3 hLeixX">
                        <div className="FaqBlockFaqList__Title-sc-1nm1j8i-4 kETCqR">
                          How does crypto copy trading work?
                        </div>
                        <div className="FaqBlockFaqList__IconContainer-sc-1nm1j8i-5 hhRnlw">
                          <svg
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g filter="url(#filter0_b_821_8970)">
                              <circle
                                r={24}
                                transform="matrix(1 0 0 -1 24 24)"
                                fill="#3071EE"
                                fillOpacity="0.1"
                              ></circle>
                              <circle
                                r={23}
                                transform="matrix(1 0 0 -1 24 24)"
                                stroke="#3071EE"
                                strokeWidth={2}
                              ></circle>
                            </g>
                            <path
                              d="M25.5 34C25.5 34.8284 24.8284 35.5 24 35.5C23.1716 35.5 22.5 34.8284 22.5 34H25.5ZM22.9393 12.9393C23.5251 12.3536 24.4749 12.3536 25.0607 12.9393L34.6066 22.4853C35.1924 23.0711 35.1924 24.0208 34.6066 24.6066C34.0208 25.1924 33.0711 25.1924 32.4853 24.6066L24 16.1213L15.5147 24.6066C14.9289 25.1924 13.9792 25.1924 13.3934 24.6066C12.8076 24.0208 12.8076 23.0711 13.3934 22.4853L22.9393 12.9393ZM22.5 34V14H25.5V34H22.5Z"
                              fill="#3071EE"
                            />
                            <defs>
                              <filter
                                id="filter0_b_821_8970"
                                x={-40}
                                y={-40}
                                width={128}
                                height={128}
                                filterUnits="userSpaceOnUse"
                                colorInterpolationFilters="sRGB"
                              >
                                <feFlood
                                  floodOpacity={0}
                                  result="BackgroundImageFix"
                                />
                                <feGaussianBlur
                                  in="BackgroundImage"
                                  stdDeviation={20}
                                />
                                <feComposite
                                  in2="SourceAlpha"
                                  operator="in"
                                  result="effect1_backgroundBlur_821_8970"
                                ></feComposite>
                                <feBlend
                                  mode="normal"
                                  in="SourceGraphic"
                                  in2="effect1_backgroundBlur_821_8970"
                                  result="shape"
                                />
                              </filter>
                            </defs>
                          </svg>
                        </div>
                      </div>
                      <div
                        height={0}
                        className="FaqBlockFaqList__DescriptionContainer-sc-1nm1j8i-6 tJjUZ"
                      >
                        <div className="FaqBlockFaqList__Description-sc-1nm1j8i-7 bIPIpE">
                          Copy trading crypto works by connecting users and copy
                          traders together using a synergistic software solution
                          provided by the {siteSettings.siteName} trading platform. One has to
                          select a fund manager, allocate their capital to the
                          trader and confirm to start copying trades.
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="FaqBlockFaqList__FAQItem-sc-1nm1j8i-1 dDEKGW">
                    <div className="FaqBlockFaqList__TextContainer-sc-1nm1j8i-2 kwUqw">
                      <div className="FaqBlockFaqList__TitleContainer-sc-1nm1j8i-3 hLeixX">
                        <div className="FaqBlockFaqList__Title-sc-1nm1j8i-4 kETCqR">
                          What is the best crypto copy trading platform?
                        </div>
                        <div className="FaqBlockFaqList__IconContainer-sc-1nm1j8i-5 hhRnlw">
                          <svg
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g filter="url(#filter0_b_821_8970)">
                              <circle
                                r={24}
                                transform="matrix(1 0 0 -1 24 24)"
                                fill="#3071EE"
                                fillOpacity="0.1"
                              ></circle>
                              <circle
                                r={23}
                                transform="matrix(1 0 0 -1 24 24)"
                                stroke="#3071EE"
                                strokeWidth={2}
                              ></circle>
                            </g>
                            <path
                              d="M25.5 34C25.5 34.8284 24.8284 35.5 24 35.5C23.1716 35.5 22.5 34.8284 22.5 34H25.5ZM22.9393 12.9393C23.5251 12.3536 24.4749 12.3536 25.0607 12.9393L34.6066 22.4853C35.1924 23.0711 35.1924 24.0208 34.6066 24.6066C34.0208 25.1924 33.0711 25.1924 32.4853 24.6066L24 16.1213L15.5147 24.6066C14.9289 25.1924 13.9792 25.1924 13.3934 24.6066C12.8076 24.0208 12.8076 23.0711 13.3934 22.4853L22.9393 12.9393ZM22.5 34V14H25.5V34H22.5Z"
                              fill="#3071EE"
                            />
                            <defs>
                              <filter
                                id="filter0_b_821_8970"
                                x={-40}
                                y={-40}
                                width={128}
                                height={128}
                                filterUnits="userSpaceOnUse"
                                colorInterpolationFilters="sRGB"
                              >
                                <feFlood
                                  floodOpacity={0}
                                  result="BackgroundImageFix"
                                />
                                <feGaussianBlur
                                  in="BackgroundImage"
                                  stdDeviation={20}
                                />
                                <feComposite
                                  in2="SourceAlpha"
                                  operator="in"
                                  result="effect1_backgroundBlur_821_8970"
                                ></feComposite>
                                <feBlend
                                  mode="normal"
                                  in="SourceGraphic"
                                  in2="effect1_backgroundBlur_821_8970"
                                  result="shape"
                                />
                              </filter>
                            </defs>
                          </svg>
                        </div>
                      </div>
                      <div
                        height={0}
                        className="FaqBlockFaqList__DescriptionContainer-sc-1nm1j8i-6 tJjUZ"
                      >
                        <div className="FaqBlockFaqList__Description-sc-1nm1j8i-7 bIPIpE">
                          There are several cryptocurrency trading platforms
                          that are trusted by the cryptocurrency community, with
                          {siteSettings.siteName} being one of them, offering advanced trading
                          tools, staking and other unique features.
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>

          {/* footer */}
          <Footer />
        </div>
      </div>
      <div
        id="gatsby-announcer"
        style={{
          position: "absolute",
          top: 0,
          width: 1,
          height: 1,
          padding: 0,
          overflow: "hidden",
          clip: "rect(0, 0, 0, 0)",
          whiteSpace: "nowrap",
          border: 0,
        }}
        aria-live="assertive"
        aria-atomic="true"
      />
      <div className="Toastify" />
      <div />
    </div>
  );
};

export default Copy;
