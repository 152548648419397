import { useEffect } from "react";
import "../../styles/bottom/staking-terms.css";
import Header from "../../components/general/Header";
import Footer from "../../components/general/Footer";
import { siteSettings } from "../../static";

const StakingTerms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="___gatsby">
      <div style={{ outline: "none" }} tabIndex={-1} id="gatsby-focus-wrapper">
        <div className="DefaultLayout__BackgroundContainer-sc-1h2qfsw-0 bzHVOS">
          {/* header */}
          <Header />

          {/* body */}
          <div className="Container-sc-rx245t-0 TermsAndStakingTemplate__StyledContainer-sc-ojora4-1 dGblwP acxIL">
            <h1 className="TermsAndStakingTemplate__Title-sc-ojora4-2 bqWWTy">
              Staking Terms of Use
            </h1>
            <strong className="TermsAndStakingTemplate__Strong-sc-ojora4-0 eoTTaq">
              PLEASE READ THESE TERMS OF USE CAREFULLY AS THEY CONTAIN RISK
              NOTICE, ACKNOWLEDGEMENT AND ACCEPTANCE, NO WAIVER AND NO CLASS
              ACTION CLAUSE, WHICH AFFECT YOUR RIGHTS. IF YOU DO NOT AGREE TO
              THESE TERMS OF USE
              <br />
              <br />
            </strong>
            <strong className="TermsAndStakingTemplate__Strong-sc-ojora4-0 eoTTaq">
              IMPORTANT NOTICE PRIOR TO ACCESSING OR USING THE SERVICES, YOU
              (THE CLIENT) MUST INFORM YOURSELF OF AND OBSERVE ANY REGULATIONS
              APPLICABLE TO YOU. ACCESSING OR USING THE SERVICES MAY BE
              RESTRICTED BY LAW IN CERTAIN JURISDICTIONS AND ACTIVITIES
              UNDERTAKEN THROUGH THE SERVICES MAY BE SUBJECT TO SPECIFIC
              REGULATIONS OR REVIEW BY CERTAIN AUTHORITIES. A FAILURE TO COMPLY
              THEREWITH MAY RESULT IN SANCTIONS AS PROVIDED FOR IN THE CONCERNED
              REGULATIONS.
              <br />
              <br />
            </strong>
            <strong className="TermsAndStakingTemplate__Strong-sc-ojora4-0 eoTTaq">
              These Terms of Use are considered to be fully and unconditionally
              accepted by the User (as defined below) once he/she registers
              and/or enters the Staking (as defined below) and/or while using of
              the Services (as defined below).
            </strong>
            <p className="TermsAndStakingTemplate__Paragraph-sc-ojora4-4 bGZfrz">
              Staking cryptocurrencies, including by accessing or using this
              Platform and the Services, as defined below, has potential rewards
              and risks involved. Transacting in cryptocurrencies, digital
              assets and DeFi assets, in general and including in Applicable
              Digital Assets, as well as Staking carries high level of risk and
              is not suitable for all people. Anyone wishing to access and use
              the Services, as defined below, should seek their own independent
              professional legal, financial and tax advice.
            </p>
            <h2 className="TermsAndStakingTemplate__H2-sc-ojora4-3 dxCNYa">
              Preamble
            </h2>
            <p className="TermsAndStakingTemplate__Paragraph-sc-ojora4-4 bGZfrz">
              These terms of use are entered into by and between you and the
              {siteSettings.siteName} Trading Solutions LTD and any of its
              affiliates ("{siteSettings.siteName}", "we", "us" or "our"). The
              following terms of use, together with any documents expressly
              incorporated herein by reference (collectively, the "Terms of
              Use"), govern your access including any content, functionality,
              and services offered on or through
              {siteSettings.siteName}.com (the "Platform"), hereinafter referred
              to as a "Service" or, collectively, as the "Services".
            </p>
            <p className="TermsAndStakingTemplate__Paragraph-sc-ojora4-4 bGZfrz">
              Staking Accounts may be made available by {siteSettings.siteName}{" "}
              to the Users that fulfill the eligibility criteria specified in
              the General Terms and Conditions which can be found at{" "}
              <a href="/terms">{`Terms and Conditions`}</a>
            </p>
            <div className="TermsAndStakingTemplate__List-sc-ojora4-5 gBTVAB">
              <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                <b>Definitions</b>
                <div className="TermsAndStakingTemplate__List-sc-ojora4-5 gBTVAB">
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    "Applicable Digital Assets" shall mean digital assets issued
                    and operated on the Networks, advertisement of which is
                    shown on the Platform;
                  </div>
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    Staking or to Stake means committing Cryptocurrency holdings
                    via the Staking Accounts to third-parties’ programs of
                    decentralized finance (DeFi) platforms and/or centralized
                    (CeFi) platforms where Cryptocurrency Staking takes place on
                    behalf of the User through Staking Accounts Services and
                    under these Terms of Use;
                  </div>
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    Staking Rewards means rewards received as a result of
                    Staking;
                  </div>
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    Staking Protocols are used to generate Staking Rewards and
                    operated by separate third-party decentralized (DeFi) and
                    centralized (CeFi) finance platforms;
                  </div>
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    "Personal Address" or, collectively, "Personal Addresses"
                    shall mean the address or, collectively, addresses on the
                    Networks;
                  </div>
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    "Third-Party" or, collectively, "Third-Parties" shall mean
                    any other natural person who is not a User;
                  </div>
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    “User” shall mean a natural person who has already signed up
                    for Staking Accounts on the Platform. A User is allowed to
                    use all the Services of the Platform.
                  </div>
                </div>
              </div>
              <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                <b>Acceptance and modifications of the terms of use</b>
                <div className="TermsAndStakingTemplate__List-sc-ojora4-5 gBTVAB">
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    Modifications of the Terms of Use. We may revise and update
                    the Terms of Use without notice from time to time in our
                    sole discretion with all changes being effective immediately
                    when we post them. Should any change be made to the Terms of
                    Use, by continuing to use the Platform and/or the Services
                    after such change is made, with or without having explicitly
                    accepted the revised or otherwise changed Terms of Use, you
                    are agreeing to be bound by the current version of the Terms
                    of Use.
                  </div>
                </div>
              </div>
              <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                <b>General</b>
                <div className="TermsAndStakingTemplate__List-sc-ojora4-5 gBTVAB">
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    Staking Protocols are operated by separate third-party
                    decentralized (DeFi) and centralized (CeFi) finance
                    platforms and {siteSettings.siteName} has no control over
                    such platforms.
                  </div>
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    Any instructions in relation to Your use of the Staking
                    Accounts are transmitted by {siteSettings.siteName} to one
                    of the Staking Protocols by virtue of its position as a
                    technology provider.
                  </div>
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    {siteSettings.siteName} only acts as a platform allowing
                    access to a variety of third-party Staking Protocols and
                    provide Users with related Services, such as accessing funds
                    on behalf of such Users, providing information about Staking
                    Rewards, and distributing earnings.
                  </div>
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    While using the Services, the Users receive a corresponding
                    portion of the Staking Rewards generated by the relevant
                    Staking Protocol, provided however that{" "}
                    {siteSettings.siteName} receives another portion of such
                    Staking Rewards for providing the Services (to be determined
                    by {siteSettings.siteName} at its sole discretion). You
                    agree and understand that {siteSettings.siteName} does not
                    guarantee that You will receive the Staking Rewards and that
                    the applicable percentage of the Staking Rewards (i) is an
                    estimate only and not guaranteed, (ii) may change at any
                    time in {siteSettings.siteName}’s sole discretion, and (iii)
                    may be more or less than the actual Staking Rewards{" "}
                    {siteSettings.siteName} receives from the Staking Protocols.
                  </div>
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    The User hereby grants Us the right to the fullest extent
                    that We may choose any Staking Protocol at Our own
                    discretion in order to generate Staking Rewards.
                  </div>
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    The time frame for withdrawing your cryptocurrencies from
                    your Stakings Accounts is dependent on third party
                    providers. {siteSettings.siteName} reserves the right to
                    make such withdrawals within 3 business days of receiving a
                    withdrawal request from the User.
                  </div>
                </div>
              </div>
              <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                <b>Risk notice, acknowledgement and acceptance</b>
                <div className="TermsAndStakingTemplate__List-sc-ojora4-5 gBTVAB">
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    You hereby acknowledge that you have carefully assessed your
                    financial situation and risk tolerance, and as a
                    consequence, you have conducted any additional research, or
                    asked advice, as you may have deemed necessary after reading
                    the foregoing Disclaimer. You agree to assume responsibility
                    for any of the risks listed in this Section 4 and any other
                    risks, assumptions and decisions involved in using Staking
                    Protocols and Cryptocurrency. You warrant and accept that
                    you understand these risks.
                  </div>
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    Market risk of digital assets. Market risk is a very
                    pronounced risk factor in digital assets. You hereby agree
                    that you understand, assume, acknowledge and accept your
                    exposure to all of the below listed Market risks of digital
                    assets, including Applicable Digital Assets:
                  </div>
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    High volatility. The market value of digital assets is often
                    volatile. Some of the reasons for the volatility are the
                    small market capitalisation compared to traditional capital
                    markets, the risk of sudden regulatory changes, trend-cycles
                    or the performance of the market for traditional
                    investments.
                  </div>
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    Emerging market. The market for digital assets, including
                    markets for DeFi and Staking, is still in an emerging and
                    maturing phase. Investment in these markets are often deemed
                    riskier than in long standing and more mature markets. The
                    market for certain Applicable Digital Assets may be yet to
                    exist. Hence you may find it difficult or impossible to
                    liquidate or redeem your Applicable Digital Assets.
                  </div>
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    Regulatory risks. The legal framework surrounding digital
                    assets is still uncertain in many countries and so far,
                    digital assets have seen to be regulated differently across
                    countries. This non-uniform treatment and new, potential
                    legal measures exposes Applicable Digital Assets and our
                    Users to the risks of non-compliance with law, risks of
                    change in the methodology of Applicable Digital Assets and
                    transactions with such assets being classified differently
                    leading to delisting of an Applicable Digital asset by an
                    exchange, DeFi or Staking, and restricted trade- and
                    transferability which ultimately affect the value of the
                    Applicable Digital Assets and can lead to penalties or
                    fines.
                  </div>
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    Enforceability of Smart contracts. A smart contract is an
                    interactive blockchain-native agreement based on code (the
                    "Smart contract"). The interaction of Users with the
                    Services is mostly performed via Smart contracts.
                  </div>
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    Market abuse. As outlined above, the market for digital
                    assets is still emerging and subject to a varying or
                    non-existing regulation. As such, not all market
                    participants, including the Issue offer the safeguards of
                    traditional markets to prevent market abuse (i.e. fraud,
                    market manipulation or insider trading). Such market abuse
                    may lead to depreciation of the market value of Applicable
                    Digital Assets and cryptocurrencies resulting in partial or
                    total loss of your cryptocurrency funds and Applicable
                    Digital Assets.
                  </div>
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    Market risk is constant. Digital asset exchanges, whether
                    centralised or decentralised, are often open around the
                    clock seven days a week. This means that the digital assets,
                    including Applicable Digital Assets, are subject to a
                    constant market risk as trading never halts.
                  </div>
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    Credit and Liquidity risks. Credit and Liquidity risks are
                    often connected to difficulties with the determination of,
                    and assigning the liabilities to the Issuer of an Applicable
                    Digital asset (the "Issuer"), including for purposes of an
                    Applicable Digital asset redemption, either by redeeming it
                    directly for cash with the Issuer or by selling it to a
                    Third-Party, and including with the determination of, and
                    assigning the liabilities to the Issuer by Staking Accounts,
                    hence making it illiquid and lack of legal supervision of
                    the Issuers. You hereby agree that you understand, assume,
                    acknowledge and accept your exposure to all of the above
                    mentioned Credit and Liquidity risks.
                  </div>
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    Counterparty risk. Your Staking Account is not a checking or
                    savings account, and it is not covered by insurance against
                    losses. We will pledge, repledge, hypothecate,
                    rehypothecate, sell, lend, or otherwise transfer or use the
                    digital assets in your Staking Account to counterparties,
                    and such digital assets will be exposed to various risks as
                    a result of such transactions. In certain jurisdictions,
                    digital assets are not legal tender, and are not backed by
                    the government or any regulatory authority. Your Staking
                    Account is not registered with any federal or state
                    securities regulatory authority. Legislative and regulatory
                    changes or actions at the state, federal, or international
                    level may adversely affect the use, transfer, exchange, and
                    value of digital assets. You acknowledge that by utilizing
                    the Staking Account service, your digital assets are not
                    custodied by {siteSettings.siteName} and may be subject to
                    total loss if an event occurs at the protocol level, which
                    is outside of
                    {siteSettings.siteName}’ control. You understand and agree
                    that notwithstanding {siteSettings.siteName} will not be
                    responsible for any such loss (whether total or otherwise)
                    of your digital assets and will not replace them or
                    otherwise compensate you. You hereby agree that you
                    understand, assume, acknowledge and accept your exposure to
                    all of the above mentioned Counterparty risks.
                  </div>
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    Technical risks. The technological design of the Networks,
                    Applicable Digital Assets, DeFi and Staking comes with a
                    variety of different risks, including, without limitation
                    those listed below. You hereby agree that you understand,
                    assume, acknowledge and accept your exposure to all of the
                    below listed Technical risks:
                  </div>
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    Hardfork. A Hardfork describes an event which splits a new
                    blockchain from the original one by modifying the source
                    code. Usually a modification or update of the source code is
                    accepted by all participants. Where there is a disagreement
                    between such participants of a single network, the network
                    infrastructure can be divided into two groups using two
                    different blockchains. Particularly, in case of Hardfork of
                    any of the Networks, Applicable Digital Assets registered on
                    the Network may be both credited on both the original
                    Network and new network. In the event of a Hardfork, there
                    may be significant price fluctuations resulting in a
                    temporary suspension of Trading of Cryptocurrencies by
                    Staking Accounts and loss of access to digital assets,
                    including Applicable Digital Assets.
                  </div>
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    Storage of digital assets. Access to the blockchain networks
                    is generally maintained through a public and a private key
                    to an address (the "Keys"). Access to the blockchain network
                    and therefore to the digital asset is impossible if the Keys
                    are lost. Theft, loss, or destruction of the Keys, as well
                    as hacking of underlying software and technology
                    infrastructure, or other reasons for the private keys stored
                    by {siteSettings.siteName} to become not recognisable any
                    more can result in you not being able to access and/or use
                    the Applicable Digital Assets on your Personal address.
                  </div>
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    Transactional risk. Transactions on any of the Networks
                    available on the Platform are sent to an address determined
                    by the public key and Network choice. If a wrong public key
                    and/or Network is used, it will be impossible to identify
                    the recipient and to reverse the transaction made to an
                    address with a wrong public key or using wrong Network
                    leading to the total loss of control over transacted
                    cryptocurrency and/or Applicable Digital Assets used in such
                    transaction. There may also be delays in the execution of
                    transactions as the transfer of Applicable Digital Assets
                    may become subject to verification, legal suits, actions and
                    proceedings, as well as other processes involving
                    Third-Parties.
                  </div>
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    Open source software. Digital Assets are based on
                    open-source software (i.e. Networks) that is freely
                    accessible and may be copied, used or modified at any time.
                    There is thus an increased risk of bugs and vulnerabilities,
                    and also deliberately embedded malfunctions. The
                    discontinuation of such open-source software is always
                    possible and might expose digital assets, including
                    Applicable Digital Assets, to vulnerabilities, programming
                    errors and threats from fraud, theft and attacks, as well as
                    lead to inaccessibility of Personal Addresses and digital
                    assets, including Applicable Digital Assets stored on such
                    Personal Addresses.
                  </div>
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    Bugs in smart contracts. Smart contracts, including without
                    limitation those created by {siteSettings.siteName} and
                    those created by our licensors and service providers can be
                    faulty, vulnerable and/or bugged. For example, in a Swap
                    feature, funds can be exchanged without the need of a
                    middleman/intermediary other than the autonomous smart
                    contract. However, since the smart contract relies solely on
                    the underlying code, the interaction with the smart contract
                    cannot be ensured to be bug-free until we, our licensors or
                    service providers have them audited by a Third-Party
                    professional technical audit company. For the User, this
                    means that the Counterparty risk is the risk of the
                    underlying code of the smart contract which can be difficult
                    to assess. Furthermore, transactions to the smart contract
                    cannot be reversed or rectified even if proven to be faulty.
                  </div>
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    Hacking. A decentralised consensus is necessary to validate
                    transactions and the validation requires computing capacity.
                    Therefore, it is possible for a participant with significant
                    computing capacity to effectively centralise the consensus
                    and hence manipulate it. For example, making it possible to
                    verify or process a false transaction for its own benefit at
                    the loss of others. The risk of such a majority attack
                    decreases with the increased computing capacity dedicated to
                    validating. Due to the mathematical foundation of the crypto
                    technology, there are also various other forms of attacks,
                    like collision attacks, dusting attacks, Sybil attacks,
                    denial-of-service attacks and distributed denial-of-service
                    attacks, or censorship attacks.
                  </div>
                </div>
              </div>
              <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                <b>Limitation of liability</b> The Terms of Use set out the full
                extent of our obligations and liabilities with respect to the
                Platform and the Services. To the maximum extent possible by
                law, Staking Accounts excludes all and any warranty, guaranty
                and responsibility in relation to or subsequent to the Platform,
                Platform Content, the Services, Personal addresses and
                Applicable Digital Assets. Staking Accounts shall in particular
                not be liable for any damages of any kind, including loss of
                income or data, suffered by the Visitor, the User or any other
                person, by act of Staking Accounts, its licensors, service
                providers or of a Third-Party. This includes any misuse that
                would be made of the User’s data, any virus or other forms of
                malware transmitted through the Platform or the server, as well
                as any non-compliance of the Users and/or the Visitors with the
                Terms of Use. Staking Accounts shall have no liability for any
                personal injury, lost profits or other consequential, special,
                punitive, indirect, or incidental damages, arising from or
                related to your use or inability to use or access the Platform.
                notwithstanding anything to the contrary in the Terms of Use,
                nothing in the Terms of Use excludes or limits our liability for
                fraud, or for death, or personal injury caused by our gross
                negligence. The foregoing disclaimer of certain damages and
                limitation of liability will apply to the maximum extent
                permitted by applicable law. The laws of some jurisdictions or
                states do not allow the exclusion or limitation of certain
                damages, so some or all of the exclusions and limitations set
                forth above may not apply to you. In no event shall the
                aggregate liability of Staking Accounts arising out of or
                relating to the use of or inability to use the Platform and/or
                the Services exceed one hundred United States Dollars (USD100).
                You agree that any claim filed more than one (1) month after the
                discovery of the alleged liability of Staking Accounts shall be
                time-barred.
              </div>
              <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                <b>Indemnification</b> You agree to defend, indemnify and hold
                harmless Staking Accounts, its licensors and service providers,
                and its and their respective officers, directors, employees,
                contractors, agents, licensors, suppliers, successors and
                assigns from and against any claims, liabilities, damages,
                judgments, awards, losses, costs, expenses or fees including
                reasonable attorneys' fees arising out of or relating to your
                breach of the Terms of Use or your use of the Platform and the
                Services, including, but not limited to, any use of the Platform
                Content, services and products other than as expressly
                authorised in the Terms of Use or your use of any information
                obtained from the Platform and/or Services and always subject to
                your acceptance of the risks mentioned in Section 4.
              </div>
              <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                <b>Electronic communications</b>
                <div className="TermsAndStakingTemplate__List-sc-ojora4-5 gBTVAB">
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    When you use the Personal Addresses, and/or the Services, or
                    send emails to {siteSettings.siteName}, you are
                    communicating with us electronically. When sending us
                    emails, you need to use your correct email address.{" "}
                    {siteSettings.siteName} is not responsible or liable for any
                    wrong or misspelled email address or inaccurate or wrong
                    Personal data submitted to the Company.
                  </div>
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    By using the Staking Accounts, Personal Addresses, and/or
                    the Services, you agree to receive electronically any
                    communications related to your use of the Staking Account,
                    Personal Addresses, and/or the Services. We will communicate
                    with you by email, in particular, sending emails to the
                    email derived from the information provided by you signing
                    up to the Platform. You agree that all agreements, notices,
                    disclosures, and other communications that are provided to
                    you electronically satisfy any legal requirement that such
                    communications be in writing. All notices from us intended
                    for receipt by You shall be deemed delivered and effective
                    when sent.
                  </div>
                </div>
              </div>
              <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                <b>Network fees</b> You may be charged by your Network provider
                for data services, gas to perform the transaction or any other
                Third-Party charges as may arise while using the Platform,
                Personal Addresses, and any of the Services, and you accept
                responsibility for such charges.
              </div>
              <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                <b>Taxes</b> Trading of Cryptocurrencies may be subject to
                various taxes, including, without limitation, Capital Gain tax
                and Income tax. You are solely responsible for any taxes arising
                from Trading of Cryptocurrencies and/or using the Services. It
                is your responsibility to correctly collect and report taxes to
                your tax authorities in your country of residence.{" "}
                {siteSettings.siteName} is not responsible for taxes arising
                from Trading of Cryptocurrencies and/or using the Services.
              </div>
              <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                <b>Waiver and Severability</b>
                <div className="TermsAndStakingTemplate__List-sc-ojora4-5 gBTVAB">
                  <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                    <div className="TermsAndStakingTemplate__List-sc-ojora4-5 gBTVAB">
                      <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                        Waiver. {siteSettings.siteName}’ failure to enforce a
                        provision of the Terms of Use does not constitute a
                        waiver of its right to do so in the future with respect
                        to that provision, any other provision, or the Terms of
                        Use as a whole. User irrevocably waives any right User
                        may have to serve as a representative or as a private
                        attorney general, or to participate as a member of a
                        class of claimants, in any suit, action, arbitration or
                        other proceeding against {siteSettings.siteName}, its
                        licensors and service providers, arising from, related
                        to or connected with the Terms of Use or any policies
                        available on the Platform.
                      </div>
                      <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                        Severability. If any provision of the Terms of Use is
                        held by a court or other tribunal of competent
                        jurisdiction to be invalid, illegal or unenforceable for
                        any reason, only such provision shall be eliminated or
                        limited to the minimum extent such that the remaining
                        provisions of the Terms of Use will continue in full
                        force and effect.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                <b>Entire Agreement</b> The Terms of Use constitute the sole and
                entire agreement between you and {siteSettings.siteName} with
                respect to the Platform and the Services, and supersede all
                prior and contemporaneous understandings, agreements,
                representations and warranties, both written and oral, with
                respect to the Platform.
              </div>
              <div className="TermsAndStakingTemplate__ListItem-sc-ojora4-6 xaUwr">
                Any notice, demand or request required or permitted to be given
                under this Agreement shall be in writing and shall be deemed
                sufficient when sent by email to{" "}
                <a
                  href={`mailto:${siteSettings.supportEmail}`}
                  className="__cf_email__"
                  data-cfemail="d3a0a6a3a3bca1a793beb2a1b4b6abfdb0bcbe"
                >
                  {siteSettings.supportEmail}
                </a>
                . Notice will be effective on receipt.
              </div>
            </div>
          </div>

          {/* footer */}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default StakingTerms;
