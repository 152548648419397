import { useEffect } from "react";
import "../../styles/bottom/cookie.css";
import Header from "../../components/general/Header";
import Footer from "../../components/general/Footer";
import { siteSettings } from "../../static";

const Cookie = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="___gatsby">
      <div style={{ outline: "none" }} tabIndex={-1} id="gatsby-focus-wrapper">
        <div className="DefaultLayout__BackgroundContainer-sc-1h2qfsw-0 bzHVOS">
          {/* header */}
          <Header />

          {/* body */}
          <div className="Container-sc-rx245t-0 CookiePolicyTemplate__StyledContainer-sc-74ppbi-0 dGblwP iVqgiM">
            <h1 className="CookiePolicyTemplate__Title-sc-74ppbi-1 ffwGym">
              Cookie Policy
            </h1>
            <p className="CookiePolicyTemplate__Paragrapgh-sc-74ppbi-2 jnckgG">
              This is the Cookie Policy for the {siteSettings.siteName} website,
              accessible from
              {/* */}{" "}
              <a
                href={siteSettings.siteLink}
                className="CookiePolicyTemplate__Link-sc-74ppbi-6 guhYus"
              >
                {siteSettings.siteCropLink}
              </a>
            </p>
            <h4 className="CookiePolicyTemplate__ParagrapghTitle-sc-74ppbi-3 jCxtVi">
              What Are Cookies
            </h4>
            <p className="CookiePolicyTemplate__Paragrapgh-sc-74ppbi-2 jnckgG">
              1.1. As is common practice with almost all professional websites
              this site uses cookies, which are tiny files that are downloaded
              to your computer, to improve your experience. This page describes
              what information they gather, how we use it and why we sometimes
              need to store these cookies. We will also share how you can
              prevent these cookies from being stored however this may downgrade
              or 'break' certain elements of the sites functionality.
            </p>
            <p className="CookiePolicyTemplate__Paragrapgh-sc-74ppbi-2 jnckgG">
              1.2. For more general information on cookies, please read the
              Wikipedia article{/* */}{" "}
              <a
                href="https://en.wikipedia.org/wiki/HTTP_cookie"
                className="CookiePolicyTemplate__Link-sc-74ppbi-6 guhYus"
              >
                https://en.wikipedia.org/wiki/HTTP_cookie
              </a>
              .
            </p>
            <h4 className="CookiePolicyTemplate__ParagrapghTitle-sc-74ppbi-3 jCxtVi">
              How We Use Cookies
            </h4>
            <p className="CookiePolicyTemplate__Paragrapgh-sc-74ppbi-2 jnckgG">
              2.1. We use cookies for a variety of reasons detailed below.
              Unfortunately in most cases there are no industry standard options
              for disabling cookies without completely disabling the
              functionality and features they add to this site. It is
              recommended that you leave on all cookies if you are not sure
              whether you need them or not in case they are used to provide a
              service that you use.
            </p>
            <h4 className="CookiePolicyTemplate__ParagrapghTitle-sc-74ppbi-3 jCxtVi">
              Disabling Cookies
            </h4>
            <p className="CookiePolicyTemplate__Paragrapgh-sc-74ppbi-2 jnckgG">
              3.1. You can prevent the setting of cookies by adjusting the
              settings on your browser (see your browser Help for how to do
              this). Be aware that disabling cookies will affect the
              functionality of this and many other websites that you visit.
              Disabling cookies will usually result in also disabling certain
              functionality and features of the this site. Therefore it is
              recommended that you do not disable cookies.
            </p>
            <h4 className="CookiePolicyTemplate__ParagrapghTitle-sc-74ppbi-3 jCxtVi">
              Cookies We Set
            </h4>
            <ul className="CookiePolicyTemplate__List-sc-74ppbi-4 dKKdOH">
              <li className="CookiePolicyTemplate__ListItem-sc-74ppbi-5 dfSAZF">
                Account related cookies
                <br />
                if you create an account with us then we will use cookies for
                the management of the signup process and general administration.
                These cookies will usually be deleted when you log out however
                in some cases they may remain afterwards to remember your site
                preferences when logged out.
              </li>
              <li className="CookiePolicyTemplate__ListItem-sc-74ppbi-5 dfSAZF">
                Login related cookies
                <br />
                we use cookies when you are logged in so that we can remember
                this fact. This prevents you from having to log in every single
                time you visit a new page. These cookies are typically removed
                or cleared when you log out to ensure that you can only access
                restricted features and areas when logged in.
              </li>
              <li className="CookiePolicyTemplate__ListItem-sc-74ppbi-5 dfSAZF">
                Email newsletters related cookies
                <br />
                this site offers newsletter or email subscription services and
                cookies may be used to remember if you are already registered
                and whether to show certain notifications which might only be
                valid to subscribed/unsubscribed users.
              </li>
              <li className="CookiePolicyTemplate__ListItem-sc-74ppbi-5 dfSAZF">
                Orders processing related cookies
                <br />
                this site offers e-commerce or payment facilities and some
                cookies are essential to ensure that your order is remembered
                between pages so that we can process it properly.
              </li>
              <li className="CookiePolicyTemplate__ListItem-sc-74ppbi-5 dfSAZF">
                Surveys related cookies
                <br />
                from time to time we offer user surveys and questionnaires to
                provide you with interesting insights, helpful tools, or to
                understand our user base more accurately. These surveys may use
                cookies to remember who has already taken part in a survey or to
                provide you with accurate results after you change pages.
              </li>
              <li className="CookiePolicyTemplate__ListItem-sc-74ppbi-5 dfSAZF">
                Forms related cookies
                <br />
                when you submit data through a form such as those found on
                contact pages or comment forms cookies may be set to remember
                your user details for future correspondence.
              </li>
              <li className="CookiePolicyTemplate__ListItem-sc-74ppbi-5 dfSAZF">
                Site preferences cookies
                <br />
                in order to provide you with a great experience on this site we
                provide the functionality to set your preferences for how this
                site runs when you use it. In order to remember your preferences
                we need to set cookies so that this information can be called
                whenever you interact with a page that is affected by your
                preferences.
              </li>
            </ul>
            <h4 className="CookiePolicyTemplate__ParagrapghTitle-sc-74ppbi-3 jCxtVi">
              Third Party Cookies
            </h4>
            <p className="CookiePolicyTemplate__Paragrapgh-sc-74ppbi-2 jnckgG">
              5.1. In some special cases we also use cookies provided by trusted
              third parties. The following section details which third party
              cookies you might encounter through this site.
            </p>
            <ul className="CookiePolicyTemplate__List-sc-74ppbi-4 dKKdOH">
              <li className="CookiePolicyTemplate__ListItem-sc-74ppbi-5 dfSAZF">
                This site uses Google Analytics which is one of the most
                widespread and trusted analytics solutions on the web for
                helping us to understand how you use the site and ways that we
                can improve your experience. These cookies may track things such
                as how long you spend on the site and the pages that you visit
                so we can continue to produce engaging content. for more
                information on Google Analytics cookies, see the official Google
                Analytics page.
              </li>
              <li className="CookiePolicyTemplate__ListItem-sc-74ppbi-5 dfSAZF">
                Third party analytics are used to track and measure usage of
                this site so that we can continue to produce engaging content.
                These cookies may track things such as how long you spend on the
                site or pages you visit which helps us to understand how we can
                improve the site for you.
              </li>
              <li className="CookiePolicyTemplate__ListItem-sc-74ppbi-5 dfSAZF">
                From time to time we test new features and make subtle changes
                to the way that the site is delivered. When we are still testing
                new features these cookies may be used to ensure that you
                receive a consistent experience whilst on the site whilst
                ensuring we understand which optimisations our users appreciate
                the most.
              </li>
              <li className="CookiePolicyTemplate__ListItem-sc-74ppbi-5 dfSAZF">
                As we sell products it's important for us to understand
                statistics about how many of the visitors to our site actually
                make a purchase and as such this is the kind of data that these
                cookies will track. This is important to you as it means that we
                can accurately make business predictions that allow us to
                monitor our advertising and product costs to ensure the best
                possible price.
              </li>
              <li className="CookiePolicyTemplate__ListItem-sc-74ppbi-5 dfSAZF">
                We use adverts to offset the costs of running this site and
                provide funding for further development. The behavioural
                advertising cookies used by this site are designed to ensure
                that we provide you with the most relevant adverts where
                possible by anonymously tracking your interests and presenting
                similar things that may be of interest.
              </li>
              <li className="CookiePolicyTemplate__ListItem-sc-74ppbi-5 dfSAZF">
                Several partners advertise on our behalf and affiliate tracking
                cookies simply allow us to see if our customers have come to the
                site through one of our partner sites so that we can credit them
                appropriately and where applicable allow our affiliate partners
                to provide any bonus that they may provide you for making a
                purchase.
              </li>
            </ul>
            <h4 className="CookiePolicyTemplate__ParagrapghTitle-sc-74ppbi-3 jCxtVi">
              More Information
            </h4>
            <p className="CookiePolicyTemplate__Paragrapgh-sc-74ppbi-2 jnckgG">
              6.1. Hopefully that has clarified things for you and as was
              previously mentioned if there is something that you aren't sure
              whether you need or not it's usually safer to leave cookies
              enabled in case it does interact with one of the features you use
              on our site.
            </p>
            <p className="CookiePolicyTemplate__Paragrapgh-sc-74ppbi-2 jnckgG">
              To find out more about cookies visit this link{/* */}{" "}
              <a
                href="https://en.wikipedia.org/wiki/HTTP_cookie"
                className="CookiePolicyTemplate__Link-sc-74ppbi-6 guhYus"
              >
                https://en.wikipedia.org/wiki/HTTP_cookie
              </a>
            </p>
            <p className="CookiePolicyTemplate__Paragrapgh-sc-74ppbi-2 jnckgG">
              However if you are still looking for more information then you can
              contact us through email:{/* */}{" "}
              <a
                href={`mailto:${siteSettings.supportEmail}`}
                className="CookiePolicyTemplate__Link-sc-74ppbi-6 guhYus"
              >
                <span
                  className="__cf_email__"
                  data-cfemail="87f4f2f7f7e8f5f3c7eae6f5e0e2ffa9e4e8ea"
                >
                  {siteSettings.supportEmail}
                </span>
              </a>
            </p>
          </div>

          {/* footer */}
          <Footer />
        </div>
      </div>
      <div
        id="gatsby-announcer"
        style={{
          position: "absolute",
          top: 0,
          width: 1,
          height: 1,
          padding: 0,
          overflow: "hidden",
          clip: "rect(0, 0, 0, 0)",
          whiteSpace: "nowrap",
          border: 0,
        }}
        aria-live="assertive"
        aria-atomic="true"
      />
      <div className="Toastify" />
      <div />
    </div>
  );
};

export default Cookie;
