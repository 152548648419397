import { useEffect } from "react";
import "../../styles/bottom/copy-terms.css";
import Header from "../../components/general/Header";
import Footer from "../../components/general/Footer";
import { siteSettings } from "../../static";

const CopyTerms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="___gatsby">
      <div style={{ outline: "none" }} tabIndex={-1} id="gatsby-focus-wrapper">
        <div className="DefaultLayout__BackgroundContainer-sc-1h2qfsw-0 bzHVOS">
          {/* header */}
          <Header />

          {/* body */}
          <div className="Container-sc-rx245t-0 CopyTradingTermsTemplate__StyledContainer-sc-1a7dxnp-0 dGblwP iNrMS">
            <h1 className="CopyTradingTermsTemplate__Title-sc-1a7dxnp-1 jNnisH">
              Copy Trading Terms of Use
            </h1>
            <ol className="CopyTradingTermsTemplate__List-sc-1a7dxnp-2 ftA-dIq">
              <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                <b>General</b>
                <ol className="CopyTradingTermsTemplate__List-sc-1a7dxnp-2 ftA-dIq">
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    These Terms and Conditions define the conditions under which
                    {siteSettings.siteName} Trading Solutions Ltd. (hereinafter
                    also referred to as the "Company") provides the Copytrading
                    Service to its users (hereinafter, “Clients”).
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    The purpose of the Copytrading Service is to connect the
                    accounts of the Followers with the account of the Trader in
                    order to enable further transactions of the Trader on the
                    financial markets in the interest of the Followers.
                    Copytrading connects the accounts of the Followers into a
                    single trading account.
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    Copytrading offers the possibility to follow the Strategies
                    of Traders, who manage their own funds through their
                    Copytrading Strategy Account. Followers, voluntarily and at
                    their own risk select the Strategy(s) they want to follow
                    according to a Strategy’s Offer. All decisions to accept or
                    reject the Offer are a personal matter of each potential
                    Follower and are made without any recommendation or
                    solicitation by the Company.
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    The Trader is an agent of the Follower with respect to the
                    Copytrading account and does not provide trust management of
                    the Follower‘s funds.
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    Only the Trader may perform trades on the Copytrading
                    Account.{" "}
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    The opening and closing of the accounts as well as the
                    non-trading operations including the deposit/withdrawal of
                    funds to/from the account can be performed only by the
                    Follower.
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    All calculations on both Copytrading Accounts and accounts,
                    as well as transfer of all types of remuneration to the
                    Trader and Copytrading Partners (hereinafter “Partners”) are
                    performed by the Company.
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    The terms and conditions regulating the trading operations
                    of the Trader as well as the non-trading operations of the
                    Trader and the Followers are specified in these Regulations.
                  </li>
                </ol>
              </li>
              <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                <b>Definitions and Terms</b>
                <ol className="CopyTradingTermsTemplate__List-sc-1a7dxnp-2 ftA-dIq">
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    Agreement - the present Agreement, including any and all
                    annexes and/or appendices attached herein which may,
                    periodically be amended or replaced from time to time
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    Company - {siteSettings.siteName} Trading Solutions Ltd
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    Client - a legal or natural person accepted by the Company
                    as a user to whom services will be provided by the Company
                    in accordance with this agreement
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    Strategy or Strategy Account - a Strategy Account created by
                    a Trader. Each Strategy will have an Offer and a unique
                    name. The Trader can make their Strategy public and
                    available for following by Followers
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    Trader - Copytrading user who has created a Strategy Account
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    Follower - Copytrading user who was Followed one or more
                    Strategy
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    Offer - the potential profit distribution conditions
                    proposed by the Trader that will take effect when starting
                    the following of their Strategy
                  </li>
                </ol>
              </li>
              <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                <b>Copytrading account requirements</b>
                <ol className="CopyTradingTermsTemplate__List-sc-1a7dxnp-2 ftA-dIq">
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    As a guarantee of protecting the interests of the Followers,
                    the Trader must be a Follower in their own Copytrading
                    account. This is done through the mandatory contribution of
                    Trader's Capital into the Strategy Account. When registering
                    a Copytrading account, the Trader's Capital is transferred
                    to the Trader's Strategy Account
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    The balance of the Trader's account equal to or lower than
                    the Trader's Capital can be withdrawn only when the
                    Copytrading Account is closed.
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    The trader can only increase the trader's capital and not
                    decrease it.
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    When creating a Copytrading account and transferring the
                    trader's capital to the account, a trader must specify
                    whether their account will be private or public. Public
                    Copytrading accounts appear in the full list of accounts on
                    the Company website and their monitoring is visible to all.
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    The name and description of a Copytrading Account must not
                    contain any of the following: obscene language or any
                    elements that are offensive in nature, including racial,
                    religious, nationalistic and ethnic slurs; guarantees of
                    profits or other promises; direct or indirect references to
                    competing companies or their advertisements; the names of
                    government officials and agencies; References to gambling or
                    financial pyramid schemes; any hyperlinks; and the names of
                    other manager's copy trading accounts or copy trading
                    portfolios if such reference clearly misleads Followers as
                    to which manager owns the copy trading account or copy
                    trading portfolio. The following elements may be used: any
                    elements that do not violate the above restrictions. The
                    Company reserves the right to change the name of Copytrading
                    accounts at its sole discretion and without prior notice.
                  </li>
                </ol>
              </li>
              <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                <b>Trader’s Offer</b>
                <ol className="CopyTradingTermsTemplate__List-sc-1a7dxnp-2 ftA-dIq">
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    The Trader's Offer (hereinafter referred to as the “Offer”)
                    refers to the Trader's offer to potential Followers to open
                    accounts within the Trader's Copytrading Account.
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    The Offer consists of a set of parameters that the Company
                    uses to calculate the Trader's Remuneration, and also
                    defines other conditions for opening accounts within the
                    Copytrading Account.
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    The Follower’s acceptance of the Offer does not bind the
                    Trader and the Follower with contractual obligations and
                    under no circumstances leads to the conclusion of a
                    contract.
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    The Company allows Traders to create Offers from one to five
                    different levels with parameters which outline the
                    conditions for following.
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    The Company reserves the right to block an a Copytrading
                    account under the following circumstances:
                    <ol
                      type="a"
                      className="CopyTradingTermsTemplate__List-sc-1a7dxnp-2 dyXoSY"
                    >
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        when fraudulent activity is suspected;
                      </li>
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        The use of a username or Copytrading Account name which
                        contains obscene language or any elements that are
                        insulting in nature, including racial, religious,
                        nationalistic and ethnic slurs
                      </li>
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        other reasons (including without prior warning or
                        explanation, at the Company’s own discretion)
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                <b>Rollover of the Copytrading account</b>
                <ol className="CopyTradingTermsTemplate__List-sc-1a7dxnp-2 ftA-dIq">
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    Copytrading account Rollover (hereinafter "Rollover") takes
                    place on all Copytrading accounts every 5 minutes server
                    time. Additional Rollovers are set by Copytrading Strategy
                    account Traders;
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    A Rollover can be either Open or Closed:
                    <ol
                      type="a"
                      className="CopyTradingTermsTemplate__List-sc-1a7dxnp-2 dyXoSY"
                    >
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        An open Rollover is used for collecting statistics and
                        updating information about the Copytrading account and
                        Follower's accounts, and for executing deposit requests
                        into a Strategy;
                      </li>
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        A closed Rollover is used for collecting statistics and
                        updating information about the Copytrading account and
                        the Followers’ accounts, as well as executing withdrawal
                        requests from a Strategy;
                      </li>
                    </ol>
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    A Copytrading account must have at least one closed Rollover
                    per day
                  </li>
                </ol>
              </li>
              <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                <b>Trading interval</b>
                <ol className="CopyTradingTermsTemplate__List-sc-1a7dxnp-2 ftA-dIq">
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    A trading interval on an Account begins on the date the
                    Account is activated (when the first deposit is made).
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    The end of a trading interval designates the beginning of
                    the following trading interval.{/* */}{" "}
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    Requests for the payment of the Manager’s Remuneration are
                    automatically created during the first open Rollover of the
                    trading day preceding the day on which the trading interval
                    ends. These requests are processed during the first open
                    Rollover on the day on which the trading interval ends.
                    {/* */}{" "}
                  </li>
                </ol>
              </li>
              <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                <b>Deposit of funds to Follower’s account</b>
                <ol className="CopyTradingTermsTemplate__List-sc-1a7dxnp-2 ftA-dIq">
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    A request must be submitted to deposit funds into an
                    account.
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    After submitting the request for deposit of funds, the
                    corresponding amount will be debited from the Follower's
                    corresponding wallet into their account
                  </li>
                </ol>
              </li>
              <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                <b>Withdrawal of funds from the account</b>
                <ol className="CopyTradingTermsTemplate__List-sc-1a7dxnp-2 ftA-dIq">
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    In order to withdraw funds from an account, an application
                    must be submitted by the Follower
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    The funds withdrawn from the account will be transferred to
                    the Follower’s wallet in the currency corresponding to the
                    account currency
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    In order to withdraw their entire balance, the Follower must
                    submit an unfollow application for the closing of the
                    account.
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    By submitting the application for withdrawal of funds, the
                    execution period of the application is determined. The
                    request will be executed during the next appropriate
                    Rollover.
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    when a request for withdrawal of funds or closing of an
                    account is submitted, it will be processed at the next
                    Rollover, based on the settings in the Trader's Copytrading
                    account
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    A Follower may cancel their request for withdrawal of funds
                    as soon as the request is submitted.
                  </li>
                </ol>
              </li>
              <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                <b>Obligations of the Trader and the Follower</b>
                <ol className="CopyTradingTermsTemplate__List-sc-1a7dxnp-2 ftA-dIq">
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    The Trader and the Follower guarantee to:
                    <ol
                      type="a"
                      className="CopyTradingTermsTemplate__List-sc-1a7dxnp-2 dyXoSY"
                    >
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        bear full responsibility for compliance with relevant
                        legislation, including, but not limited to, legislation
                        relating to currency, tax and other laws of the country
                        of residence, including full responsibility for the
                        management of Follower's accounts;
                      </li>
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        provide true and accurate information when registering
                        as a Company client
                      </li>
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        fully and completely know and understand the meaning,
                        terms and conditions of all accepted regulatory
                        documents of the Company;
                      </li>
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        be aware of the consequences of their actions in the
                        enforcement of these Terms and Conditions.
                      </li>
                    </ol>
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    In the event that this guarantee is breached, the Trader
                    agrees to settle, at their own expense and using their own
                    authority, any disputes or complaints of third parties
                    arising from their management of the Copytrading Account.
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    The Trader and the Follower agree to keep the access
                    passwords to their accounts on the trading platform secure
                    and confidential and are not authorized to disclose these
                    passwords to third parties. All actions related to the
                    fulfillment of the present Terms and Conditions and/or the
                    use of login and password are considered to be performed by
                    the holder of this information. The Company shall not bear
                    any responsibility for unauthorized use of the login
                    information by third parties.{/* */}{" "}
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    The Manager and the Follower do not have the right to:
                    <ol
                      type="a"
                      className="CopyTradingTermsTemplate__List-sc-1a7dxnp-2 dyXoSY"
                    >
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        assume any responsibility on behalf of the Company or
                        impose any obligations on the Company;
                      </li>
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        use the brand name and/or trademark of the Company;
                      </li>
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        Publish or participate in publications about the Company
                        in the mass media, distribute or publish articles and
                        letters about the Company in newspapers, magazines and
                        other periodicals, internet blogs and forums without the
                        permission of the Company, or participate in the writing
                        of such articles and letters;
                      </li>
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        Make any guarantees and/or promises or claims in
                        relation to any payments on behalf of the Company or
                        using the Company's trademark and/or brand name;
                      </li>
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        take any other actions that may cause damage to the
                        Company or generate complaints from third parties
                        against the Company.
                      </li>
                    </ol>
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    If any lawsuits or complaints are filed against the Company
                    due to the Trader’s or Follower’s failure to comply with
                    these terms and conditions, all losses suffered by the
                    Company shall be fully compensated by the Trader or the
                    Follower respectively
                  </li>
                </ol>
              </li>
              <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                <b>Copytrading account liquidation</b>
                <ol className="CopyTradingTermsTemplate__List-sc-1a7dxnp-2 ftA-dIq">
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    The liquidation of a Copytrading Account will result in the
                    corresponding liquidation of all accounts within the
                    Copytrading Account.
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    The Trader may initiate the closure of a Copytrading Account
                    by submitting an Account liquidation request. All open
                    positions will then be force closed.
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    The Company may initiate the closure of a Copytrading
                    Account. All open positions will then be force closed.
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    The Company reserves the right to close a Copytrading
                    Account in the following cases:
                    <ol
                      type="a"
                      className="CopyTradingTermsTemplate__List-sc-1a7dxnp-2 dyXoSY"
                    >
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        The ROE level displayed in the Copytrading Account
                        monitoring has remained unchanged for 100 consecutive
                        trading days, indicating that there is no trading
                        activity in the account. If this occurs, the account
                        will be closed automatically;
                      </li>
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        There is suspicion of fraud;
                      </li>
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        The manager has not followed the rules of the company
                        forum;
                      </li>
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        The Manager has not complied with the Company's
                        requirement to change the nickname or name of a
                        Copytrading account that contains obscene language or
                        language that is offensive/insulting to social, racial,
                        national or religious identity;
                      </li>
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        Other reasons as determined without prior notice and at
                        its own discretion;
                      </li>
                    </ol>
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    The remaining net equity of the Followers and the Trader, if
                    any, shall be automatically distributed to their respective
                    {siteSettings.siteName} account wallet balances
                  </li>
                </ol>
              </li>
              <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                <b>Account expiration</b>
                <ol className="CopyTradingTermsTemplate__List-sc-1a7dxnp-2 ftA-dIq">
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    the Company's policy on account expiration is as follows:
                    <ol
                      type="a"
                      className="CopyTradingTermsTemplate__List-sc-1a7dxnp-2 dyXoSY"
                    >
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        accounts with a positive balance will not expire and may
                        remain indefinitely
                      </li>
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        Followers may close their accounts at any time.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                <b>Communication</b>
                <ol className="CopyTradingTermsTemplate__List-sc-1a7dxnp-2 ftA-dIq">
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    The Trader and the Follower are clients of the Company. In
                    order to communicate with the Client, the Company may use
                    the following:{/* */}
                    <ol
                      type="a"
                      className="CopyTradingTermsTemplate__List-sc-1a7dxnp-2 dyXoSY"
                    >
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        e-mai
                      </li>
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        Notifications
                      </li>
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        Live chat
                      </li>
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        Social media
                      </li>
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        Other communication channels
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                <b>Risks</b>
                <ol className="CopyTradingTermsTemplate__List-sc-1a7dxnp-2 ftA-dIq">
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    The Company does not guarantee any profit. Positive trading
                    results in the past are not a guarantee of future positive
                    trading results.
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    The Trader and the Follower accept responsibility for
                    possible financial losses, such as direct losses or profit
                    lost resulting from the following risks:
                    <ol
                      type="a"
                      className="CopyTradingTermsTemplate__List-sc-1a7dxnp-2 dyXoSY"
                    >
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        the risk that the Trader may not have the required
                        qualification, knowledge, skills or education to manage
                        the Follower's funds;{/* */}{" "}
                      </li>
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        the risk that the Trader may not act in the interest of
                        the Followers or may commit fraudulent operations in
                        relation to the Followers funds;
                      </li>
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        the risk that the Trader may lose control/access of
                        their Copytrading account(s);
                      </li>
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        the risk that third parties may gain control/access of
                        Copytrading account(s);{" "}
                      </li>
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        the risk that the Manager, the Follower or the Partner
                        misunderstand or misinterpret these terms and
                        conditions;
                      </li>
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        the risk of unforeseen delays in transfers between
                        accounts or late execution of deposit/withdrawal
                        requests or the closing/liquidation of a Copytrading
                        Account;
                      </li>
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        The risk of hacker attacks, which can result in third
                        parties gaining access to Copytrading Accounts
                      </li>
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        the risk of possible claims from financial and tax
                        authorities of jurisdictions where the Trader or
                        Followers reside
                      </li>
                      <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                        the risk of delays in receiving information about
                        alteration of the Copytrading Terms and Conditions
                        agreement or general {siteSettings.siteName} Trading
                        Solutions Ltd. Terms and Conditions;
                      </li>
                    </ol>
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    the Copytrading Account Trader and their Followers bear the
                    financial responsibility for any negative equity which may
                    occur in a Copytrading Account in direct proportion to their
                    share in the Copytrading account.
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    The Company shall under no circumstances be responsible for
                    the consequences of the above risks.{/* */}{" "}
                  </li>
                </ol>
              </li>
              <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                <b>Miscellaneous</b>
                <ol className="CopyTradingTermsTemplate__List-sc-1a7dxnp-2 ftA-dIq">
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    The Client acknowledges that the Company has the right to
                    make changes, remove, add, modify or change portions of
                    these Terms and Conditions at any time at its sole
                    discretion and without prior written notice to the Client.
                  </li>
                  <li className="CopyTradingTermsTemplate__ListItem-sc-1a7dxnp-3 ToALG">
                    If one or more provisions of these Terms and Conditions
                    become invalid, void or ineffective for any reason, this
                    shall not affect the validity of the remaining provisions,
                    which shall continue to be binding.{/* */}{" "}
                  </li>
                </ol>
              </li>
            </ol>
          </div>

          {/* footer */}
          <Footer />
        </div>
      </div>
      <div
        id="gatsby-announcer"
        style={{
          position: "absolute",
          top: 0,
          width: 1,
          height: 1,
          padding: 0,
          overflow: "hidden",
          clip: "rect(0, 0, 0, 0)",
          whiteSpace: "nowrap",
          border: 0,
        }}
        aria-live="assertive"
        aria-atomic="true"
      />
      <div className="Toastify" />
      <div />
    </div>
  );
};

export default CopyTerms;
