import { useState } from "react";
import styled from "styled-components";

const features = [
  {
    name: "User-Friendly Interface",
    desc: "Margex is designed with user experience taken into consideration. Enjoy the industry's most transparent system of reporting.",
  },
  {
    name: "Better Risk Management",
    desc: "Switch between isolated and cross-margin in one account, allowing for better risk management by keeping you aware of the exact liquidation price and funds at risk in your orders.",
  },
  {
    name: "Multicollateral Wallets",
    desc: "Margex's multi-collateral wallets allow you to deposit any cryptocurrency and trade almost any pair listed on the exchange, with settlements made in your chosen collateral, without the need to own the underlying asset when trading different pairs.",
  },
  {
    name: "The whole market data is at your fingertips",
    desc: "Margex offers live deal flow and a $40,000,000+ deep orderbook combined from 12+ liquidity providers. This ensures that a single trader with a relatively large order can't manipulate prices.",
  },
  {
    name: "Go Long (Buy) or Go Short (Sell)",
    desc: "Trade in any market direction by opening long or short positions with up to 100x leverage for all assets offered on the platform. Margex's unique order structure allows you to open multiple positions on the same asset in the same or opposite direction without consolidating them into one large position.",
  },
];

const Features = () => {
  const [activeFeature, setActiveFeature] = useState("User-Friendly Interface");

  return (
    <FeaturesWrapper>
      <div className="container">
        <p className="features__title">
          Margex offers a broad range of powerful tools <br />
          to harness your trading skills and boost profits
        </p>

        <section>
          <div className="features__wrap">
            {features.map((feature) => (
              <div
                className={
                  feature.name === activeFeature ? "feature active" : "feature"
                }
                onClick={() => setActiveFeature(feature.name)}
              >
                <div className="feature__top">
                  <span>
                    {/* <img src="./icons/user-friendly.svg" alt="user" /> */}
                    <p>{feature.name}</p>
                  </span>
                  <p className="description">{feature.desc}</p>
                </div>

                <div className="feature__bottom">
                  <p>Learn more</p>
                </div>
              </div>
            ))}
          </div>

          <div className="image_wrap">
            <img
              src="https://margex.com/static/way-1-05a78515933052a6ade28483bcfc8fce.webp"
              alt=""
              className="active"
            />
          </div>
        </section>
      </div>
    </FeaturesWrapper>
  );
};

const FeaturesWrapper = styled.div`
  .features__wrap {
    display: grid;
    /* gap: 24px; */
    max-width: 450px;
  }

  .features__wrap .feature {
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    z-index: 1;
    transition: background-color 0.2s ease 0s;
    border-radius: 8px;
    margin-bottom: 10px;
  }

  .features__wrap .feature:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }

  .features__wrap .feature.active {
    background-color: rgba(255, 255, 255, 0.15);
  }

  .features__wrap .feature .feature__top span {
    font-size: 16px;
  }

  .features__wrap .feature .feature__top {
    padding: 10px 16px;
    font-size: 14px;
    font-family: "Open Sans";
  }

  .features__wrap .feature .description {
    display: none;
  }

  .features__wrap .feature.active .description {
    display: block;
  }

  .features__wrap .feature .feature__bottom {
    display: none;
  }

  .features__wrap .feature.active .feature__bottom {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    color: rgb(255, 255, 255);
    text-decoration: none;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 44px;
    cursor: pointer;
    text-transform: uppercase;
  }

  .container {
    background-color: #3071ee;
    color: white;
    padding: 70px;
    padding-top: 130px;
    min-height: 700px;
  }

  .container section {
    position: relative;
    /* margin-top: 120px; */
  }

  .image_wrap {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: end;
    justify-content: flex-end;
    position: absolute;
    top: 50px;
    right: 0px;
    z-index: 999;
    transition: opacity 1s ease-in-out 0s;
    /* opacity: 0; */
  }

  .image_wrap img.active {
    /* display: block; */
    width: 640px;
    aspect-ratio: auto 640 / 501;
    height: 501px;
    overflow-clip-margin: content-box;
    overflow: clip;
  }

  .features__title {
    font-family: Gilroy;
    font-weight: 500;
    font-size: 38px;
    color: white;
  }
`;

export default Features;
