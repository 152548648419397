import { useEffect } from "react";
import "../../styles/bottom/privacy.css";
import Header from "../../components/general/Header";
import Footer from "../../components/general/Footer";
import { siteSettings } from "../../static";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="___gatsby">
      <div style={{ outline: "none" }} tabIndex={-1} id="gatsby-focus-wrapper">
        <div className="DefaultLayout__BackgroundContainer-sc-1h2qfsw-0 bzHVOS">
          {/* header */}
          <Header />

          {/* body */}
          <div className="Container-sc-rx245t-0 PrivacyPolicyTemplate__StyledContainer-sc-rj502g-0 dGblwP kskqOK">
            <h1 className="PrivacyPolicyTemplate__Title-sc-rj502g-1 dspPfA">
              Privacy Policy
            </h1>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              At {siteSettings.siteName}, accessible from{/* */}{" "}
              <a
                href="/"
                className="PrivacyPolicyTemplate__Link-sc-rj502g-6 gzrjbC"
              >
                {siteSettings.siteCropLink}
              </a>{" "}
              {/* */}, one of our main priorities is the privacy of our
              visitors. This Privacy Policy document contains types of
              information that is collected and recorded by{" "}
              {siteSettings.siteName} and how we use it.
            </p>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              If you have additional questions or require more information about
              our Privacy Policy, do not hesitate to contact us.
            </p>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              This Privacy Policy applies only to our online activities and is
              valid for visitors to our website with regards to the information
              that they shared and/or collect in {siteSettings.siteName}. This
              policy is not applicable to any information collected offline or
              via channels other than this website.
            </p>
            <h4 className="PrivacyPolicyTemplate__ParagrapghTitle-sc-rj502g-3 eMuYzY">
              Consent
            </h4>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              By using our website, you hereby consent to our Privacy Policy and
              agree to its terms.
            </p>
            <h4 className="PrivacyPolicyTemplate__ParagrapghTitle-sc-rj502g-3 eMuYzY">
              {" "}
              {/* */}Information we collect
            </h4>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              <strong>Account Creation:</strong> {/* */} We may collect your
              name, email address, date of birth, tax number, username, and
              password or other personal information used to identify you.
            </p>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              <strong>Your Communications with Us.</strong>
              {/* */} We collect personal information from you such as email
              address, phone number, or mailing address when you request
              information about our Services, request customer or technical
              support, apply for a job or otherwise communicate with us.
            </p>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              <strong>Surveys.</strong> {/* */}We may contact you to participate
              in surveys. If you decide to participate, you may be asked to
              provide certain information which may include personal
              information.
            </p>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              <strong>Social Media Content.</strong>
              {/* */}We may offer forums, blogs, or social media pages. Any
              content you provide on these channels will be considered “public”
              and is not subject to privacy protections.
            </p>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              <strong>
                Registration for Sweepstakes, Contests and Tournaments.
              </strong>{" "}
              {/* */}We may run sweepstakes, contests and tournaments. Contact
              information you provide may be used to reach you about the
              sweepstakes or contest and for other promotional, marketing and
              business purposes, if permitted by law. In some jurisdictions, we
              are required to publicly share information about winners.
            </p>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              <strong>KYC documents.</strong> {/* */}In some cases, AML laws
              require us to sight and record details of certain documents to
              meet the standards, set under those laws. Therefore, in order to
              comply with the laws, we have the right to request you the
              following documents and information: passport, driver's licence,
              national ID (if applicable), source of funds confirmation, utility
              bills or other documents or information we consider necessary to
              conduct KYC/AML.
            </p>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              <strong>Automatic Data Collection.</strong>
              {/* */}We may collect certain information automatically when you
              use the Services. This information may include your Internet
              protocol (IP) address, user settings, MAC address, cookie
              identifiers, mobile carrier, mobile advertising and other unique
              identifiers, details about your browser, operating system or
              device, location information, Internet service provider, pages
              that you visit before, during and after using the Services,
              information about the links you click, and other information about
              how you use the Services. Information we collect may be associated
              with accounts and other devices.
            </p>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              In addition, we may automatically collect data regarding your use
              of our Services, such as the types of content you interact with
              and the frequency and duration of your activities. We may combine
              your information with information that other people provide when
              they use our Services, including information about you when they
              tag you.
            </p>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              <strong>
                Cookies, Pixel Tags/Web Beacons, Analytics Information, and
                Interest-Based Advertising technologies.
              </strong>{" "}
              {/* */}We, as well as third parties that provide content,
              advertising, or other functionality on the Services, may use
              cookies, pixel tags, local storage, and other technologies to
              automatically collect information through the Services.
              Technologies are essentially small data files placed on your
              computer, tablet, mobile phone, or other devices that allow us and
              our partners to record certain pieces of information whenever you
              visit or interact with our Services.
            </p>
            <h4 className="PrivacyPolicyTemplate__ParagrapghTitle-sc-rj502g-3 eMuYzY">
              How we use collected information
            </h4>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              We use the collected information in various ways, including to:
            </p>
            <ul className="PrivacyPolicyTemplate__List-sc-rj502g-4 lkIOCJ">
              <li className="PrivacyPolicyTemplate__ListItem-sc-rj502g-5 jaryjL">
                Provide, operate, maintain our website and develop relevant
                features and services;
              </li>
              <li className="PrivacyPolicyTemplate__ListItem-sc-rj502g-5 jaryjL">
                Improve, personalize, and expand our website;
              </li>
              <li className="PrivacyPolicyTemplate__ListItem-sc-rj502g-5 jaryjL">
                Understand and analyze how you use our website;
              </li>
              <li className="PrivacyPolicyTemplate__ListItem-sc-rj502g-5 jaryjL">
                Develop new products, services, features, and functionality;
              </li>
              <li className="PrivacyPolicyTemplate__ListItem-sc-rj502g-5 jaryjL">
                Communicate with you, either directly or through one of our
                partners, including for customer service, to provide you with
                updates and other information relating to the website, and for
                marketing and promotional purposes;
              </li>
              <li className="PrivacyPolicyTemplate__ListItem-sc-rj502g-5 jaryjL">
                Provide you, or permit selected third parties to provide you,
                with information about products or services we feel may interest
                you.
              </li>
              <li className="PrivacyPolicyTemplate__ListItem-sc-rj502g-5 jaryjL">
                Send you emails;
              </li>
              <li className="PrivacyPolicyTemplate__ListItem-sc-rj502g-5 jaryjL">
                Research and develop new services;
              </li>
              <li className="PrivacyPolicyTemplate__ListItem-sc-rj502g-5 jaryjL">
                Find and prevent fraud;
              </li>
              <li className="PrivacyPolicyTemplate__ListItem-sc-rj502g-5 jaryjL">
                Measure or understand the effectiveness of advertising we serve
                to you and others, and to deliver relevant advertising to you;
              </li>
              <li className="PrivacyPolicyTemplate__ListItem-sc-rj502g-5 jaryjL">
                Make suggestions and recommendations to you and other users of
                our site about goods or services that may interest you or them;
              </li>
              <li className="PrivacyPolicyTemplate__ListItem-sc-rj502g-5 jaryjL">
                Send you updates, security alerts technical notices, and other
                support messages;
              </li>
              <li className="PrivacyPolicyTemplate__ListItem-sc-rj502g-5 jaryjL">
                Personalize the Services and provide advertisements, content and
                features that match user profiles or interests;
              </li>
              <li className="PrivacyPolicyTemplate__ListItem-sc-rj502g-5 jaryjL">
                Allow you to participate in interactive features of our service,
                when you choose to do so;
              </li>
            </ul>
            <h4 className="PrivacyPolicyTemplate__ParagrapghTitle-sc-rj502g-3 eMuYzY">
              Log Files
            </h4>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              {siteSettings.siteName} follows a standard procedure of using log
              files. These files log visitors when they visit websites. All
              hosting companies do this and a part of hosting services'
              analytics. The information collected by log files include internet
              protocol (IP) addresses, browser type, Internet Service Provider
              (ISP), date and time stamp, referring/exit pages, and possibly the
              number of clicks. These are not linked to any information that is
              personally identifiable. The purpose of the information is for
              analyzing trends, administering the site, tracking users' movement
              on the website, and gathering demographic information.
            </p>
            <h4 className="PrivacyPolicyTemplate__ParagrapghTitle-sc-rj502g-3 eMuYzY">
              Cookies
            </h4>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              Like any other website, {siteSettings.siteName} uses 'cookies'.
              These cookies are used to store information including visitors'
              preferences, and the pages on the website that the visitor
              accessed or visited. The information is used to optimize the
              users' experience by customizing our web page content based on
              visitors' browser type and/or other information.
            </p>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              For more information on cookies, please read our “Cookie policy”.
            </p>
            <h4 className="PrivacyPolicyTemplate__ParagrapghTitle-sc-rj502g-3 eMuYzY">
              Our Advertising Partners
            </h4>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              Some advertisers on our website may use cookies and web beacons.
              Each of our advertising partners has their own Privacy Policy for
              their policies on user data.
            </p>
            <h4 className="PrivacyPolicyTemplate__ParagrapghTitle-sc-rj502g-3 eMuYzY">
              Advertising Partners Privacy Policies
            </h4>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              You may consult this list to find the Privacy Policy for each of
              the advertising partners of {siteSettings.siteName}.
            </p>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              Third-party ad servers or ad networks use technologies like
              cookies, JavaScript, or Web Beacons that are used in their
              respective advertisements and links that appear on{" "}
              {siteSettings.siteName}, which are sent directly to users'
              browsers. They automatically receive your IP address when this
              occurs. These technologies are used to measure the effectiveness
              of their advertising campaigns and/or to personalize the
              advertising content that you see on websites that you visit.
            </p>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              Note that {siteSettings.siteName} has no access to or control over
              these cookies that are used by third-party advertisers.
            </p>
            <h4 className="PrivacyPolicyTemplate__ParagrapghTitle-sc-rj502g-3 eMuYzY">
              Third Party Privacy Policies
            </h4>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              {siteSettings.siteName}'s Privacy Policy does not apply to other
              advertisers or websites. Thus, we are advising you to consult the
              respective Privacy Policies of these third-party ad servers for
              more detailed information. It may include their practices and
              instructions about how to opt-out of certain options.
            </p>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              You can choose to disable cookies through your individual browser
              options. To know more detailed information about cookie management
              with specific web browsers, it can be found at the browsers'
              respective websites.
            </p>
            <h4 className="PrivacyPolicyTemplate__ParagrapghTitle-sc-rj502g-3 eMuYzY">
              GDPR Data Protection Rights
            </h4>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              We would like to make sure you are fully aware of all of your data
              protection rights. Every user is entitled to the following:
            </p>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              The right to access – You have the right to request copies of your
              personal data. We may charge you a small fee for this service.
            </p>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              The right to rectification – You have the right to request that we
              correct any information you believe is inaccurate. You also have
              the right to request that we complete the information you believe
              is incomplete.
            </p>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              The right to erasure – You have the right to request that we erase
              your personal data, under certain conditions.
            </p>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              The right to restrict processing – You have the right to request
              that we restrict the processing of your personal data, under
              certain conditions.
            </p>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              The right to object to processing – You have the right to object
              to our processing of your personal data, under certain conditions.
            </p>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              The right to data portability – You have the right to request that
              we transfer the data that we have collected to another
              organization, or directly to you, under certain conditions.
            </p>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              If you make a request, we have one month to respond to you. If you
              would like to exercise any of these rights, please contact us.
            </p>
            <h4 className="PrivacyPolicyTemplate__ParagrapghTitle-sc-rj502g-3 eMuYzY">
              Miscellaneous
            </h4>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              If {siteSettings.siteName} becomes aware of a data breach or
              security breach, we will endeavor to notify our customers via
              email or other means of communication so that you can take
              appropriate security measures.
            </p>
            <h4 className="PrivacyPolicyTemplate__ParagrapghTitle-sc-rj502g-3 eMuYzY">
              Children's Information
            </h4>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              Another part of our priority is adding protection for children
              while using the internet. We encourage parents and guardians to
              observe, participate in, and/or monitor and guide their online
              activity.
            </p>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              {siteSettings.siteName} does not knowingly collect any Personal
              Identifiable Information from children under the age of 18. If you
              think that your child provided this kind of information on our
              website, we strongly encourage you to contact us immediately and
              we will do our best efforts to promptly remove such information
              from our records.
            </p>
            <h4 className="PrivacyPolicyTemplate__ParagrapghTitle-sc-rj502g-3 eMuYzY">
              Changes to our privacy policy
            </h4>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              We may revise this Privacy Policy from time to time without any
              prior notice. You understand and agree that you will be deemed to
              have accepted the updated Privacy Policy if you continue to use
              the Services after the new Privacy Policy takes effect. The most
              current version of the {siteSettings.siteName} policy will govern
              our processing of your personal data and will always be at our
              website {/* */}{" "}
              <a
                href="/"
                className="PrivacyPolicyTemplate__Link-sc-rj502g-6 gzrjbC"
              >
                {siteSettings.siteCropLink}
              </a>
            </p>
            <h4 className="PrivacyPolicyTemplate__ParagrapghTitle-sc-rj502g-3 eMuYzY">
              Contact us
            </h4>
            <p className="PrivacyPolicyTemplate__Paragrapgh-sc-rj502g-2 dVNUqA">
              If You have any questions about our Privacy Policy, please feel
              free to contact us at email:{" "}
              <a
                href={`mailto:${siteSettings.supportEmail}`}
                className="PrivacyPolicyTemplate__Link-sc-rj502g-6 gzrjbC"
              >
                <span
                  className="__cf_email__"
                  data-cfemail="b7c4c2c7c7d8c5c3f7dad6c5d0d2cf99d4d8da"
                >
                  {siteSettings.supportEmail}
                </span>
              </a>
            </p>
          </div>

          {/* footer */}
          <Footer />
        </div>
      </div>
      <div
        id="gatsby-announcer"
        style={{
          position: "absolute",
          top: 0,
          width: 1,
          height: 1,
          padding: 0,
          overflow: "hidden",
          clip: "rect(0, 0, 0, 0)",
          whiteSpace: "nowrap",
          border: 0,
        }}
        aria-live="assertive"
        aria-atomic="true"
      />
      <div className="Toastify" />
      <div />
    </div>
  );
};

export default Privacy;
