import styled from "styled-components";
import { DashboardShot } from "../features/DashboardShot";
import { siteSettings } from "../../static";

const features = [
  {
    feature: "Combined Liquidity of 12+",
    brk: "Exchanges in One Place",
    icon: "./icons/combined.svg",
  },
  {
    feature: "Bank-Grade",
    brk: "Multi-Layer Security",
    icon: "./icons/bank.svg",
  },
  {
    feature: "Quick sign up",
    brk: "in less than 10 minutes",
    icon: "./icons/quick.svg",
  },
];

const MainLockup = () => {
  return (
    <div className="Container-sc-rx245t-0 dGblwP" style={{ zIndex: "1" }}>
      <h1
        className="SectionStart__StartTitle-sc-dij555-1 fHJqlF"
        style={{ fontFamily: "Gilroy", fontWeight: 700 }}
      >
        Your Reliable Way to Trade Bitcoin
        <span className="SectionStart__DLgBlock-sc-dij555-2 bNqILF">
          <span className="SectionStart__TextNoWrap-sc-dij555-3 guLlDr">
            &nbsp;{/* */}With up to
          </span>
          <span className="SectionStart__TextPrimary-sc-dij555-4 jPOTTJ">
            <span className="SectionStart__TextNoWrap-sc-dij555-3 guLlDr">
              &nbsp;{/* */}100x Leverage
            </span>
          </span>
        </span>
      </h1>
      <div className="SectionStart__StartSubTitle-sc-dij555-5 iSrpGj"></div>
      <div className="SectionStart__FlexBox-sc-dij555-6 eGrlJn">
        <a
          href={`${siteSettings.appLink}/register`}
          className="SectionStart__StartBtn-sc-dij555-7 cxGBQr"
        >
          <div className="SectionStart__StartBtnTitle-sc-dij555-8 fMXwDk">
            START TRADING
          </div>
          <div className="SectionStart__StartBtnSubTitle-sc-dij555-9 jyOZsu">
            Create an account
          </div>
          <div className="SectionStart__StartBtnIcon-sc-dij555-10 bbhvZi">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 6L20 12L14 18M19 12H4"
                stroke="white"
                stroke-width="2"
                stroke-linecap="square"
              />
            </svg>
          </div>
        </a>
        {/* <div className="SectionStart__FlexIcons-sc-dij555-12 clBTJR">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
          >
            <circle cx={8} cy={8} r={8} fill="#C9D0E3" />
            <path
              d="M11.937 8.263c-.248-.32-.621-.54-1.122-.66.637-.325.917-.877.84-1.655a1.635 1.635 0 00-.234-.733 1.468 1.468 0 00-.529-.5 2.911 2.911 0 00-.724-.289 5.715 5.715 0 00-.898-.144V2.667h-.987v1.57c-.17 0-.431.004-.782.013V2.667h-.987v1.615c-.141.004-.348.007-.622.007l-1.359-.007v1.051h.712c.324 0 .515.146.57.436v1.84c.043 0 .077.002.103.007h-.103v2.576c-.034.218-.158.327-.372.327h-.711l-.199 1.174h1.282c.082 0 .199 0 .353.002.154.003.27.004.346.004v1.634h.987v-1.615c.18.004.44.006.782.006v1.61h.988v-1.635c.35-.017.66-.05.932-.096.272-.048.531-.121.78-.222.247-.1.454-.225.62-.375.168-.15.305-.34.414-.57.11-.231.179-.498.209-.802.055-.593-.04-1.05-.289-1.371zM7.52 5.403a25.203 25.203 0 00.6-.009 3.74 3.74 0 01.372.016c.156.013.287.03.394.051.107.022.224.057.352.106.129.05.231.109.308.18a.89.89 0 01.196.269.84.84 0 01.08.375c0 .12-.02.228-.058.327a.616.616 0 01-.173.247 1.753 1.753 0 01-.237.173c-.081.049-.185.088-.311.118s-.236.054-.33.07a2.701 2.701 0 01-.353.036l-.32.013a6.895 6.895 0 01-.298 0l-.222-.003V5.404zm2.696 4.366a.911.911 0 01-.157.257.89.89 0 01-.253.192c-.105.056-.206.1-.305.134a2.464 2.464 0 01-.355.09 4.674 4.674 0 01-.741.08 8.41 8.41 0 01-.647.007 20.865 20.865 0 00-.238-.003V8.359c.035 0 .136-.002.305-.007a6.845 6.845 0 01.856.013c.188.013.346.032.474.058.128.026.268.065.42.118.152.054.275.119.369.196a.962.962 0 01.237.295c.064.12.096.256.096.41 0 .12-.02.229-.06.327z"
              fill="#fff"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={34}
            height={12}
            viewBox="0 0 34 12"
            fill="none"
          >
            <g fill="#C9D0E3">
              <path d="M14.282 11.18h-2.67L13.282.854h2.67l-1.67 10.326zM23.96 1.106a6.58 6.58 0 00-2.395-.44c-2.636 0-4.493 1.407-4.504 3.417-.022 1.483 1.33 2.307 2.34 2.801 1.033.505 1.384.835 1.384 1.286-.01.691-.835 1.01-1.604 1.01-1.066 0-1.637-.164-2.505-.549l-.351-.165-.374 2.318c.626.285 1.78.538 2.978.55 2.801 0 4.625-1.385 4.647-3.527.01-1.175-.703-2.076-2.241-2.812-.934-.472-1.506-.791-1.506-1.274.01-.44.484-.89 1.538-.89a4.536 4.536 0 011.988.395l.242.11.363-2.23zM27.509 7.521c.22-.593 1.066-2.889 1.066-2.889-.011.023.22-.604.35-.988l.188.89s.505 2.471.615 2.987h-2.22zM30.804.854H28.74c-.637 0-1.12.186-1.395.857l-3.966 9.468h2.801s.462-1.274.56-1.549h3.429c.076.363.318 1.55.318 1.55h2.472L30.804.853zM9.382.854L6.767 7.895l-.286-1.428c-.483-1.648-2-3.438-3.691-4.328l2.395 9.03h2.823L12.205.854H9.382z"></path>
              <path d="M4.34.854H.044L0 1.063c3.35.856 5.57 2.922 6.482 5.404l-.934-4.745c-.154-.66-.626-.846-1.208-.868z"></path>
            </g>
            <defs>
              <clipPath id="clip0">
                <path fill="#fff" d="M0 .667h33.333v10.667H0z" />
              </clipPath>
            </defs>
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={22}
            height={13}
            viewBox="0 0 22 13"
            fill="none"
          >
            <g>
              <path d="M14.099 1.733H8.466v9.2h5.633v-9.2z" fill="#BFC6D6" />
              <path
                d="M9.046 6.334a5.841 5.841 0 012.235-4.6 5.85 5.85 0 100 9.2 5.842 5.842 0 01-2.235-4.6z"
                fill="#C9D0E3"
              />
              <path
                d="M20.747 6.334a5.85 5.85 0 01-9.467 4.6 5.852 5.852 0 000-9.201 5.851 5.851 0 019.467 4.6z"
                fill="#D8DDEA"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <path fill="#fff" d="M.333.333h21.333v12H.333z" />
              </clipPath>
            </defs>
          </svg>
        </div> */}
        {/* <div className="SectionStart__StartBenefit-sc-dij555-13 eEGa-DM">
          <div className="SectionStart__StartBenefitItem-sc-dij555-14 eHMVOY">
            <svg className="SectionStart__StyledStartBenefitIcon-sc-dij555-16 cNQSWK">
              <use href="#benefit-rating" />
            </svg>
            <div className="SectionStart__StartBenefitTitle-sc-dij555-15 iFDOun">
              Combined Liquidity of 12+ <br />
              Exchanges in One Place
            </div>
          </div>
          <div className="SectionStart__StartBenefitItem-sc-dij555-14 eHMVOY">
            <svg className="SectionStart__StyledStartBenefitIcon-sc-dij555-16 cNQSWK">
              <use href="#benefit-bank" />
            </svg>
            <div className="SectionStart__StartBenefitTitle-sc-dij555-15 iFDOun">
              Bank-Grade <br /> Multi-Layer Security
            </div>
          </div>
          <div className="SectionStart__StartBenefitItem-sc-dij555-14 eHMVOY">
            <svg className="SectionStart__StyledStartBenefitIcon-sc-dij555-16 cNQSWK">
              <use href="#benefit-shild" />
            </svg>
            <div className="SectionStart__StartBenefitTitle-sc-dij555-15 iFDOun">
              No personal details <br /> are required
            </div>
          </div>
        </div> */}

        <MiniFeatures>
          <div className="container">
            {features.map((feature) => (
              <div className="feature">
                <p>
                  {feature.feature} <br /> {feature.brk}
                </p>
                <img src={feature.icon} alt="" />
              </div>
            ))}
          </div>
        </MiniFeatures>
      </div>
      {/* section:dashboard-shot */}
      {/* <div className="SectionStart__StartTrade-sc-dij555-17 kiJmwh">
        <div className="SectionStart__VideoBlock-sc-dij555-18 dNifrk">
          <video
            width="946"
            height="492"
            autoPlay="true"
            loop=""
            muted=""
            playsInline=""
          >
            <source
              src="./static/Main_video_minified-141221434c7e8e9b0dfc4703fb9ac6eb.mp4"
              type="video/mp4"
            />
            <track kind="captions" />
          </video>
        </div>
        <div className="SectionStart__MobileBlock-sc-dij555-19 coszfQ">
          <div
            data-gatsby-image-wrapper=""
            class="gatsby-image-wrapper gatsby-image-wrapper-constrained"
          >
            <div style={{ maxWidth: "320px", display: "block" }}>
              <img
                alt=""
                role="presentation"
                aria-hidden="true"
                src="data:image/svg+xml;charset=utf-8,%3Csvg%20height=&#x27;554&#x27;%20width=&#x27;320&#x27;%20xmlns=&#x27;http://www.w3.org/2000/svg&#x27;%20version=&#x27;1.1&#x27;%3E%3C/svg%3E"
                style={{
                  maxWidth: "100%",
                  display: "block",
                  position: "static",
                }}
              />
            </div>
            <div
              aria-hidden="true"
              data-placeholder-image=""
              style={{
                opacity: "1",
                transition: "opacity 500ms linear",
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
              }}
            ></div>

            <img
              data-gatsby-image-ssr=""
              data-main-image=""
              style={{ opacity: 0 }}
              sizes="(min-width: 320px) 320px, 100vw"
              decoding="async"
              loading="lazy"
              data-src="/static/6392c5e306c929d2eb31b21b1b8df245/40a47/start-trade-mobile.png"
              data-srcset="/static/6392c5e306c929d2eb31b21b1b8df245/1a0c9/start-trade-mobile.png 80w,/static/6392c5e306c929d2eb31b21b1b8df245/983b7/start-trade-mobile.png 160w,/static/6392c5e306c929d2eb31b21b1b8df245/40a47/start-trade-mobile.png 320w"
              alt="Margex App"
            />
            <noscript>
              <img
                data-gatsby-image-ssr=""
                data-main-image=""
                style={{ opacity: 0 }}
                sizes="(min-width: 320px) 320px, 100vw"
                decoding="async"
                loading="lazy"
                src="/static/6392c5e306c929d2eb31b21b1b8df245/40a47/start-trade-mobile.png"
                srcSet="/static/6392c5e306c929d2eb31b21b1b8df245/1a0c9/start-trade-mobile.png 80w,/static/6392c5e306c929d2eb31b21b1b8df245/983b7/start-trade-mobile.png 160w,/static/6392c5e306c929d2eb31b21b1b8df245/40a47/start-trade-mobile.png 320w"
                alt="Margex App"
              />
            </noscript>
          </div>
        </div>
      </div> */}

      <DashboardShot />
    </div>
  );
};

const MiniFeatures = styled.div`
  .container {
    max-width: 700px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 48px;
    margin-top: 64px;
    padding-bottom: 24px;
  }

  .container {
    @media screen and (max-width: 868px) {
      display: none;
    }
  }

  .container .feature {
    display: flex;
    flex-direction: row-reverse;
    gap: 6px;
  }

  .container .feature p {
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
  }
`;

export default MainLockup;
