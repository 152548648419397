import styled from "styled-components";
import { siteSettings } from "../../static";

const sections = [
  {
    name: "Privacy",
    text: `We realize that in the IT driven world the most valuable asset is data which could be used against you. ${siteSettings.siteName} values your privacy and the right to not disclose your personal information.`,
  },
  {
    name: "Secure trading",
    text: `${siteSettings.siteName} utilizes the most practical and highly scalable infrastructure type, that, even during peak market activity, enables traders to trade uninterrupted. ${siteSettings.siteName} combines the very best of cryptocurrency and bank-grade security standards. 100% of the assets are stored in cold offline wallets.`,
  },
  {
    name: "Transparency in every aspect",
    text: `Every bit of information is available to you. From the submission of your order to its execution - you are in total control.`,
  },
];

const WhyUs = () => {
  return (
    // <section
    //   className="SectionChoice__ChoiceSection-sc-jwdpkx-0 jHBevi"
    //   style={{
    //     boxSizing: "border-box",
    //     WebkitTapHighlightColor: "transparent",
    //     margin: "0px",
    //     padding: "0px",
    //     border: "0px",
    //     font: "inherit",
    //     verticalAlign: "baseline",
    //     display: "block",
    //     paddingTop: "60px",
    //   }}
    // >
    //   <div
    //     className="Container-sc-rx245t-0 dGblwP"
    //     style={{
    //       boxSizing: "border-box",
    //       WebkitTapHighlightColor: "transparent",
    //       margin: "0px",
    //       padding: "0px",
    //       border: "0px",
    //       font: "inherit",
    //       verticalAlign: "baseline",
    //       marginInline: "auto",
    //       paddingInline: "10px",
    //       width: "100%",
    //       contain: "none",
    //       maxWidth: "1190px",
    //       paddingRight: "40px",
    //       paddingInlineStart: "40px",
    //     }}
    //   >
    //     <h2
    //       className="SectionChoice__ChoiceTitle-sc-jwdpkx-1 gTnnmg"
    //       style={{
    //         boxSizing: "border-box",
    //         WebkitTapHighlightColor: "transparent",
    //         margin: "0px",
    //         padding: "0px",
    //         border: "0px",
    //         font: "inherit",
    //         verticalAlign: "baseline",
    //         fontFamily: "Gilroy",
    //         fontWeight: 800,
    //         marginBottom: "62px",
    //         fontSize: "38px",
    //         textAlign: "center",
    //         lineHeight: "47px",
    //       }}
    //     >
    //       Why you should choose
    //       <br
    //         style={{
    //           boxSizing: "border-box",
    //           WebkitTapHighlightColor: "transparent",
    //         }}
    //       />
    //       Margex's Trading Infrastructure
    //     </h2>
    //     <div
    //       className="SectionChoice__ChoiceList-sc-jwdpkx-2 fSxATO"
    //       style={{
    //         boxSizing: "border-box",
    //         WebkitTapHighlightColor: "transparent",
    //         margin: "0px",
    //         padding: "0px",
    //         border: "0px",
    //         font: "inherit",
    //         verticalAlign: "baseline",
    //         display: "flex",
    //         flexWrap: "wrap",
    //         gap: "30px",
    //       }}
    //     >
    //       <div
    //         className="SectionChoice__ChoiceItem-sc-jwdpkx-3 hWkbvv"
    //         style={{
    //           boxSizing: "border-box",
    //           WebkitTapHighlightColor: "transparent",
    //           margin: "0px",
    //           padding: "0px",
    //           border: "0px",
    //           font: "inherit",
    //           verticalAlign: "baseline",
    //           position: "relative",
    //           width: "100%",
    //           marginBottom: "40px",
    //           flex: "1 1 0%",
    //         }}
    //       >
    //         <div
    //           className="SectionChoice__ChoiceItemTitle-sc-jwdpkx-4 cohiii"
    //           style={{
    //             boxSizing: "border-box",
    //             WebkitTapHighlightColor: "transparent",
    //             margin: "0px",
    //             padding: "0px",
    //             border: "0px",
    //             font: "inherit",
    //             verticalAlign: "baseline",
    //             fontFamily: "Gilroy",
    //             fontSize: "24px",
    //             lineHeight: "29px",
    //             marginBottom: "10px",
    //           }}
    //         >
    //           Privacy
    //         </div>
    //         <div
    //           className="SectionChoice__ChoiceItemDescription-sc-jwdpkx-5 iNmdCs"
    //           style={{
    //             boxSizing: "border-box",
    //             WebkitTapHighlightColor: "transparent",
    //             margin: "0px",
    //             padding: "0px",
    //             border: "0px",
    //             font: "inherit",
    //             verticalAlign: "baseline",
    //             fontSize: "15px",
    //             lineHeight: "20px",
    //             color: "rgb(122, 132, 157)",
    //           }}
    //         >
    //           We realize that in the IT driven world the most valuable asset is
    //           data which could be used against you. Margex values your privacy
    //           and the right to not disclose your personal information.
    //         </div>
    //       </div>
    //       <div
    //         className="SectionChoice__ChoiceItem-sc-jwdpkx-3 hWkbvv"
    //         style={{
    //           boxSizing: "border-box",
    //           WebkitTapHighlightColor: "transparent",
    //           margin: "0px",
    //           padding: "0px",
    //           border: "0px",
    //           font: "inherit",
    //           verticalAlign: "baseline",
    //           position: "relative",
    //           width: "100%",
    //           marginBottom: "40px",
    //           flex: "1 1 0%",
    //         }}
    //       >
    //         <div
    //           className="SectionChoice__ChoiceItemTitle-sc-jwdpkx-4 cohiii"
    //           style={{
    //             boxSizing: "border-box",
    //             WebkitTapHighlightColor: "transparent",
    //             margin: "0px",
    //             padding: "0px",
    //             border: "0px",
    //             font: "inherit",
    //             verticalAlign: "baseline",
    //             fontFamily: "Gilroy",
    //             fontSize: "24px",
    //             lineHeight: "29px",
    //             marginBottom: "10px",
    //           }}
    //         >
    //           Secure trading
    //         </div>
    //         <div
    //           className="SectionChoice__ChoiceItemDescription-sc-jwdpkx-5 iNmdCs"
    //           style={{
    //             boxSizing: "border-box",
    //             WebkitTapHighlightColor: "transparent",
    //             margin: "0px",
    //             padding: "0px",
    //             border: "0px",
    //             font: "inherit",
    //             verticalAlign: "baseline",
    //             fontSize: "15px",
    //             lineHeight: "20px",
    //             color: "rgb(122, 132, 157)",
    //           }}
    //         >
    //           Margex utilizes the most practical and highly scalable
    //           infrastructure type, that, even during peak market activity,
    //           enables traders to trade uninterrupted. Margex combines the very
    //           best of cryptocurrency and bank-grade security standards. 100% of
    //           the assets are stored in cold offline wallets.
    //         </div>
    //       </div>
    //       <div
    //         className="SectionChoice__ChoiceItem-sc-jwdpkx-3 hWkbvv"
    //         style={{
    //           boxSizing: "border-box",
    //           WebkitTapHighlightColor: "transparent",
    //           margin: "0px",
    //           padding: "0px",
    //           border: "0px",
    //           font: "inherit",
    //           verticalAlign: "baseline",
    //           position: "relative",
    //           width: "100%",
    //           marginBottom: "40px",
    //           flex: "1 1 0%",
    //         }}
    //       >
    //         <div
    //           className="SectionChoice__ChoiceItemTitle-sc-jwdpkx-4 cohiii"
    //           style={{
    //             boxSizing: "border-box",
    //             WebkitTapHighlightColor: "transparent",
    //             margin: "0px",
    //             padding: "0px",
    //             border: "0px",
    //             font: "inherit",
    //             verticalAlign: "baseline",
    //             fontFamily: "Gilroy",
    //             fontSize: "24px",
    //             lineHeight: "29px",
    //             marginBottom: "10px",
    //           }}
    //         >
    //           Transparency in every aspect
    //         </div>
    //         <div
    //           className="SectionChoice__ChoiceItemDescription-sc-jwdpkx-5 iNmdCs"
    //           style={{
    //             boxSizing: "border-box",
    //             WebkitTapHighlightColor: "transparent",
    //             margin: "0px",
    //             padding: "0px",
    //             border: "0px",
    //             font: "inherit",
    //             verticalAlign: "baseline",
    //             fontSize: "15px",
    //             lineHeight: "20px",
    //             color: "rgb(122, 132, 157)",
    //           }}
    //         >
    //           Every bit of information is available to you. From the submission
    //           of your order to its execution - you are in total control.
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>

    <WhyUsWrapper>
      <p className="title">
        Why you should choose <br />
        {siteSettings.siteName}'s Trading Infrastructure
      </p>

      <br />

      <div className="container">
        {sections.map((section) => (
          <div className="section">
            <div className="marker"></div>
            <p className="section__title">{section.name}</p>
            <p className="section__text">{section.text}</p>
          </div>
        ))}
      </div>

      <br />
      <br />
    </WhyUsWrapper>
  );
};

const WhyUsWrapper = styled.div`
  max-width: 1110px;
  margin: 0 auto;
  padding-top: 24px;

  .title {
    text-align: center;
    font-family: Gilroy;
    font-size: 38px;
    text-align: center;
    line-height: 47px;
    font-weight: bolder;
  }

  .marker {
    background-color: #3071ee;
    width: 30px;
    height: 6px;
  }

  .section__title {
    font-size: 24px;
    font-family: Gilroy;
    font-weight: 500;
  }

  .section__text {
    font-size: 15px;
    line-height: 20px;
    color: rgb(122, 132, 157);
    font-weight: 500;
  }

  .container {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 30px;
    margin-top: 16px;
    padding: 16px;
  }

  .container {
    @media screen and (max-width: 868px) {
      grid-template-columns: auto;
      padding: 16px;
    }
  }

  .section {
  }
`;

export default WhyUs;
